import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Col, Divider, Input, Modal, Popconfirm, Row, Space, Switch } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChietTinhService from "../../../../../api/tour/chiettinh.service";
import TableDetailThoiGianAD from "./TableDetailThoiGianAD";
import TableTotalThoiGianAD from "./TableTotalThoiGianAD";
import { TIME_DURATION } from "../../../../../constants";

const ModalThoiGianApDung = ({ isOpen, onSave, onCancel, tourChietTinhId }) => {
    const { t } = useTranslation();
    const [thoiGianApDung, setThoiGianApDung] = useState(null);
    const [tenMau, setTenMau] = useState(null);
    const [dataThoiGianApDung, setdataThoiGianApDung] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [tourQuanLyKhoangKhachResponses, setTourQuanLyKhoangKhachResponses] = useState();
    const [items, setItems] = useState([]);
    useEffect(() => {
        getData();
    }, [tourChietTinhId, isOpen]);
    const getData = () => {
        if (tourChietTinhId !== 0) {
            let data = {
                tourChietTinhId: tourChietTinhId,
            };
            console.log("tourChietTinhId " + tourChietTinhId);

            ChietTinhService.getThoiGianApDung(data)
                .then(function (response) {
                    if (response.status === 200 && response.data.code === "00") {
                        var itemsData = [];
                        for (let k = 0; k < response.data.data.length; k++) {
                            const data = response.data.data[k];

                            let dataTT = [];
                            let dataDT = [];
                            let values = data.values;
                            let dataQLKH = [];
                            setTourQuanLyKhoangKhachResponses(data.tourQuanLyKhoangKhachResponses);
                            for (let i = 0; i < values.length; i++) {
                                if (values[i].type === "total") {
                                    dataTT.push({
                                        id: Math.random() * (100 - 1),
                                        id_: values[i].id,
                                        tourChietTinhId: values[i].tourChietTinhId,
                                        tourQuanLyKhoangKhachId: values[i].tourQuanLyKhoangKhachId,
                                        markupType: t("tour.percentage"),
                                        khachLe: values[i].khachLePhanTram,
                                        daiLy: values[i].daiLyPhanTram,
                                        type: values[i].type,
                                        loaiTien: values[i].dmTyGiaId,
                                        apDung: values[i].apDung,
                                    });
                                    dataTT.push({
                                        id: Math.random() * (100 - 1),
                                        id_: values[i].id,
                                        tourChietTinhId: values[i].tourChietTinhId,
                                        tourQuanLyKhoangKhachId: values[i].tourQuanLyKhoangKhachId,
                                        markupType: t("tour.fixedAmount"),
                                        daiLy: values[i].daiLySoTien,
                                        khachLe: values[i].khachLeSoTien,
                                        type: values[i].type,
                                        loaiTien: values[i].dmTyGiaId,
                                        apDung: values[i].apDung === 2 ? 1 : 2,
                                    });
                                    dataTT.push({
                                        id: "NULL " + Math.random() * (100 - 1),
                                        id_: values[i].id,
                                        tourChietTinhId: null,
                                        tourQuanLyKhoangKhachId: null,
                                        markupType: null,
                                        type: null,
                                        loaiTien: null,
                                        apDung: null,
                                    });
                                }
                                if (values[i].type === "detail") {
                                    let dataTourKS = data.tourQuanLyKhoangKhachResponses.find(
                                        (dt) => dt.id === values[i].tourQuanLyKhoangKhachId
                                    );
                                    dataQLKH.push({
                                        ...dataTourKS,
                                        tourChietTinhId: data.tourChietTinhId,
                                        tourQuanLyKhoangKhachId: values[i].tourQuanLyKhoangKhachId,
                                        soKhachToiThieu: values[i].soKhachToiThieu,
                                        soKhachToiDa: values[i].soKhachToiDa,
                                        khachLe: values[i].khachLePhanTram,
                                        daiLy: values[i].daiLyPhanTram,
                                        type: values[i].type,
                                        loaiTien: values[i].dmTyGiaId,
                                        apDung: values[i].apDung,
                                    });
                                    // dataDT.push({
                                    //     id: Math.random() * (100 - 1),
                                    //     id_: values[i].id,
                                    //     markupType: t("tour.percentage"),
                                    //     tourQuanLyKhoangKhachResponses: [
                                    //         {
                                    //             ...dataTourKS,
                                    //             tourChietTinhId: data.tourChietTinhId,
                                    //             tourQuanLyKhoangKhachId: values[i].tourQuanLyKhoangKhachId,
                                    //             soKhachToiThieu: values[i].soKhachToiThieu,
                                    //             soKhachToiDa: values[i].soKhachToiDa,
                                    //             khachLe: values[i].khachLePhanTram,
                                    //             daiLy: values[i].daiLyPhanTram,
                                    //             type: values[i].type,
                                    //             loaiTien: values[i].dmTyGiaId,
                                    //             apDung: values[i].apDung,
                                    //         },
                                    //     ],
                                    // });
                                    // dataDT.push({
                                    //     id: Math.random() * (100 - 1),
                                    //     id_: values[i].id,
                                    //     markupType: t("tour.fixedAmount"),
                                    //     tourQuanLyKhoangKhachResponses: [
                                    //         {
                                    //             ...dataTourKS,
                                    //             tourChietTinhId: data.tourChietTinhId,
                                    //             tourQuanLyKhoangKhachId: values[i].tourQuanLyKhoangKhachId,
                                    //             soKhachToiThieu: values[i].soKhachToiThieu,
                                    //             soKhachToiDa: values[i].soKhachToiDa,
                                    //             khachLe: values[i].khachLePhanTram,
                                    //             daiLy: values[i].daiLyPhanTram,
                                    //             type: values[i].type,
                                    //             loaiTien: values[i].dmTyGiaId,
                                    //             apDung: values[i].apDung,
                                    //         },
                                    //     ],
                                    // });
                                    // dataDT.push({
                                    //     id: "NULL " + Math.random() * (100 - 1),
                                    //     soKhachToiThieu: null,
                                    //     soKhachToiDa: null,
                                    //     tourChietTinhId: null,
                                    //     tourQuanLyKhoangKhachId: null,
                                    //     markupType: null,
                                    //     type: null,
                                    //     loaiTien: null,
                                    //     apDung: null,
                                    // });
                                }
                            }
                            if (dataQLKH.length > 0) {
                                dataDT.push({
                                    id: Math.random() * (100 - 1),
                                    id_: data.tourChietTinhId,
                                    markupType: t("tour.percentage"),
                                    tourQuanLyKhoangKhachResponses: dataQLKH,
                                });
                                dataDT.push({
                                    id: Math.random() * (100 - 1),
                                    id_: data.tourChietTinhId,
                                    markupType: t("tour.fixedAmount"),
                                    tourQuanLyKhoangKhachResponses: dataQLKH,
                                });
                                dataDT.push({
                                    id: "NULL " + Math.random() * (100 - 1),
                                    soKhachToiThieu: null,
                                    soKhachToiDa: null,
                                    tourChietTinhId: null,
                                    tourQuanLyKhoangKhachId: null,
                                    markupType: null,
                                    type: null,
                                    loaiTien: null,
                                    apDung: null,
                                });
                            }
                            dataTT.push({ id: "SUBTOTAL" });
                            dataDT.push({ id: "SUBTOTAL" });

                            var tuNgay = data.tuNgay.split("/");
                            var denNgay = data.denNgay.split("/");
                            itemsData.push({
                                isShow: false,
                                id: data.id,
                                tourChietTinhId: data.tourChietTinhId,
                                tuNgay: dayjs(new Date(tuNgay[2], tuNgay[1] - 1, tuNgay[0])),
                                denNgay: dayjs(new Date(denNgay[2], denNgay[1] - 1, denNgay[0])),
                                values: data,
                                rowsTT: [...dataTT],
                                rowsDT: [...dataDT],
                                variantTT: "contained",
                                variantDT: "outlined",
                                isTableTotal: true,
                            });
                        }
                        setdataThoiGianApDung(response.data.data);
                        setItems(itemsData);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    };
    const onFinish = (e) => {
        e.preventDefault();
        console.log("Items: ", items);
        var thoiGianApDungs = [];
        items.forEach((it) => {
            var rowsTT = [];
            var rowsDT = [];
            it.rowsTT.forEach((element) => {
                if (element.markupType === "Percentage" || element.markupType === "FixedAmount") {
                    if (element.markupType === "Percentage") {
                        rowsTT.push({
                            type: "total",
                            id: element.id_.toString().indexOf("NEW") !== -1 ? 0 : element.id_,
                            dmTyGiaId: element.loaiTien,
                            khachLePhanTram: element.khachLe,
                            daiLyPhanTram: element.daiLy,
                            apDung: element.apDung === 1 ? 1 : 2,
                        });
                    } else {
                        rowsTT[rowsTT.length - 1] = {
                            ...rowsTT[rowsTT.length - 1],
                            khachLeSoTien: element.khachLe,
                            daiLySoTien: element.daiLy,
                            apDung: element.apDung === 1 ? 2 : 1,
                        };
                    }
                }
            });
            // debugger;
            for (let index = 0; index < it.rowsDT.length; index++) {
                const element = it.rowsDT[index];
                if (element.markupType === "Percentage") {
                    const percentage = it.rowsDT[index + 1];
                    let dataDT =
                        element.tourQuanLyKhoangKhachResponses?.map((tour, i) => {
                            return {
                                type: "detail",
                                id: element.id_.toString().indexOf("NEW") !== -1 ? 0 : element.id_,
                                tourQuanLyKhoangKhachId: tour.id,
                                dmTyGiaId: tour.loaiTien,
                                khachLePhanTram: tour.khachLe,
                                daiLyPhanTram: tour.daiLy,
                                apDung: tour.apDung === 1 ? 1 : 2,
                                // khachLeSoTien:
                                //   percentage.tourQuanLyKhoangKhachResponses[i].khachLe,
                                // daiLySoTien: percentage.tourQuanLyKhoangKhachResponses[i].daiLy,
                            };
                        }) || [];
                    rowsDT.push(...dataDT);
                } else {
                    element.tourQuanLyKhoangKhachResponses?.map((tour, i) => {
                        rowsDT = rowsDT.map((item) => {
                            if (item !== undefined) {
                                if (item.tourQuanLyKhoangKhachId === tour.id) {
                                    return { ...item, khachLeSoTien: tour.khachLe, daiLySoTien: tour.daiLy };
                                }
                            }
                            return item;
                        });
                    });
                }
            }
            thoiGianApDungs.push({
                id: it.id, // 0: thêm mới, >0 chỉnh sửa.
                tuNgay: it.tuNgay.format("DD/MM/YYYY"),
                denNgay: it.denNgay.format("DD/MM/YYYY"),
                values: [...rowsDT, ...rowsTT],
            });
        });
        let data = {
            tourChietTinhId: tourChietTinhId ? tourChietTinhId : 1,
            thoiGianApDungs: thoiGianApDungs,
        };
        console.log(JSON.stringify(data));
        onSave(data, false);
    };
    const handleCancels = (e) => {
        e.preventDefault();
        onCancel(false);
        setPopupOpen(false);
    };
    const handleCancel = (e) => {
        e.preventDefault();
        setPopupOpen(true);
    };
    const handleClear = () => {};
    const danhSachMau = () => {};
    const handleRemoveField = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };

    const handleAddField = () => {
        setItems([
            ...items,
            {
                isShow: false,
                id: 0,
                tuNgay: null,
                denNgay: null,
                values: [],
                tourChietTinhId: null,
                rowsTT: [{ id: "SUBTOTAL" }],
                rowsDT: [{ id: "SUBTOTAL" }],
                variantTT: "contained",
                variantDT: "outlined",
                isTableTotal: true,
            },
        ]);
    };
    const handleItemChange = (name, index, value) => {
        console.log("name: " + name + " value: " + value);
        const newItems = [...items];
        if (name === "variantDT") {
            newItems[index][name] = value;
            newItems[index]["isTableTotal"] = false;
            newItems[index]["variantTT"] = "outlined";
        }
        if (name === "variantTT") {
            newItems[index][name] = value;
            newItems[index]["isTableTotal"] = true;
            newItems[index]["variantDT"] = "outlined";
        } else {
            newItems[index][name] = value;
        }
        setItems(newItems);
    };
    const onChangeTrangThai = () => {};
    const handleSave = (item) => {
        setItems(
            items.map((it) => {
                if (it.id === item.id) return item;
                return it;
            })
        );
    };

    return (
        <>
            <Popconfirm
                style={{ marginLeft: "1000px", display: "block" }}
                title={t("closePopup")}
                description={t("areYouConfirmClosePopup")}
                placement="topLeft"
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={handleCancels}
                open={popupOpen}
            />

            <Modal
                title={t("hotel.priceRoom.applicationTime")}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancel}
                footer={null}
                width={1000}
                height={700}
            >
                <Divider />
                <Box component="form" noValidate>
                    {/*<Input hidden value={tourChietTinhId}></Input>*/}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                            <TextField
                                label={t("hotel.priceRoom.applicationTime")}
                                select
                                fullWidth
                                name="thoiGianApDung"
                                onChange={(e) => setThoiGianApDung(e.target.value)}
                                value={thoiGianApDung}
                            >
                                {dataThoiGianApDung.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.tuNgay + " - " + option.denNgay}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={5}></Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                            <Button sx={{ marginTop: "10px" }} variant="outlined" onClick={danhSachMau}>
                                {t("tour.danhSachMau")}
                            </Button>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                            <Button sx={{ marginTop: "10px" }} variant="contained" onClick={handleAddField}>
                                {t("add")}
                            </Button>
                        </Col>
                    </Row>

                    {items.map(
                        (item, index) => (
                            // thoiGianApDung === item.id ? (
                            <div key={index}>
                                <Row style={{ marginTop: "10px" }}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={1}>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={(e) => handleItemChange("isShow", index, !item.isShow)}
                                        >
                                            {item.isShow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                components={["DatePicker", "DatePicker"]}
                                                sx={{ marginLeft: "10px" }}
                                            >
                                                <DatePicker
                                                    label={t("toDate")}
                                                    value={item.tuNgay}
                                                    maxDate={item.denNgay}
                                                    defaultValue={item.tuNgay}
                                                    format="DD/MM/YYYY"
                                                    // disableFuture
                                                    name="tuNgay"
                                                    onChange={(value) => handleItemChange("tuNgay", index, value)}
                                                />
                                                <DatePicker
                                                    label={t("fromDate")}
                                                    name="denNgay"
                                                    format="DD/MM/YYYY"
                                                    minDate={item.tuNgay}
                                                    defaultValue={item.denNgay}
                                                    value={item.denNgay}
                                                    onChange={(value) => handleItemChange("denNgay", index, value)}
                                                    // disablePast
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={5}></Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={1}>
                                        <RemoveCircleIcon
                                            style={{ marginTop: "20px" }}
                                            onClick={(e) => handleRemoveField(index)}
                                        />
                                    </Col>
                                </Row>
                                {!item.isShow ? (
                                    <div>
                                        <Row style={{ marginTop: 10 }}>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                                                <Button
                                                    variant={item.variantTT}
                                                    onClick={(e) => handleItemChange("variantTT", index, "contained")}
                                                >
                                                    {t("tour.total")}
                                                </Button>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                                                <Button
                                                    variant={item.variantDT}
                                                    onClick={(e) => handleItemChange("variantDT", index, "contained")}
                                                >
                                                    {t("tour.chiTiet")}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "10px" }}></Row>
                                        {item.isTableTotal ? (
                                            <TableTotalThoiGianAD
                                                rowsTT={item.rowsTT}
                                                handleSave={handleSave}
                                                item={item}
                                                items={items}
                                                setItems={setItems}
                                            />
                                        ) : (
                                            <TableDetailThoiGianAD
                                                dataSource={item.rowsDT}
                                                item={item}
                                                items={items}
                                                setItems={setItems}
                                                tourQuanLyKhoangKhachResponses={tourQuanLyKhoangKhachResponses}
                                            />
                                        )}
                                    </div>
                                ) : null}
                                <Divider />
                            </div>
                        )
                        // ) : null
                    )}
                    <Row style={{ marginTop: 6 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                            <FormControlLabel
                                style={{ marginTop: "14px" }}
                                label={t("tour.luuMau")}
                                control={
                                    <Switch
                                        style={{ marginLeft: "10px" }}
                                        defaultChecked={false}
                                        onChange={onChangeTrangThai}
                                    />
                                }
                                labelPlacement="start"
                            />
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                sx={{
                                    marginBottom: 2,
                                }}
                                fullWidth
                                label={t("tour.tenMau")}
                                // multiline
                                rows={1}
                                value={tenMau}
                                onChange={(e) => setTenMau(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 6 }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                        <Col xs={24} sm={24} md={24} lg={18} xl={10}>
                            <Space>
                                <Button variant="contained" onClick={onFinish} startIcon={<SaveIcon />}>
                                    {t("action.ok")}
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#898989",
                                        color: "white",
                                    }}
                                    key="reset"
                                    startIcon={<RestartAltIcon />}
                                    onClick={(e) => handleClear(e)}
                                >
                                    {t("action.reset")}
                                </Button>
                                <Popconfirm
                                    title={t("closePopup")}
                                    description={t("areYouConfirmClosePopup")}
                                    okText={t("yes")}
                                    cancelText={t("no")}
                                    onConfirm={handleCancels}
                                >
                                    <Button variant="outlined" startIcon={<DeleteIcon />} key="clear">
                                        {t("action.cancel")}
                                    </Button>
                                </Popconfirm>
                            </Space>
                        </Col>
                    </Row>
                </Box>
            </Modal>
        </>
    );
};
export default ModalThoiGianApDung;
