import React, { useState } from "react";
import {  Row,  notification } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {API_BASE_URL, TIME_DURATION} from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import ModalHP from "./ModalHP";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";


const FormHp = ({ onSearch, dvId, dataAdd }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleFinish = (status, values) => {
        setIsModalOpen(status);
        setIsModalOpen(false);
        let data = {
            ...values,
            id: 0,
            nccThongTinChungId: dvId,
            kichThuocPhong: values.kichThuocPhong !== undefined ? values.kichThuocPhong : "",
            foc: values.foc !== undefined ? values.foc : "",
            ghiChu: values.ghiChu !== undefined ? values.ghiChu : "",
            dmTienIchList: values.dmTienIchList !== undefined ? values.dmTienIchList : [],
        };
        console.log("data=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/hangphong/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataAdd(data)
                        }

                    }
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };

    // const onSearch = (value) => {
    //     handleSearch(value);
    // };
    return (
        <Row>
            {contextHolder}
            {/*<Col*/}
            {/*    xs={10} sm={10} md={10} lg={10} xl={8}*/}
            {/*></Col>*/}
            {/*<Col*/}
            {/*    xs={10} sm={10} md={10} lg={10} xl={8}*/}
            {/*></Col>*/}
            {/*<Col*/}
            {/*    xs={10} sm={10} md={10} lg={10} xl={8}*/}
            {/*>*/}
            {/*    <Space size="large">*/}
            {/*        /!*<Button onClick={showModal} icon={<PlusOutlined />} type="primary">*!/*/}
            {/*        /!*    {t("add")}*!/*/}
            {/*        /!*</Button>*!/*/}
                    <Button sx={{marginTop:"5px"}} variant="outlined" onClick={showModal} startIcon={<AddIcon />} color="warning">
                        {t("add")}
                    </Button>
                    <ModalHP isOpen={isModalOpen} handleCancel={handleCancel} handleFinish={handleFinish} />
                {/*</Space>*/}
            {/*</Col>*/}
        </Row>
    );
};
export default FormHp;
