import { PlusOutlined } from "@ant-design/icons";
import {
    Affix,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Space,
    Typography,
    Upload,
    message,
    notification,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import { API_BASE_URL } from "../../../../../constants";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";
import { isEmpty } from "../../../../../constants/utils";
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const ModalTkAction = ({ isOpen, handleCancel, handleFinish, record, action }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [tenNH, setTenNH] = useState([]);
    const [fileImg, setFileImg] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [fileListInit, setFileListInit] = useState([]);
    const [tenNganHangError, setTenNganHangError] = useState("");
    const [soTaiKhoanError, setSoTaiKhoanError] = useState("");
    const [chuSoHuuError, setChuSoHuuError] = useState("");
    const [dataSource, setDataSource] = useState({
        dmNganHangId: "",
        tenNganHang: "",
        soTaiKhoan: "",
        chuSoHuu: "",
        chiNhanh: "",
        maSwift: "",
    });
    const [dataSourceInit, setDataSourceInit] = useState({
        dmNganHangId: "",
        tenNganHang: "",
        soTaiKhoan: "",
        chuSoHuu: "",
        chiNhanh: "",
        maSwift: "",
    });
    const [errFile, setErrFile] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [isEnable, setIsEnable] = useState(true);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmNganHang",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    // console.log("Response: ====" + response.data.data);
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].name,
                                });
                            }
                        }
                        setTenNH(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        let apiData = {
            id: record.id,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/tknh/getDataById",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log("Response: ====" + data);
                    setIsEnable(false);
                    if (data) {
                        setDataSource(data);
                        setDataSourceInit({ ...data });
                    }
                    setFileList([
                        {
                            uid: "-1",
                            name: data.soTaiKhoan + ".png",
                            status: "done",
                            thumbUrl: "data:image/png;base64," + data.base64Value,
                            preview: "data:image/png;base64," + data.base64Value,
                        },
                    ]);
                    setFileListInit([
                        {
                            uid: "-1",
                            name: data.soTaiKhoan + ".png",
                            status: "done",
                            thumbUrl: "data:image/png;base64," + data.base64Value,
                            preview: "data:image/png;base64," + data.base64Value,
                        },
                    ]);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, [record.id]);
    const onFinish = (e) => {
        debugger;
        e.preventDefault();
        setTenNganHangError(false);
        setChuSoHuuError(false);
        setSoTaiKhoanError(false);
        setErrFile(false);
        let error = false;
        if (!dataSource.dmNganHangId) {
            setTenNganHangError(true);
            error = true;
        }
        if (!dataSource.chuSoHuu) {
            setChuSoHuuError(true);
            error = true;
        }
        if (!dataSource.soTaiKhoan) {
            setSoTaiKhoanError(true);
            error = true;
        }
        if (!fileList || fileList.length === 0) {
            setErrFile(true);
            error = true;
        }
        if (error) return;

        const dataSave = {
            ...dataSource,
            id: record.id,
            tenNganHang: tenNH[dataSource.dmNganHangId],
            qrCodeImg: {
                base64Value: fileList[0].preview?.substring(fileList[0].preview.indexOf("base64") + 7),
            },
        };
        handleFinish(!isOpen, dataSave);
    };
    const handleCancels = () => {
        // form.resetFields();
        handleCancel();
    };
    const handleCancelImg = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };
    const handleChangeImg = async ({ fileList: newFileList }) => {
        console.log("handleChangeImg ====", newFileList);
        debugger;
        if (newFileList.length > 0) {
            if (!newFileList[0].url && !newFileList[0].preview) {
                newFileList[0].preview = await getBase64(newFileList[0].originFileObj);
            }
            setPreviewImage(newFileList[0].url || newFileList[0].preview);
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
            handleReadQrCode(newFileList);
            setIsEnable(false);
            setIsFileUpload(true);
        } else {
            // handleClear();
            setIsEnable(true);
            setIsFileUpload(false);
        }
        setFileList(newFileList);
    };

    const handleReadQrCode = (fileList) => {
        let apiData = {
            base64Value: fileList[0].preview.split("base64,")[1],
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/tknh/readqrcode",
            data: apiData,
            headers: authHeader(),
        }).then(function (response) {
            if (response.status === 200 && response.data.code === "00") {
                if (!isEmpty(response.data.data)) {
                    console.log(response.data.data);

                    form.setFieldsValue({
                        soTaiKhoan: response.data.data.soTaiKhoan,
                    });

                    setDataSourceInit({ ...dataSource });
                    setDataSource(response.data.data);
                }
            }
        });
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const handleClear = () => {
        form.resetFields();
        setDataSource({ ...dataSourceInit });
        setFileList([...fileListInit]);
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const handleChangeDataSource = (e) => {
        const newDS = { ...dataSource };
        let name = e.target.name;
        let value = e.target.value;
        newDS[name] = value;
        setDataSource(newDS);
    };
    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <Row>
                    <Col xs={17} sm={17} md={17} lg={17} xl={17}>
                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <TextField
                                    disabled={!isEnable}
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("hotel.accountBanking.bank")}
                                    name="dmNganHangId"
                                    onChange={(e) => handleChangeDataSource(e)}
                                    value={dataSource.dmNganHangId ? dataSource.dmNganHangId : 0}
                                    error={tenNganHangError}
                                    helperText={
                                        tenNganHangError
                                            ? t("validate") + " " + t("hotel.accountBanking.bank").toLowerCase()
                                            : ""
                                    }
                                    // required
                                    fullWidth
                                    select
                                >
                                    {tenNH.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                                <TextField
                                    disabled={!isEnable}
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("hotel.accountBanking.accountNumber")}
                                    name="soTaiKhoan"
                                    onChange={(e) => handleChangeDataSource(e)}
                                    value={dataSource.soTaiKhoan ? dataSource.soTaiKhoan : ""}
                                    error={soTaiKhoanError}
                                    helperText={
                                        soTaiKhoanError
                                            ? t("validate") +
                                              " " +
                                              t("hotel.accountBanking.accountNumber").toLowerCase()
                                            : ""
                                    }
                                    // required
                                    fullWidth
                                ></TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField
                                    disabled={!isEnable && !isFileUpload}
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("hotel.accountBanking.accountOwner")}
                                    name="chuSoHuu"
                                    onChange={(e) => handleChangeDataSource(e)}
                                    value={dataSource.chuSoHuu ? dataSource.chuSoHuu : ""}
                                    error={chuSoHuuError}
                                    helperText={
                                        chuSoHuuError
                                            ? t("validate") + " " + t("hotel.accountBanking.accountOwner").toLowerCase()
                                            : ""
                                    }
                                    // required
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <TextField
                                    disabled={!action}
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("hotel.accountBanking.branch")}
                                    name="chiNhanh"
                                    onChange={(e) => handleChangeDataSource(e)}
                                    value={dataSource.chiNhanh ? dataSource.chiNhanh : ""}
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <TextField
                                    disabled={!action}
                                    sx={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("hotel.accountBanking.swiftCode")}
                                    name="maSwift"
                                    onChange={(e) => handleChangeDataSource(e)}
                                    value={dataSource.maSwift ? dataSource.maSwift : ""}
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <TextField
                                    disabled={!action}
                                    style={{
                                        paddingBottom: 1,
                                    }}
                                    label={t("notes")}
                                    name="ghiChu"
                                    onChange={(e) => handleChangeDataSource(e)}
                                    value={dataSource.ghiChu ? dataSource.ghiChu : ""}
                                    // multiline
                                    rows={3}
                                    fullWidth
                                ></TextField>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                        <Row>
                            <Upload
                                disabled={!action}
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileImg.length > 0 ? fileImg : fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelImg}>
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                            {errFile ? <Typography.Text type="danger">{t("errorQRCode")}</Typography.Text> : <></>}
                        </Row>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    const handleGenQrCode = (e) => {
        debugger;
        e.preventDefault();
        let apiData = {
            dmNganHangId: dataSource.dmNganHangId,
            soTaiKhoan: dataSource.soTaiKhoan,
            chuSoHuu: dataSource.chuSoHuu,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/tknh/qrcode",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    console.log("===res", response.data.data);
                    if (response.data.data) {
                        // setIsShowImg(true);
                        // setGenQrBase64(response.data.data);
                        setFileList([
                            {
                                uid: "-1",
                                name: dataSource.soTaiKhoan + ".png",
                                status: "done",
                                thumbUrl: "data:image/png;base64," + response.data.data,
                                preview: "data:image/png;base64," + response.data.data,
                            },
                        ]);
                        api["success"]({
                            message: t("stour"),
                            description: response.data.message,
                            duration: 0,
                        });
                    }
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("hotel.accountBanking.genQrCode") + " " + t("noti.error").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(() => {
                api["error"]({
                    message: t("stour"),
                    description: t("system.error"),
                    duration: 0,
                });
            });
    };

    return (
        <div>
            {contextHolder}
            <Modal
                title={action ? t("edit") + " " + t("hotel.accountBanking").toLowerCase() : t("hotel.accountBanking")}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1000}
            >
                <Divider />
                <Box component="form" noValidate>
                    {dataRender()}
                    {action ? (
                        <Form.Item
                            wrapperCol={{
                                span: 16,
                                offset: 10,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <div
                                    style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        maxHeight: 60,
                                        paddingInline: 50,
                                        lineHeight: "60px",
                                        backgroundColor: "rgba(150, 150, 150, 0.2)",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <Space>
                                        <Button
                                            variant="contained"
                                            onClick={onFinish}
                                            type="submit"
                                            startIcon={<Save />}
                                        >
                                            {t("action.ok")}
                                        </Button>
                                        <Button
                                            sx={{
                                                backgroundColor: "#898989",
                                                color: "white",
                                            }}
                                            key="reset"
                                            startIcon={<RestartAlt />}
                                            onClick={(e) => handleClear(e)}
                                        >
                                            {t("action.reset")}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Delete />}
                                            onClick={handleCancels}
                                            key="clear"
                                        >
                                            {t("action.cancel")}
                                        </Button>
                                        <Button onClick={handleGenQrCode} type="dashed">
                                            {t("hotel.accountBanking.genQrCode")}
                                        </Button>
                                    </Space>
                                </div>
                            </Affix>
                        </Form.Item>
                    ) : (
                        ""
                    )}
                </Box>
            </Modal>
        </div>
    );
};
export default ModalTkAction;
