import { Tabs } from "antd";
import React, {  useState } from "react";
import { useTranslation } from "react-i18next";

const ThuChiTienPT=({}) => {
    const { t } = useTranslation();


    return (
        <>hihi</>
    )
}
export default ThuChiTienPT