import React, { useState } from "react";
import {  Col, Row} from "antd";
import { useTranslation } from "react-i18next";
import ModalToT from "./ModalToT";
import axios from "axios";
import { API_BASE_URL } from "../../../constants";
import authHeader from "../../../api/auth_header";
import AddIcon from "@mui/icons-material/Add";
import {Button} from "@mui/material";

const FormToT = ({ dataAdd, deleteLst, handleSearch, dataSearch }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = (status, values) => {
        setIsModalOpen(false);
        let data = {
            ma_loai_hinh: values.ma_loai_hinh,
            loai_hinh_du_lich: values.loai_hinh_du_lich,
            mo_ta: values.mo_ta,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/saveDmLoaiHinhDuLich",
            data: data,
            headers: authHeader(),
        }).then(function (response) {
            if (response.status === 200 && response.data.code === "00") {
                let dataLst = [];
                let dataLstSearch = [];
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        dataLst.push({
                            stt: i + 1,
                            id: data[i].id,
                            ma_loai_hinh: data[i].ma_loai_hinh,
                            loai_hinh_du_lich: data[i].loai_hinh_du_lich,
                            mo_ta: data[i].mo_ta,
                            hieu_luc: data[i].hieu_luc,
                        });
                        dataLstSearch.push({
                            value: data[i].id + " - " + data[i].ma_loai_hinh + " - " + data[i].loai_hinh_du_lich,
                        });
                    }
                    dataAdd(dataLst, dataLstSearch);
                }
            }
        });
    };
    const onSearch = (value) => {
        handleSearch(value);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const deleteClick = () => {
        deleteLst();
    };
    return (
        <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}></Col>
            <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                {/*<Space size="large">*/}
                {/*    <Button icon={<DeleteOutlined />} type="primary" danger onClick={deleteClick}>*/}
                {/*        {t("delete")}*/}
                {/*    </Button>*/}
                    <Button onClick={showModal} sx={{marginTop:"5px"}} variant="outlined"  startIcon={<AddIcon />} color="warning">
                        {t("add")}
                    </Button>
                    <ModalToT isOpen={isModalOpen} handleCancel={handleCancel} handleFinish={handleOk} />
                {/*</Space>*/}
            </Col>
        </Row>
    );
};
export default FormToT;
