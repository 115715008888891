import React from "react";
import {Row, Input} from "antd";
// import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {Button} from "@mui/material";

const { Search } = Input;

const FormPlane = ({ dataAdd, keySide, handleSearch }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const onClickAdd = () => {
        switch (keySide) {
            case 6:
                return navigate("/ncc/may-bay/new");
            default:
                return null;
        }
    };
    // const onSearch = (value) => {
    //     handleSearch(value);
    // };
    return (
        <Row>
            {/*<Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>*/}
            {/*<Col xs={9} sm={9} md={9} lg={9} xl={9}></Col>*/}
            {/*<Col xs={6} sm={6} md={6} lg={6} xl={6}>*/}
            {/*    <Space size="large">*/}
                    {/*<Button icon={<DownloadOutlined/>} type="default">*/}
                    {/*    {t("exportFile")}*/}
                    {/*</Button>*/}
                    {/*<Button icon={<ImportOutlined/>} type="dashed">*/}
                    {/*    {t("importFile")}*/}
                    {/*</Button>*/}
                    <Button sx={{marginTop:"5px"}} variant="outlined" onClick={onClickAdd} startIcon={<AddIcon />} color="warning">
                        {t("add")}
                    </Button>
            {/*    </Space>*/}
            {/*</Col>*/}
        </Row>
    );
};
export default FormPlane;
