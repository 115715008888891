import React, { useEffect, useState } from "react";
import { Col, Divider, Modal, Row, Table, notification } from "antd";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Slider,
  TextField,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import { GridFilterAltIcon } from "@mui/x-data-grid";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import otoService from "../../../../../api/tour/oto/oto.service";
import chiettinhService from "../../../../../api/tour/chiettinh.service";
import { TIME_DURATION } from "../../../../../constants";
import "../ncc/ModalNcc.css";

function valuetext(value) {
  return `${value} đ`;
}

const ModalThongTinNccOto = ({
  isOpen,
  onSaves,
  onIsShow,
  onCancels,
  onUpdateServiceContainDuplicate,
  onUpdateService,
  isCapNhatAll,
  soCho,
  khoangKhachDuplicate,
  thoiGianAD,
}) => {
  const { t } = useTranslation();
  const [dataQuocGia, setDataQuocGia] = useState([]);
  const [dataTinhTP, setDataTinhTP] = useState([]);
  const [quocGiaId, setQuocGiaId] = useState("");
  const [tinhId, setTinhId] = useState("");
  const [supplier, setSupplier] = useState("");
  const [dichVu, setDichVu] = useState("");
  const [hinhThucGiaId, setHinhThucGiaId] = useState("");
  const [soChoId, setSoChoId] = useState("");
  const [dmSoCho, setDmSoCho] = useState([]);
  const [dmHinhThucGia, setDmHinhThucGia] = useState([]);
  const [loaiXeId, setLoaiXeId] = useState("");
  const [dmLoaiXe, setDmLoaiXe] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [select, setSelect] = useState({
    selectedRowKeys: [],
    loading: false,
  });
  const { selectedRowKeys, loading } = select;
  const [dataNccOto, setDataNccOto] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState([0, 0]);
  const [valueInit, setValueInit] = useState([0, 0]);
  const [dataMucGia, setDataMucGia] = useState([]);
  const [dataMucGiaInit, setDataMucGiaInit] = useState([]);
  const [giaMin, setGiaMin] = useState(0);
  const [giaMax, setGiaMax] = useState(0);
  const [dmTyGiaId, setDmTyGiaId] = useState(1);
  const [dmTyGia, setDmTyGia] = useState([
    { label: "VND", value: 1 },
    { label: "USD", value: 2 },
  ]);
  const [giaApDungs, setGiaApDungs] = useState([]);
  const open = Boolean(anchorEl);
  useEffect(() => {
    getDataQuocGia();
    getDmLoaiXe();
    getDmHinhThucGia();
    getCauHinhMucGia({ id: dmTyGiaId });
  }, []);

  useEffect(() => {
    if (isOpen)
      getNccOto({
        searchNcc: "",
        searchDichVu: "",
        tuNgay: thoiGianAD !== null ? thoiGianAD.tuNgay : "",
        denNgay: thoiGianAD !== null ? thoiGianAD.denNgay : "",
        hinhThucGiaId: 0,
        soChoId: isCapNhatAll ? 0 : soCho,
        loaiXeId: 0,
        quocGiaId: 0,
        tinhId: 0,
        // giaMin: 0,
        // giaMax: 0,
        // dmTyGiaId: 1,
      });
    getDmSoCho(isCapNhatAll, soCho);
  }, [isCapNhatAll, soCho, isOpen]);

  const getDataQuocGia = () => {
    otoService
      .getQuocGia()
      .then(function (response) {
        if (response.status === 200) {
          let dataQG = [];
          let data = response.data.data;
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].hieu_luc === true) {
                dataQG.push({
                  value: data[i].id,
                  label: data[i].quoc_gia,
                });
              }
            }
            setDataQuocGia(dataQG);
          }
        }
      })
      .catch(function (response) {
        console.log("Error response: ====", response);
      });
  };

  const getDataTinhTP = (idQuocGia) => {
    otoService
      .getTinhTP({ dm_quoc_gia_id: idQuocGia })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          let dataTinhTP = data.map((item) => {
            if (item.hieu_luc === true) {
              return {
                value: item.id,
                label: item.tinh_thanhpho,
              };
            }
            return null;
          });
          setDataTinhTP(dataTinhTP);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmHinhThucGia = () => {
    otoService
      .getDmHinhThucGia()
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmHinhThucGia(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getNccOto = (data) => {
    otoService
      .getNccOto(data)
      .then(function (response) {
        if (response.status === 200) {
          let dataNccOto =
            response.data.data?.map((item) => ({
              ...item,
              key: item.nccThongTinChungId,
            })) || [];
          console.log("dataNccOto", dataNccOto);
          setDataNccOto(dataNccOto);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const getDmSoCho = (isCapNhatAll, idSoCho) => {
    otoService
      .getDmSoCho()
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.data;
          let dmSoCho = [];
          data.forEach((item) => {
            if (item.hieu_luc) {
              if (isCapNhatAll || idSoCho === 0) {
                dmSoCho.push({
                  value: item.id,
                  label: item.so_cho_str,
                });
              } else if (item.id === idSoCho) {
                dmSoCho.push({
                  value: item.id,
                  label: item.so_cho_str,
                });
              }
            }
          });
          setDmSoCho(dmSoCho);
        }
      })
      .catch((response) => {
        console.log("Error response: ====" + response);
      });
  };

  const getDmLoaiXe = () => {
    otoService
      .getDmLoaiXe()
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.data;
          let dmLoaiXe = data.map((item) => {
            if (item.hieu_luc) {
              return {
                value: item.id,
                label: item.ten_loai_xe,
              };
            }
            return null;
          });
          setDmLoaiXe(dmLoaiXe);
        }
      })
      .catch((response) => {
        console.log("Error response: ====" + response);
      });
  };

  const getCauHinhMucGia = (data) => {
    if (data.id === 1) {
      setValue([0, 100000000]);
      setValueInit([0, 100000000]);
    } else {
      setValue([0, 10000]);
      setValueInit([0, 10000]);
    }
    chiettinhService
      .layCauHinhMucGia(data)
      .then(function (response) {
        if (response.status === 200) {
          let dataMucGia =
            response.data.data?.map((item) => ({
              ...item,
              label: item.mucGia,
            })) || [];
          setDataMucGia(dataMucGia);
          if (data.id === 1)
            setDataMucGiaInit(JSON.parse(JSON.stringify(dataMucGia)));
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = parseInt(value);
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelect({
        ...select,
        selectedRowKeys: selectedRowKeys,
      });
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
      setSelectedRows(selectedRows);
    },
  };
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: t("res.code"),
        dataIndex: "maDichVu",
        key: "maDichVu",
        width: "10%",
      },
      {
        title: t("service") + "/" + t("trip"),
        dataIndex: "tenDichVu",
        key: "tenDichVu",
        width: "10%",
      },
      {
        title: t("formOfPricing"),
        key: "hinhThucGia",
        dataIndex: "hinhThucGia",
        width: "10%",
      },
      {
        title: t("number"),
        dataIndex: "soCho",
        key: "soCho",
        width: "10%",
      },
      {
        title: t("vehicleType"),
        dataIndex: "loaiXe",
        key: "loaiXe",
        width: "10%",
      },
      {
        title: t("hotel.priceRoom.applicationTime"),
        dataIndex: "thoiGianAD",
        key: "thoiGianAD",
        width: "20%",
      },
      {
        title: t("hotel.priceClass.giaNet"),
        dataIndex: "giaNet",
        key: "giaNet",
        width: "10%",
        render: (_, record) => {
          return handleData(record.giaNet);
        },
      },
      {
        title: t("exchangeRate.currency"),
        dataIndex: "loaiTien",
        key: "loaiTien",
        width: "10%",
      },
      {
        title: t("tax") + " " + t("vat"),
        dataIndex: "vat",
        key: "vat",
        width: "10%",
      },
    ];
    let dataDichVus = record?.dichVus?.map((item) => {
      return {
        ...item,
        key: item.tranxOtoId,
        thoiGianAD: `${item.tuNgay} - ${item.denNgay}`,
        loaiTien: item.maNgoaiTe,
        vat: record.vat,
        dmVatId: record.dmVatId,
        tenNcc: record.tenNcc,
        isVat: record.isVat,
        nccThongTinChungId: record.nccThongTinChungId,
      };
    });
    return (
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataDichVus}
        // pagination={false}
        loading={loading}
      />
    );
  };
  const columns = [
    {
      title: t("maNcc"),
      dataIndex: "maNcc",
      key: "maNcc",
      width: "10%",
    },
    {
      title: t("supplier"),
      dataIndex: "tenNcc",
      key: "tenNcc",
      width: "20%",
    },
    {
      title: t("address"),
      dataIndex: "diaChi",
      key: "diaChi",
      width: "30%",
    },
    {
      title: t("phoneNumber"),
      dataIndex: "sdt",
      key: "sdt",
      width: "20%",
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      width: "20%",
    },
  ];

  const handleCancels = () => {
    setSupplier("");
    setDichVu("");
    setHinhThucGiaId("");
    setSoChoId("");
    setLoaiXeId("");
    setQuocGiaId("");
    setTinhId("");
    isCapNhatAll ? onCancels(false) : onIsShow(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setGiaApDungs([]);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    handleApplyFilter();
  };

  const handleApplyFilter = () => {
    setAnchorEl(null);
    let dataSearch = {
      searchNcc: supplier ? supplier : "",
      searchDichVu: dichVu ? dichVu : "",
      tuNgay: thoiGianAD !== null ? thoiGianAD.tuNgay : "",
      denNgay: thoiGianAD !== null ? thoiGianAD.denNgay : "",
      hinhThucGiaId: hinhThucGiaId ? hinhThucGiaId : 0,
      soChoId: soChoId ? soChoId : isCapNhatAll ? 0 : soCho,
      loaiXeId: loaiXeId ? loaiXeId : 0,
      quocGiaId: quocGiaId ? quocGiaId : 0,
      tinhId: tinhId ? tinhId : 0,
      // giaMin: giaApDungs.length > 0 ? (giaMin ? giaMin : 0) : value[0],
      // giaMax: giaApDungs.length > 0 ? (giaMax ? giaMax : 0) : value[1], //mức giá k có giá max => giaMax truyền 0
      // dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
    };
    console.log(dataSearch);
    getNccOto(dataSearch);
  };

  const handleChangeQG = (event) => {
    setQuocGiaId(event.target.value);
    getDataTinhTP(event.target.value);
  };

  const handleCancelChooseService = () => {
    setSelect({ selectedRowKeys: [], loading: false });
    setSelectedRows([]);
    handleCancels();
  };

  const handleResetChooseService = () => {
    setSelect({ selectedRowKeys: [], loading: false });
    setSelectedRows([]);
    setSupplier("");
    setDichVu("");
    setHinhThucGiaId("");
    setSoChoId("");
    setLoaiXeId("");
    setQuocGiaId("");
    setTinhId("");
  };

  const handleSearchChooseService = () => {
    let dataSearch = {
      searchNcc: supplier ? supplier : "",
      searchDichVu: dichVu ? dichVu : "",
      tuNgay: thoiGianAD !== null ? thoiGianAD.tuNgay : "",
      denNgay: thoiGianAD !== null ? thoiGianAD.denNgay : "",
      hinhThucGiaId: hinhThucGiaId ? hinhThucGiaId : 0,
      soChoId: soChoId ? soChoId : isCapNhatAll ? 0 : soCho,
      loaiXeId: loaiXeId ? loaiXeId : 0,
      quocGiaId: quocGiaId ? quocGiaId : 0,
      tinhId: tinhId ? tinhId : 0,
      // giaMin: giaApDungs.length > 0 ? (giaMin ? giaMin : 0) : value[0],
      // giaMax: giaApDungs.length > 0 ? (giaMax ? giaMax : 0) : value[1], //mức giá k có giá max => giaMax truyền 0
      // dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
    };
    console.log(dataSearch);
    getNccOto(dataSearch);
  };

  const handleSaveAllChooseService = () => {
    setIsOpenConfirm(false);
    onUpdateServiceContainDuplicate(selectedRows);
    setSelect({ selectedRowKeys: [], loading: false });
    setSelectedRows([]);
    handleCancels();
    handleNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
  };

  const handleSaveChooseService = () => {
    setIsOpenConfirm(false);
    onUpdateService(selectedRows);
    setSelect({ selectedRowKeys: [], loading: false });
    setSelectedRows([]);
    handleCancels();
    handleNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
  };

  const handleConfirmSave = (e) => {
    e.preventDefault();
    let arr = selectedRows.map((item) => item.soCho);
    let finDuplicates = arr.filter(
      (item, index) => arr.indexOf(item) !== index
    );
    console.log(finDuplicates);
    if (finDuplicates.length > 0) {
      handleNoti("error", t("noti.soCho"));
    } else {
      if (selectedRows.length === 1 && !isCapNhatAll) {
        onSaves(selectedRows);
        setSelect({ selectedRowKeys: [], loading: false });
        setSelectedRows([]);
        handleCancels();
        handleNoti(
          "success",
          t("edit") + " " + t("noti.success").toLowerCase()
        );
      } else if (selectedRows.length >= 1 && isCapNhatAll) {
        console.log(selectedRows);
        if (khoangKhachDuplicate.length > 0) {
          let isCheck = false;
          for (let i = 0; i < khoangKhachDuplicate.length; i++) {
            const element = khoangKhachDuplicate[i];
            let checkIdx = selectedRows.findIndex(
              (item) => item.soCho === element
            );
            if (checkIdx !== -1) {
              isCheck = true;
              break;
            }
          }
          if (isCheck) {
            setIsOpenConfirm(true);
          } else {
            onUpdateServiceContainDuplicate(selectedRows);
            setSelect({ selectedRowKeys: [], loading: false });
            setSelectedRows([]);
            handleCancels();
            handleNoti(
              "success",
              t("edit") + " " + t("noti.success").toLowerCase()
            );
          }
        } else {
          onUpdateService(selectedRows);
          setSelect({ selectedRowKeys: [], loading: false });
          setSelectedRows([]);
          handleCancels();
          handleNoti(
            "success",
            t("edit") + " " + t("noti.success").toLowerCase()
          );
        }
      } else {
        handleNoti("error", t("noti.selectServiceError"));
      }
    }
  };

  const handleNoti = (type, description) => {
    return api[type]({
      message: t("stour"),
      description: description,
      duration: TIME_DURATION,
    });
  };

  const handleChangeLoaiTien = (dmTyGiaId) => {
    setDmTyGiaId(dmTyGiaId);
    getCauHinhMucGia({ id: dmTyGiaId });
  };

  const handleCheckMucGia = (e, item) => {
    debugger;
    setGiaMax(item.giaMax);
    setGiaMin(item.giaMin);
    setValue([...valueInit]);
    let checked = e.target.checked;
    const lstGiaApDung = [];
    if (checked) lstGiaApDung.push(item.id);

    // if (checked) {
    //   if (!lstGiaApDung.includes(item.id)) lstGiaApDung.push(item.id);
    // } else {
    //   if (lstGiaApDung.length > 0) {
    //     let idx = lstGiaApDung.findIndex((it) => it.id === item.id);
    //     lstGiaApDung.splice(idx, 1);
    //   }
    // }
    setGiaApDungs(lstGiaApDung);
  };

  const handleResetFilter = () => {
    // setDataMucGia([...dataMucGiaInit]);
    setDataMucGia(JSON.parse(JSON.stringify(dataMucGiaInit)));
    setDmTyGiaId(1);
    setValue([...valueInit]);
    setGiaApDungs([]);
  };

  let formatter = new Intl.NumberFormat("en-US", {});

  return (
    <>
      {contextHolder}
      <Modal
        title={
          <div style={{ textAlign: "center" }}>{t("confirm.oto.title")}</div>
        }
        open={isOpenConfirm}
        // onCancel={() => setIsOpenConfirm(false)}
        footer={[
          <div style={{ textAlign: "center" }}>
            <Button
              key="back"
              style={{ marginRight: "5px" }}
              variant="contained"
              onClick={handleSaveChooseService}
            >
              {t("no")}
            </Button>
            <Button
              variant="contained"
              key="submitConfirm"
              type="primary"
              onClick={handleSaveAllChooseService}
            >
              {t("yes")}
            </Button>
          </div>,
        ]}
      >
        <p style={{ textAlign: "center" }}>{t("confirm.oto.message")}</p>
      </Modal>
      <Modal
        title={t("tour.oto.chonDichVu")}
        open={isOpen}
        onOk={handleConfirmSave}
        onCancel={handleCancelChooseService}
        footer={null}
        // width={1300}
        // height={700}
        className="custom-modal-ncc"
      >
        <Divider style={{ backgroundColor: "black" }} />
        <Row
          spacing={2}
          style={{ marginBottom: "20px", justifyContent: "space-between" }}
        >
          <Col xs={4} sm={4} md={4} lg={4} xl={3}>
            <OutlinedInput
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
              size="small"
              placeholder="Mã/Tên nhà cung cấp"
              value={supplier}
              onChange={(e) => setSupplier(e.target.value)}
            />
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={3}>
            <OutlinedInput
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
              size="small"
              placeholder="Mã dịch vụ cung cấp"
              value={dichVu}
              onChange={(e) => setDichVu(e.target.value)}
            />
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={3}>
            <TextField
              label={t("formOfPricing")}
              select
              fullWidth
              size="small"
              name="hinhThucGiaId"
              onChange={(e) => setHinhThucGiaId(e.target.value)}
              value={hinhThucGiaId}
            >
              {dmHinhThucGia?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.hinh_thuc_gia}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={2}>
            <TextField
              label={t("number")}
              select
              fullWidth
              size="small"
              name="soChoId"
              onChange={(e) => setSoChoId(e.target.value)}
              value={soChoId}
            >
              {dmSoCho?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={2}>
            <TextField
              label={t("vehicleType")}
              select
              fullWidth
              size="small"
              name="loaiXeId"
              onChange={(e) => setLoaiXeId(e.target.value)}
              value={loaiXeId}
            >
              {dmLoaiXe?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <div style={{ display: "flex" }}>
              <TextField
                label={t("location.country")}
                select
                fullWidth
                size="small"
                name="quocGiaId"
                onChange={(event) => handleChangeQG(event)}
                value={quocGiaId}
              >
                {dataQuocGia?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label={t("location.city")}
                select
                fullWidth
                size="small"
                name="tinhId"
                onChange={(event) => setTinhId(event.target.value)}
                value={tinhId}
              >
                {dataTinhTP?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={2}>
            <Button
              style={{
                backgroundColor: "#898989",
                color: "white",
                textTransform: "none",
                width: "100%",
              }}
              key="filter"
              startIcon={<GridFilterAltIcon />}
              onClick={handleClick}
              aria-controls={"account-menu"}
              aria-haspopup="true"
              aria-expanded={"true"}
            >
              {t("filter")}
            </Button>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              // onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <DialogContent>
                <Row
                  style={{
                    display: "flex",
                    width: "400px",
                    alignItems: "center",
                  }}
                >
                  <Col span={5}>
                    <b>{t("exchangeRate.currency")}</b>
                  </Col>
                  <Col span={9}>
                    <TextField
                      select
                      fullWidth
                      size="small"
                      name="dmTyGiaId"
                      onChange={(e) => handleChangeLoaiTien(e.target.value)}
                      value={dmTyGiaId}
                    >
                      {dmTyGia?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Col>
                </Row>
                <Divider></Divider>
                <Row>
                  <Col span={5}>
                    <b>{t("priceLevel")}</b>
                  </Col>
                </Row>
                <Row>
                  <FormGroup
                    sx={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "480px",
                    }}
                  >
                    {dataMucGia.map((item) => {
                      return (
                        <FormControlLabel
                          key={item.id}
                          sx={{ width: "150px" }}
                          control={
                            <Checkbox
                              checked={giaApDungs?.includes(item.id)}
                              onClick={(e) => handleCheckMucGia(e, item)}
                            />
                          }
                          label={item.label}
                        />
                      );
                    })}
                  </FormGroup>
                </Row>
                <Divider />
                <Row>
                  <Col span={5}>
                    <b>{t("priceRange")}</b>
                  </Col>
                </Row>
                <Row>
                  <Col span={4} />
                  <Col span={16}>
                    <Slider
                      getAriaLabel={() => "Minimum distance"}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      min={0}
                      max={dmTyGiaId === 1 ? 100000000 : 10000}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={4} />
                  <Col span={7}>
                    <TextField
                      label={t("tour.oto.minValue")}
                      value={
                        dmTyGiaId === 1
                          ? `${formatter.format(value[0])} đ`
                          : `${formatter.format(value[0])} $`
                      }
                      size="small"
                      disabled
                    ></TextField>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={7}>
                    <TextField
                      label={t("tour.oto.maxValue")}
                      value={
                        dmTyGiaId === 1
                          ? `${formatter.format(value[1])} đ`
                          : `${formatter.format(value[1])} $`
                      }
                      size="small"
                      disabled
                    ></TextField>
                  </Col>
                </Row>
                <Divider />
                <Row style={{ justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      marginRight: "5px",
                      backgroundColor: "#898989",
                    }}
                    onClick={() => handleResetFilter()}
                  >
                    {t("action.reset")}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "none" }}
                    onClick={handleApplyFilter}
                  >
                    {t("action.apply")}
                  </Button>
                </Row>
              </DialogContent>
            </Menu>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={2}>
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={handleSearchChooseService}
            >
              {t("search")}
            </Button>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender,
              defaultExpandedRowKeys: ["0"],
            }}
            dataSource={dataNccOto}
            style={{ width: "100%" }}
          />
        </Row>
        <Row>
          <Col span={12}></Col>
          <Col span={12}>
            <Button
              variant="contained"
              sx={{ marginRight: "5px" }}
              onClick={handleConfirmSave} // handleSaveChooseService
              startIcon={<SaveIcon />}
            >
              {t("action.ok")}
            </Button>
            <Button
              key="reset"
              startIcon={<RestartAltIcon />}
              style={{
                backgroundColor: "#898989",
                color: "white",
                marginRight: "5px",
              }}
              onClick={handleResetChooseService}
            >
              {t("action.reset")}
            </Button>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={handleCancelChooseService}
            >
              {t("action.cancel")}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ModalThongTinNccOto;
