import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const NestedMenu = ({ t, onEdit, onDelete, onCancel }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [taiXuongAnchor, setTaiXuongAnchor] = useState(null);
    const [baoGiaAnchor, setBaoGiaAnchor] = useState(null);
    const [tachHoaDonAnchor, setTachHoaDonAnchor] = useState(null);

    // Main menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTaiXuongAnchor(null);
        setBaoGiaAnchor(null);
        setTachHoaDonAnchor(null);
    };

    // Submenu handlers
    const handleTaiXuongClick = (event) => {
        setTaiXuongAnchor(event.currentTarget);
    };

    const handleBaoGiaClick = (event) => {
        setBaoGiaAnchor(event.currentTarget);
    };

    const handleTachHoaDonClick = (event) => {
        setTachHoaDonAnchor(event.currentTarget);
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>

            {/* Main Menu */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleTaiXuongClick}>
                    <ListItemText primary="Tải xuống" />
                    <ListItemIcon>
                        <ArrowRightIcon />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={onEdit}>Sửa</MenuItem>
                <MenuItem onClick={onDelete}>Xóa</MenuItem>
                <MenuItem>Nhân bản</MenuItem>
                <MenuItem>Chuyển Booking</MenuItem>
                <MenuItem onClick={onCancel}>Hủy báo giá</MenuItem>
                <MenuItem>Xem lịch sử</MenuItem>
            </Menu>

            {/* Tải xuống Menu */}
            <Menu
                anchorEl={taiXuongAnchor}
                open={Boolean(taiXuongAnchor)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={handleBaoGiaClick}>
                    <ListItemText primary="Báo giá gốc" />
                    <ListItemIcon>
                        <ArrowRightIcon />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem onClick={handleBaoGiaClick}>
                    <ListItemText primary="Báo giá bổ sung" />
                    <ListItemIcon>
                        <ArrowRightIcon />
                    </ListItemIcon>
                </MenuItem>
            </Menu>

            {/* Báo giá Menu */}
            <Menu
                anchorEl={baoGiaAnchor}
                open={Boolean(baoGiaAnchor)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem>
                    <ListItemText primary="Chương trình Tour" />
                </MenuItem>
                <MenuItem>
                    <ListItemText primary="Chi phí Sale" />
                </MenuItem>
                <MenuItem onClick={handleTachHoaDonClick}>
                    <ListItemText primary="Tách hóa đơn" />
                    <ListItemIcon>
                        <ArrowRightIcon />
                    </ListItemIcon>
                </MenuItem>
            </Menu>

            {/* Tách hóa đơn Menu */}
            <Menu
                anchorEl={tachHoaDonAnchor}
                open={Boolean(tachHoaDonAnchor)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem>
                    <ListItemText primary="Tất cả" />
                </MenuItem>
                <MenuItem>
                    <ListItemText primary="Nhóm người lớn 01" />
                </MenuItem>
                <MenuItem>
                    <ListItemText primary="Nhóm người lớn 02" />
                </MenuItem>
                <MenuItem>
                    <ListItemText primary="Nhóm người lớn 03" />
                </MenuItem>
            </Menu>
        </div>
    );
};

export default NestedMenu;
