import { DeleteOutlined } from "@ant-design/icons";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { DataGrid, enUS, viVN } from "@mui/x-data-grid";
import { Col, Divider, Modal, Popconfirm, Radio, Row, Space, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dm_do_tuoi from "../../../../../api/category/dm_do_tuoi";
import Dm_ty_gia from "../../../../../api/category/dm_ty_gia";
import ChietTinhService from "../../../../../api/tour/chiettinh.service";

const ModalThongTinTreEm = ({ isOpen, onSave, onCancel, tourChietTinhId, txtThoiGianApDung }) => {
    const { t, i18n } = useTranslation();
    const [doTuoi, setDoTuoi] = useState(0);
    const [tenMau, setTenMau] = useState(null);
    const [dataDoTuoi, setdataDoTuoi] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [dataTyGia, setDataTyGia] = useState([]);
    const [id, setId] = useState(0);

    useEffect(() => {
        if (tourChietTinhId !== 0) {
            let data = {
                tourChietTinhId: tourChietTinhId,
            };
            ChietTinhService.getGiaTreEm(data)
                .then(function (response) {
                    if (response.status === 200) {
                        let data = response.data.data;
                        console.log("data tre em", data);

                        let dataTE = [];
                        // let element = data[0];
                        if (data.length > 0) {
                            data.forEach((element) => {
                                let values = element.values;
                                if (values.length > 0)
                                    for (let i = 0; i < values.length; i++) {
                                        dataTE.push({
                                            id: "NULL " + Math.random() * (100 - 1),
                                            dmDoTuoiId: element.dmDoTuoiId,
                                            dmDoTuoiId_: element.id,
                                            markupType: null,
                                            charge1Child: null,
                                            loaiTien: null,
                                            apDung: null,
                                        });
                                        dataTE.push({
                                            id: Math.random() * (100 - 1),
                                            id_: values[i].id,
                                            dmDoTuoiId: element.dmDoTuoiId,
                                            dmDoTuoiId_: element.id,
                                            markupType: t("tour.percentage"),
                                            charge1Child: values[i].phanTram,
                                            loaiTien: values[i].dmTyGiaId,
                                            apDung: values[i].apDung === 1 ? 1 : 2,
                                        });
                                        dataTE.push({
                                            id: Math.random() * (100 - 1),
                                            id_: values[i].id,
                                            dmDoTuoiId: element.dmDoTuoiId,
                                            dmDoTuoiId_: element.id,
                                            markupType: t("tour.fixedAmount"),
                                            charge1Child: values[i].soTien,
                                            loaiTien: values[i].dmTyGiaId,
                                            apDung: values[i].apDung === 2 ? 1 : 2,
                                        });
                                        dataTE.push({
                                            id: "NULL " + Math.random() * (100 - 1),
                                            dmDoTuoiId: element.dmDoTuoiId,
                                            dmDoTuoiId_: element.id,
                                            markupType: null,
                                            charge1Child: null,
                                            loaiTien: null,
                                            apDung: null,
                                        });
                                    }
                                else {
                                    dataTE.push({
                                        id: "NULL " + Math.random() * (100 - 1),
                                        dmDoTuoiId: element.dmDoTuoiId,
                                        dmDoTuoiId_: element.id,
                                        markupType: null,
                                        charge1Child: null,
                                        loaiTien: null,
                                        apDung: null,
                                    });
                                }
                            });

                            setId(data[0].id);
                            setDoTuoi(data[0].dmDoTuoiId);
                        }
                        dataTE.push({ id: "SUBTOTAL" });
                        setDataTable(dataTE);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    }, [t, tourChietTinhId]);
    useEffect(() => {
        let data = {};
        Dm_ty_gia.getDmTyGiaHeThong(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDataTyGia(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
    }, []);
    useEffect(() => {
        let data = {};
        Dm_do_tuoi.getDmDoTuoi(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    console.log(data);
                    setdataDoTuoi(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
    }, []);
    const onFinish = (e) => {
        e.preventDefault();
        console.log(dataTable);
        var rowsTT = [];
        var giaTreEms = [];
        for (let i = 0; i < dataDoTuoi.length; i++) {
            const element = dataDoTuoi[i];
            let dataDT = dataTable.filter((dt) => dt.dmDoTuoiId === element.id);
            dataDT.forEach((element) => {
                if (element.markupType === "Percentage" || element.markupType === "FixedAmount") {
                    if (element.markupType === "Percentage") {
                        rowsTT.push({
                            type: "total",
                            id: element.id_.toString().indexOf("NEW") !== -1 ? 0 : element.id_,
                            dmTyGiaId: element.loaiTien,
                            phanTram: element.charge1Child,
                            apDung: element.apDung === 1 ? 1 : 2,
                        });
                    } else {
                        rowsTT[rowsTT.length - 1] = {
                            ...rowsTT[rowsTT.length - 1],
                            soTien: element.charge1Child,
                            apDung: element.apDung === 1 ? 2 : 1,
                        };
                    }
                }
            });
            giaTreEms.push({
                id:
                    dataDT[0]?.dmDoTuoiId_ && (dataDT[0]?.dmDoTuoiId_ + "").indexOf("NEW") === -1
                        ? dataDT[0]?.dmDoTuoiId_
                        : 0, // 0: thêm mới, >0 chỉnh sửa.
                dmDoTuoiId: element.id,
                values: [...rowsTT],
            });
            rowsTT = [];
        }

        let data = {
            tourChietTinhId: tourChietTinhId,
            giaTreEms: giaTreEms,
        };
        console.log(JSON.stringify(data));
        onSave(data, false);
    };
    const handleCancels = (e) => {
        e.preventDefault();
        onCancel(false);
    };
    const handleClear = () => {};
    const danhSachMau = () => {};
    const onChangeTrangThai = () => {};
    const handleDeleteRow = (row) => {
        if (dataTable.length === 0) {
            return;
        }
        let data = dataTable.filter((el) => el.id_ !== row.id_);
        setDataTable(data);
    };
    const getCellClassName = ({ row, field }) => {
        if (field === "action") {
            return "bold";
        }

        return "";
    };
    const handleAddRow = () => {
        var addRows = [];
        for (let i = 0; i < dataTable.length; i++) {
            const element = dataTable[i];
            if (element.id === "SUBTOTAL") {
                var id_ = "NEW" + i;
                addRows.push({
                    id: id_ + Math.random().toString(),
                    dmDoTuoiId: doTuoi,
                    id_: id_,
                    markupType: t("tour.percentage"),
                    charge1Child: 0,
                    loaiTien: 1,
                    apDung: 1,
                });
                addRows.push({
                    id_: id_,
                    id: id_ + Math.random().toString(),
                    dmDoTuoiId: doTuoi,
                    markupType: t("tour.fixedAmount"),
                    charge1Child: 0,
                    loaiTien: 1,
                    apDung: 2,
                });
                addRows.push({
                    dmDoTuoiId: doTuoi,
                    id: "NULL " + id_,
                });
                addRows.push(element);
            } else addRows.push(element);
        }
        setDataTable(addRows);
    };
    const columns = [
        {
            field: "stt",
            headerName: t("stt"),
            width: 10,
            colSpan: ({ row }) => {
                if (row.id === "SUBTOTAL") {
                    return 2;
                }
                return undefined;
            },
            renderCell: ({ value, row }) => {
                if (row.id === "SUBTOTAL") {
                    return (
                        <Box
                            sx={{ display: "flex", alignItems: "flex-end" }}
                            onClick={() => handleAddRow(row.dmDoTuoiId)}
                        >
                            <AddIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                            <span style={{ marginBottom: "5px", fontSize: "15px" }}>{t("tour.themLoaiTien")}</span>
                        </Box>
                    );
                }
                return value;
            },
        },
        {
            field: "markupType",
            headerName: t("tour.markupType"),
            width: 150,
            flex: 1,
            headerAlign: "center",
            sortable: true,
        },
        {
            field: "charge1Child",
            headerName: t("tour.charge1Child"),
            width: 150,
            editable: true,
            align: "center",
            flex: 1,
            headerAlign: "center",
            sortable: true,
        },
        {
            field: "loaiTien",
            headerName: t("exchangeRate.currency"),
            type: "number",
            width: 110,
            align: "center",
            flex: 1,
            headerAlign: "center",
            renderCell: ({ value, row }) => {
                if (row.id.toString().indexOf("NULL") !== 0 && row.id.toString().indexOf("SUBTOTAL") !== 0) {
                    return (
                        <TextField
                            // label={t("tour.soKhachToiThieu")}
                            select
                            disabled={row.markupType !== t("tour.percentage") ? true : false}
                            fullWidth
                            name="loaiTien"
                            onChange={(e) => handleChangeLT(e.target.value, row)}
                            value={value}
                        >
                            {dataTyGia.map((option) => (
                                <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                    {option.maNgoaiTe}
                                </MenuItem>
                            ))}
                        </TextField>
                    );
                }
            },
        },
        {
            field: "apDung",
            headerName: t("action.apply"),
            description: t("action.apply"),
            sortable: true,
            flex: 1,
            headerAlign: "center",
            align: "center",
            width: 160,
            renderCell: ({ value, row }) => {
                if (row.id.toString().indexOf("NULL") !== 0 && row.id.toString().indexOf("SUBTOTAL") !== 0) {
                    return (
                        <Radio
                            checked={value === 1}
                            onChange={(e) => handleChangeRadio(row, e.target.value)}
                            value={1}
                            name={"radio-buttons" + row.id}
                            inputProps={{ "aria-label": "A" }}
                        />
                    );
                }
            },
        },
        {
            field: "action",
            headerName: t("action"),
            description: t("action"),
            sortable: false,
            align: "center",
            flex: 1,
            headerAlign: "center",
            width: 100,
            renderCell: ({ row }) => {
                if (
                    row.id.toString().indexOf("NULL") !== 0 &&
                    row.id.toString().indexOf("SUBTOTAL") !== 0 &&
                    row.markupType.toString().indexOf(t("tour.percentage"))
                ) {
                    return (
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDeleteRow(row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    );
                }
            },
        },
    ];
    const handleChangeLT = (value, row) => {
        console.log(value);
        setDataTable(
            dataTable.map((r) => {
                if (r.id_ === row.id_) {
                    r.loaiTien = parseInt(value);
                }
                return r;
            })
        );
    };
    const handleChangeRadio = (row, value) => {
        console.log(row);
        setDataTable(
            dataTable.map((r) => {
                if (r.id_ === row.id_ && r.id === row.id) {
                    r.apDung = parseInt(value);
                }
                if (r.id_ === row.id_ && r.id !== row.id) r.apDung = 2;
                return r;
            })
        );
    };
    const processRowUpdate = (updatedRow, originalRow) => {
        setDataTable(
            dataTable.map((dt) => {
                if (dt.id === updatedRow.id) return updatedRow;
                return dt;
            })
        );
    };
    const [tableDoTuoi, setTableDoTuoi] = useState([]);
    const [dataFilter, setDataFilter] = useState([]);
    useEffect(() => {
        if (doTuoi) {
            debugger;
            let data = dataTable.filter((e) => e.dmDoTuoiId === doTuoi);
            data.push({ id: "SUBTOTAL" });
            setDataFilter(data);
        }
    }, [dataTable, doTuoi]);
    return (
        <Modal
            title={t("tour.thongTinGiaTreEm")}
            open={isOpen}
            onOk={onFinish}
            onCancel={handleCancels}
            footer={null}
            width={1000}
            height={700}
        >
            <Divider />
            <Box component="form" noValidate>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                        <TextField
                            label={t("tour.chonDoTuoi")}
                            select
                            fullWidth
                            name="doTuoi"
                            onChange={(e) => setDoTuoi(e.target.value)}
                            value={doTuoi}
                        >
                            {dataDoTuoi.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.do_tuoi}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={3}></Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={4}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                        <Button sx={{ marginTop: "10px" }} variant="outlined" onClick={danhSachMau} type="submit">
                            {t("tour.danhSachMau")}
                        </Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                        <Button variant="contained">{t("tour.total")}</Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}></Row>
                <Box
                    sx={{
                        width: "100%",
                        "& .bold": {
                            marginBottom: 100,
                        },
                    }}
                >
                    <DataGrid
                        autoheight
                        processRowUpdate={processRowUpdate}
                        rows={dataFilter}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 100,
                                },
                            },
                        }}
                        autoHeight
                        pageSizeOptions={[10]}
                        localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
                        getCellClassName={getCellClassName}
                        experimentalFeatures={{ columnGrouping: true }}
                        // checkboxSelection
                        // disableRowSelectionOnClick
                    />
                </Box>
                <Row style={{ marginTop: 6 }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormControlLabel
                            style={{ marginTop: "14px" }}
                            label={t("tour.luuMau")}
                            control={<Switch style={{ marginLeft: "10px" }} onChange={onChangeTrangThai} />}
                            labelPlacement="start"
                        />
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            sx={{
                                marginBottom: 2,
                            }}
                            fullWidth
                            label={t("tour.tenMau")}
                            multiline
                            rows={1}
                            value={tenMau}
                            onChange={(e) => setTenMau(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 6 }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={10}>
                        <Space>
                            <Button variant="contained" onClick={onFinish} type="submit" startIcon={<SaveIcon />}>
                                {t("action.ok")}
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#898989",
                                    color: "white",
                                }}
                                key="reset"
                                startIcon={<RestartAltIcon />}
                                onClick={(e) => handleClear(e)}
                            >
                                {t("action.reset")}
                            </Button>
                            <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleCancels} key="clear">
                                {t("action.cancel")}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Box>
        </Modal>
    );
};
export default ModalThongTinTreEm;
