import React, { useEffect, useState } from "react";
import { Row, Table, Cascader } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { API_BASE_URL } from "../../../constants";
import authHeader from "../../../api/auth_header";
import { DataGrid, GridToolbarQuickFilter, enUS, viVN } from "@mui/x-data-grid";
import i18n from "../../../i18n";

const Location = () => {
    const [dataSource, setDataSource] = useState([]);
    // const [dataCity, setDataCity] = useState([]);
    // const [dataDistrict, setDataDistrict] = useState([]);
    // const [dataWar, setDataWar] = useState([]);
    // const [isEnableH, setIsEnableH] = useState(true);
    // const [isEnableW, setIsEnableW] = useState(true);
    // const [inputString, setInputString] = useState('');
    const [optionsCascader, setOptionsCascader] = useState([]);
    // const quoc_gia = {
    //     "dm_quoc_gia_id": 247
    // }
    useEffect(() => {
        let data = {
            dm_quoc_gia_id: 247,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    var source = [];
                    for (let i = 0; i < response.data.data.length; i++) {
                        const element = { id: i, ...response.data.data[i] };
                        source.push(element);
                    }
                    setDataSource(source);

                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = { value: o.tinh_thanhpho, label: o.tinh_thanhpho, children: [] };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = { value: o.quan_huyen, label: o.quan_huyen, children: [] };
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        setOptionsCascader(result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    // const apiTinhTP = () => {
    //
    //     axios({
    //         method: 'post',
    //         url: API_BASE_URL + "category/danhSachTinhThanhPho",
    //         data: quoc_gia,
    //         headers: header_token
    //     }).then(function (response) {
    //         console.log("Response: ====" + response.status)
    //         if (response.status === 200) {
    //             console.log(response.data.data)
    //             setDataCity(response.data.data)
    //         }
    //     }).catch(function (response) {
    //         console.log("Error response: ====" + response)
    //         if (response.status === 401) {
    //
    //         }
    //     });
    // }
    // const apiHuyen = (data) => {
    //     axios({
    //         method: 'post',
    //         url: API_BASE_URL + "category/danhSachHuyen",
    //         data: data,
    //         headers: header_token
    //     }).then(function (response) {
    //         console.log("Response: ====" + response.status)
    //         if (response.status === 200) {
    //             console.log(response.data.data)
    //             setDataDistrict(response.data.data)
    //         }
    //     }).catch(function (response) {
    //         console.log("Error response: ====" + response)
    //         if (response.status === 401) {
    //
    //         }
    //     });
    // }
    // const apiXa = (data) => {
    //     axios({
    //         method: 'post',
    //         url: API_BASE_URL + "category/danhSachXa",
    //         data: data,
    //         headers: header_token
    //     }).then(function (response) {
    //         console.log("Response: ====" + response.status)
    //         if (response.status === 200) {
    //             console.log(response.data.data)
    //             setDataWar(response.data.data)
    //         }
    //     }).catch(function (response) {
    //         console.log("Error response: ====" + response)
    //         if (response.status === 401) {
    //
    //         }
    //     });
    // }
    const { t } = useTranslation();

    const columns = [
        {
            headerName: t("location.idWards"),
            field: "dm_xa_id",
            key: "dm_xa_id",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("location.wards"),
            field: "phuong_xa",
            key: "phuong_xa",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("location.district"),
            field: "quan_huyen",
            key: "quan_huyen",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("location.city"),
            field: "tinh_thanhpho",
            key: "tinh_thanhpho",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("location.country"),
            field: "quoc_gia",
            key: "quoc_gia",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];
    const onChange = (key) => {
        if (key === 2) {
        }
    };
    // const handleClick = (e) => {
    //     setIsHidden(true)
    //     apiTinhTP()
    // }
    // const handleClickT = (e) => {
    //     let data = {
    //         "dm_tinh_id": e.target.id
    //     }
    //     apiHuyen(data)
    //     setIsEnableH(false)
    //     setInputString(e.target.value)
    // }
    // const handleClickH = (e) => {
    //     let data = {
    //         "dm_huyen_id": e.target.id
    //     }
    //     apiXa(data)
    //     setIsEnableW(false)
    //     setInputString(inputString + "," + e.target.value)
    //     console.log("=====" + e.target)
    // }
    // const handleClickX = (e) => {
    //     let dataLst = []
    //     setInputString(inputString + "," + e.target.value)
    //     console.log("+++++" + inputString)
    //     setIsHidden(false)
    //     dataLst.push({
    //         value: inputString
    //     })
    //     setOptions(dataLst)
    // }
    // const contentCity = () => {
    //     let html = (
    //         <div>
    //             {dataCity.map(item => <p key={item.id} id={item.id} value={item.tinh_thanhpho}
    //                                      onClick={handleClickT}> {item.tinh_thanhpho} </p>)}
    //         </div>
    //     )
    //     return html
    // }
    // const contentHuyen = () => {
    //     let html = (
    //         <div>
    //             {dataDistrict.map(item => <p key={item.id} id={item.id} value={item.quan_huyen}
    //                                          onClick={handleClickH}> {item.quan_huyen} </p>)}
    //         </div>
    //     )
    //     return html
    // }
    // const contentXa = () => {
    //     let html = (
    //         <div>
    //             {dataWar.map(item => <p key={item.id} id={item.id} value={item.phuong_xa}
    //                                     onClick={handleClickX}> {item.phuong_xa} </p>)}
    //         </div>
    //     )
    //     return html
    // }
    // const items = [
    //     {
    //         key: '1',
    //         label: t('location.city'),
    //         children: contentCity(),
    //     },
    //     {
    //         key: '2',
    //         label: t('location.district'),
    //         children: contentHuyen(),
    //         disabled: isEnableH
    //     },
    //     {
    //         key: '3',
    //         label: t('location.wards'),
    //         children: contentXa(),
    //         disabled: isEnableW
    //     },
    // ];

    // const showTabs = [
    //     <Tabs defaultActiveKey="1" items={items} onChange={onChange}
    //           style={{overflow: "scroll", height: "200px", display: "block"}}/>
    // ];
    // const handleSearch = (value) => {
    //     let valueSp = value.split(', ');
    //     let data = dataSource.filter(el => el.dm_xa_id === parseInt(valueSp[0]));
    //     setDataSource(data);
    // }
    const onChangeCascader = (value, selectedOptions) => {
        console.log(value, selectedOptions);
    };
    const filterCascader = (inputValue, path) =>
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

    return (
        <div>
            <div
                style={{
                    marginBottom: 10,
                }}
            >
                <Row style={{ padding: "20px" }}>
                    <Cascader
                        options={optionsCascader}
                        onChange={onChangeCascader}
                        style={{
                            width: 600,
                        }}
                        showSearch={{
                            filterCascader,
                        }}
                        onSearch={(value) => console.log(value)}
                    />
                </Row>
            </div>
            <DataGrid
                sx={{
                    "& .MuiDataGrid-cell": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiDataGrid-row": {
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        backgroundColor: "#e2e2e2",
                        marginTop: "10px"
                    },
                    "& .MuiDataGrid-columnHeaderTitle ": {
                        fontWeight: "bold",
                    },
                }}
                rows={dataSource}
                columns={columns}
                slots={{}}
                autoHeight
                {...dataSource}
                pageSizeOptions={[10]}
                localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
            />
        </div>
    );
};
export default Location;
