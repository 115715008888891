import { DeleteOutlined } from "@ant-design/icons";
import AddIcon from "@mui/icons-material/Add";
import { MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import { DataGrid, enUS, viVN } from "@mui/x-data-grid";
import { Popconfirm, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dm_ty_gia from "../../../../../api/category/dm_ty_gia";

const TableTotalThoiGianAD = ({ handleSave, item, setItems, items }) => {
    const { t, i18n } = useTranslation();
    const [rows, setRows] = useState([]);
    const [, setLoaiTien] = useState([]);
    const [dataTyGia, setDataTyGia] = useState(null);
    useEffect(() => {
        let data = {};
        Dm_ty_gia.getDmTyGiaHeThong(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDataTyGia(data);
                }
            })
            .catch(function (response) {
                console.log("Error getDmTyGiaHeThong: ====" + response);
            });
    }, []);
    useEffect(() => {
        if (item) setRows(item.rowsTT);
    }, [item]);
    const handleDeleteRow = (id) => () => {
        const updatedRows = rows.filter((row) => row.id_ !== id);

        const updatedItems = items.map((it) => (it.id_ === item.id_ ? { ...it, rowsTT: updatedRows } : it));

        setRows(updatedRows);
        setItems(updatedItems);
    };
    const columns = [
        {
            field: "stt",
            headerName: t("stt"),
            width: 10,
            editable: true,
            colSpan: ({ row }) => {
                if (row.id === "SUBTOTAL") {
                    return 2;
                }
                return undefined;
            },
            renderCell: ({ value, row }) => {
                if (row.id === "SUBTOTAL") {
                    return (
                        <Box sx={{ display: "flex", alignItems: "flex-end" }} onClick={() => handleAddRow()}>
                            <AddIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                            <span style={{ marginBottom: "5px", fontSize: "15px" }}>{t("tour.themLoaiTien")}</span>
                        </Box>
                    );
                }
                return value;
            },
        },
        {
            field: "markupType",
            headerName: t("tour.markupType"),
            width: 200,
            editable: true,
        },
        {
            field: "khachLe",
            headerName: t("tour.khachLe"),
            width: 150,
            editable: true,
        },
        {
            field: "daiLy",
            headerName: t("tour.daiLy"),
            width: 150,
            editable: true,
        },
        {
            field: "loaiTien",
            headerName: t("exchangeRate.currency"),
            type: "number",
            width: 110,
            renderCell: ({ value, row }) => {
                if (row.id.toString().indexOf("NULL") !== 0 && row.id.toString().indexOf("SUBTOTAL") !== 0) {
                    return (
                        <TextField
                            select
                            disabled={row.markupType !== t("tour.percentage")}
                            fullWidth
                            name="loaiTien"
                            onChange={(e) => {
                                var rowTT = rows.map((i) => {
                                    if (i.id_ === row.id_) {
                                        i.loaiTien = e.target.value;
                                    }
                                    return i;
                                });
                                setRows(rowTT);
                                var itNew = {
                                    ...item,
                                    rowsTT: rowTT,
                                };
                                setItems(
                                    items.map((it) => {
                                        if (it.id === itNew.id) return itNew;
                                        return it;
                                    })
                                );
                                setLoaiTien(e.target.value);
                            }}
                            value={value}
                        >
                            {dataTyGia?.map((option) => (
                                <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                    {option.maNgoaiTe}
                                </MenuItem>
                            ))}
                        </TextField>
                    );
                }
            },
        },
        {
            field: "apDung",
            headerName: t("action.apply"),
            description: t("action.apply"),
            sortable: false,
            width: 160,
            renderCell: ({ value, row }) => {
                if (row.id.toString().indexOf("NULL") !== 0 && row.id.toString().indexOf("SUBTOTAL") !== 0) {
                    return (
                        <Radio
                            checked={value === 1}
                            onChange={(e) => handleChangeRadio(row, e.target.value)}
                            value={1}
                            name={"radio-buttons" + row.id}
                            inputProps={{ "aria-label": "A" }}
                        />
                    );
                }
            },
        },
        {
            field: "action",
            headerName: t("action"),
            description: t("action"),
            sortable: false,
            width: 100,
            renderCell: ({ value, row }) => {
                if (row.id !== undefined) {
                    if (row.id.toString().indexOf("NULL") !== 0 && row.id.toString().indexOf("SUBTOTAL") !== 0) {
                        return (
                            <Typography.Link>
                                <Popconfirm
                                    title={t("deleteTheTask")}
                                    description={t("areYouConfirm")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={handleDeleteRow(row.id_)}
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Typography.Link>
                        );
                    }
                }
            },
        },
    ];
    const handleChangeRadio = (row, value) => {
        console.log(row);
        setRows(
            rows.map((r) => {
                if (r.id_ === row.id_ && r.id === row.id) {
                    r.apDung = parseInt(value);
                }
                if (r.id_ === row.id_ && r.id !== row.id) r.apDung = 2;
                return r;
            })
        );
    };

    const processRowUpdate = (updatedRow, originalRow) => {
        console.log(updatedRow);
        var rowTT = rows.map((tt) => {
            if (tt.id === updatedRow.id) return updatedRow;
            return tt;
        });
        setRows(rowTT);
        var itNew = {
            ...item,
            rowsTT: rowTT,
        };
        setItems(
            items.map((it) => {
                if (it.id === itNew.id) return itNew;
                return it;
            })
        );
    };
    const handleAddRow = () => {
        const addRows = rows.flatMap((element, i) => {
            if (element.id === "SUBTOTAL") {
                const id_ = "NEW" + i;
                return [
                    {
                        id: Math.random() * 999 + 1,
                        id_: id_,
                        tourChietTinhId: 0,
                        tourQuanLyKhoangKhachId: 0,
                        markupType: t("tour.percentage"),
                        khachLe: 0,
                        daiLy: 0,
                        type: 0,
                        loaiTien: 1,
                        apDung: 1,
                    },
                    {
                        id: Math.random() * 999 + 1,
                        id_: id_,
                        markupType: t("tour.fixedAmount"),
                        daiLy: 0,
                        khachLe: 0,
                        type: 0,
                        loaiTien: 1,
                        apDung: 2,
                    },
                    {
                        id: "NULL " + Math.random() * 999 + 1,
                        id_: id_,
                        tourChietTinhId: null,
                        tourQuanLyKhoangKhachId: null,
                        markupType: null,
                        type: null,
                        loaiTien: null,
                        apDung: null,
                    },
                    element,
                ];
            }
            return [element];
        });
        const updatedItems = items.map((it) => (it.id === item.id ? { ...it, rowsTT: addRows } : it));

        setRows(addRows);
        setItems(updatedItems);
    };

    return (
        <>
            <DataGrid
                autoHeight
                processRowUpdate={processRowUpdate}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
                sx={{
                    "& .MuiDataGrid-cell": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiDataGrid-row": {
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        backgroundColor: "#e2e2e2",
                    },
                    "& .MuiDataGrid-columnHeaderTitle ": {},
                }}
            />
        </>
    );
};
export default TableTotalThoiGianAD;
