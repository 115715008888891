import {
    CheckOutlined,
    CloseCircleFilled,
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    PlusSquareFilled,
} from "@ant-design/icons";
import { MenuItem } from "@material-ui/core";
import { Box, IconButton, InputAdornment, Link, Select, TextField } from "@mui/material";
import { Col, Collapse, Row, notification } from "antd";
import CheckIcon from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../api/auth_header";
import khachSanService from "../../../api/bao_gia/sale_rate/khach_san/khachSanService";
import dm_do_tuoi from "../../../api/category/dm_do_tuoi";
import dm_gia_ap_dung from "../../../api/category/dm_gia_ap_dung";
import otoService from "../../../api/tour/oto/oto.service";
import otoServiceBG from "../../../api/bao_gia/sale_rate/oto/otoService";
import { API_BASE_URL, DV_KS, DV_NH, DV_XE_OTO } from "../../../constants";
import TongKet from "./TongKet";
import baoGiaService from "../../../api/bao_gia/baoGiaService";
import nhaHangService from "../../../api/bao_gia/sale_rate/nha_hang/nhaHangService";
import ModalCapNhatKS from "./modalCapNhat/ModalCapNhatKS";
import ModalCapNhatOto from "./modalCapNhat/ModalCapNhatOto";
import ModalCapNhatNhaHang from "./modalCapNhat/ModalCapNhatNhaHang";
import { element } from "prop-types";

const ViewServies = ({
    dataDv,
    baoGiaTongQuanId,
    ngayKhoiHanh,
    setReload,
    dataNhomNgLon,
    dataNhomTreEm,
    soLuongNgLonBG,
    soLuongTreEmBG,
    soNgay,
    bgHanhTrinhs,
    tongGia,
    editingId,
    setEditingId,
}) => {
    const [api, contextHolder] = notification.useNotification();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [openNH, setOpenNh] = useState(false);
    const [openKs, setOpenKs] = useState(false);
    const [openOto, setOpenOto] = useState(false);
    const [dataTableTreEm, setDataTableTreEm] = useState([]);
    const [dataTableNguoiLon, setDataTableNguoiLon] = useState([]);
    const [dmDoTuoi, setDmDoTuoi] = useState([]);
    const [kieuPhong, setKieuPhong] = useState([]);
    const [dmBuaAn, setDmBuaAn] = useState([]);
    const [dmSoCho, setDmSoCho] = useState([]);
    const [dmVat, setDmVat] = useState([]);
    const [baoGiaHanhTrinhId, setBaoGiaHanhTrinhId] = useState(0);
    const [bgChietTinhSaleId, setBgChietTinhSaleId] = useState(0);
    const [bgMapCTSaleHtId, setBgMapCTSaleHtId] = useState(0);
    const [hanhDong, setHanhDong] = useState("ADD");
    const [dmDichVuId, setDmDichVuId] = useState(0);
    // let dataNhomTreEm = [];
    useEffect(() => {
        let data = {};
        dm_do_tuoi
            .getDmDoTuoi(data)
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmDoTuoi(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmKieuPhong",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].kieu_phong,
                                });
                            }
                        }
                        setKieuPhong(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        dm_gia_ap_dung
            .getDmBuaAn()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataBA = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataBA.push({
                                value: data[i].id,
                                label: data[i].ten_bua_an,
                            });
                        }
                    }
                    setDmBuaAn(dataBA);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log("Response setDmVat: ====", response.data.data);
                    let vat = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc)
                            vat.push({
                                value: data[i].vat,
                                label: data[i].vat_str,
                                id: data[i].id,
                            });
                    }
                    console.log("Response vat: ====", vat);
                    setDmVat(vat);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        otoService
            .getDmSoCho()
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dmSoCho = [];
                    data.forEach((item) => {
                        if (item.hieu_luc) {
                            dmSoCho.push({
                                value: item.id,
                                label: item.so_cho_str,
                            });
                        }
                    });
                    setDmSoCho(dmSoCho);
                }
            })
            .catch((response) => {
                console.log("Error response: ====" + response);
            });
    }, []);

    useEffect(() => {
        if (dataDv?.length > 0) {
            console.log("dataDv: ", JSON.stringify(dataDv));
            setDataSource(dataDv);
            let data = dataDv;
            let dataNL = [];
            let dataTe = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (element.mapCTSaleNguoiLon.length > 0) {
                    let rowSpanDv = 0;
                    for (let j = 0; j < element.mapCTSaleNguoiLon.length; j++) {
                        const mapCTSaleNguoiLon = element.mapCTSaleNguoiLon[j];
                        let idKS = dataNL.length;
                        if (element.tenDichVu !== DV_KS) {
                            rowSpanDv += mapCTSaleNguoiLon.dichVuNguoiLons.length;
                        }

                        for (let k = 0; k < mapCTSaleNguoiLon.dichVuNguoiLons.length; k++) {
                            const dichVuNguoiLons = mapCTSaleNguoiLon.dichVuNguoiLons[k];
                            console.log(dichVuNguoiLons.length);
                            if (element.tenDichVu === DV_KS) {
                                rowSpanDv += dichVuNguoiLons.giaDichVuKhachSans.length;
                                for (let z = 0; z < dichVuNguoiLons.giaDichVuKhachSans.length; z++) {
                                    const elementGiaKS = dichVuNguoiLons.giaDichVuKhachSans[z];
                                    if (z === 0) dichVuNguoiLons.rowSpan = dichVuNguoiLons.giaDichVuKhachSans.length;
                                    else dichVuNguoiLons.rowSpan = 0;
                                    let dvNL = {
                                        tranxBgChietTinhKsId: dichVuNguoiLons.tranxBgChietTinhKsId,
                                        dichVu: dichVuNguoiLons.dichVu,
                                        chiPhiTreEm: dichVuNguoiLons.chiPhiTreEm,
                                        vat: dichVuNguoiLons.vat,
                                        bgNhomNguoiLonId: dichVuNguoiLons.bgNhomNguoiLonId,
                                        tenNhomNguoiLon: dichVuNguoiLons.tenNhomNguoiLon,
                                        soLuongNguoiLon: dichVuNguoiLons.soLuongNguoiLon,
                                        dieuKienFoc: dichVuNguoiLons.dieuKienFoc,
                                        foc: dichVuNguoiLons.foc,
                                        rowSpan: dichVuNguoiLons.rowSpan,
                                        soTienDuocFoc: dichVuNguoiLons.soTienDuocFoc,
                                        id: "API" + Math.random(),
                                        ...elementGiaKS,
                                    };
                                    dataNL.push(mapDataNL(dvNL, mapCTSaleNguoiLon, element));
                                }
                            } else {
                                dichVuNguoiLons.rowSpan = 1;
                                dichVuNguoiLons.id = "API" + Math.random();
                                dichVuNguoiLons.giaDichVu = dichVuNguoiLons.donGia;
                                dataNL.push(mapDataNL(dichVuNguoiLons, mapCTSaleNguoiLon, element));
                            }
                        }
                    }

                    dataNL.push(mapDataNL(null, { id: "NULL" + Math.random(), rowSpan: 0, rowSpanDV: 0 }, element));
                    let first = dataNL.findIndex((dt) => dt.tenDichVu === element.tenDichVu);
                    console.log("check");
                    console.log(rowSpanDv);
                    console.log(first);
                    dataNL[first].rowSpanDV = rowSpanDv + 1;
                } else {
                    dataNL.push(mapDataNL(null, { id: "NULL" + Math.random(), rowSpan: 1, rowSpanDV: 1 }, element));
                }
                if (element.mapCTSaleTreEm.length > 0) {
                    // let rowSpanDV = 0;
                    for (let j = 0; j < element.mapCTSaleTreEm.length; j++) {
                        const mapCTSaleTreEm = element.mapCTSaleTreEm;
                        if (mapCTSaleTreEm[j]) {
                            let dichVuTreEms = mapCTSaleTreEm[j]?.dichVuTreEms;
                            let rowSpanLDV = mapCTSaleTreEm.reduce((num, item) => {
                                item.dichVuTreEms.forEach((it) => {
                                    it.details.forEach(() => {
                                        num++;
                                    });
                                });
                                return num;
                            }, 0);
                            if (dataNhomTreEm?.length === 0) rowSpanLDV += mapCTSaleTreEm.length * dichVuTreEms.length;
                            for (let h = 0; h < dichVuTreEms.length; h++) {
                                let details = dichVuTreEms[h].details;
                                for (let g = 0; g < details.length; g++) {
                                    let rowSpanDV = 0;
                                    let rowSpanLoaiDV = 0;
                                    let rowSpanNcc = 0;
                                    let firtIndexLoaiDichVu = mapCTSaleTreEm.findIndex((dt) => {
                                        let check = false;
                                        dt.dichVuTreEms?.forEach((it) => {
                                            if (it?.details?.length > 0) check = true;
                                        });
                                        return check;
                                    });
                                    let firtIndexTenNcc = dichVuTreEms.findIndex((dt) => dt?.details?.length > 0);
                                    if (dataNhomTreEm?.length > 0) {
                                        if (j === firtIndexLoaiDichVu && h === firtIndexTenNcc && g === 0)
                                            rowSpanLoaiDV = rowSpanLDV;
                                        if (h === firtIndexTenNcc && g === 0) {
                                            rowSpanNcc = dichVuTreEms.reduce((num, item) => {
                                                item.details.forEach(() => {
                                                    num++;
                                                });
                                                return num;
                                            }, 0);
                                        }
                                    } else {
                                        if (j === 0 && h === 0 && g === 0) {
                                            rowSpanLoaiDV = rowSpanLDV;
                                        }
                                        if (h === 0 && g === 0) {
                                            rowSpanNcc = dichVuTreEms.reduce((num, item) => {
                                                item.details.forEach(() => {
                                                    num++;
                                                });
                                                return num;
                                            }, 0);
                                            rowSpanNcc += dichVuTreEms.length;
                                        }
                                    }
                                    if (g === 0) {
                                        rowSpanDV = details.length;
                                        if (dataNhomTreEm?.length === 0) rowSpanDV++;
                                    }
                                    const detail = details[g];
                                    dataTe.push({
                                        id: Math.random() + "",
                                        ...detail,
                                        loaiDichVu: element.tenDichVu,
                                        bgCTSaleId: element.bgCTSaleId,
                                        dmDichVuId: element.dmDichVuId,
                                        bgMapCTSaleHtId: mapCTSaleTreEm[j].bgMapCTSaleHtId,
                                        nccThongTinChungId: mapCTSaleTreEm[j].nccThongTinChungId,
                                        parentBgMapCTSaleHtId: mapCTSaleTreEm[j].parentBgMapCTSaleHtId,
                                        tenNhaCungCap: mapCTSaleTreEm[j].tenNhaCungCap,
                                        dmGiaApDungId: mapCTSaleTreEm[j].dmGiaApDungId,
                                        ngayApDungs: mapCTSaleTreEm[j].ngayApDungs,
                                        dmLoaiApDungId: mapCTSaleTreEm[j].dmLoaiApDungId,
                                        moTa: mapCTSaleTreEm[j].moTa,
                                        dieuKienFoc: mapCTSaleTreEm[j].dieuKienFoc,
                                        foc: mapCTSaleTreEm[j].foc,
                                        mapBgChietTinhKsTreEmId: dichVuTreEms[h].mapBgChietTinhKsTreEmId,
                                        mapBgChietTinhNhTreEmId: dichVuTreEms[h].mapBgChietTinhNhTreEmId,
                                        mapBgChietTinhOtoTreEmId: dichVuTreEms[h].mapBgChietTinhOtoTreEmId,
                                        tenDichVu: dichVuTreEms[h].tenDichVu,
                                        giaDichVu: dichVuTreEms[h].donGia,
                                        // bgNhomTreEmId: dichVuTreEms[h].bgNhomTreEmId,
                                        tranxBgChietTinhKsId: dichVuTreEms[h].tranxBgChietTinhKsId,
                                        tranxBgChietTinhNhId: dichVuTreEms[h].tranxBgChietTinhNhId,
                                        tranxBgChietTinhOtoId: dichVuTreEms[h].tranxBgChietTinhOtoId,
                                        rowSpanNcc: rowSpanNcc,
                                        rowSpanLoaiDV: rowSpanLoaiDV,
                                        rowSpanDV: rowSpanDV,
                                    });
                                }
                                if (dataNhomTreEm?.length === 0)
                                    dataTe.push({
                                        id: "NEW_ADD" + Math.random(),
                                        loaiDichVu: element.tenDichVu,
                                        tenNhaCungCap: mapCTSaleTreEm[j].tenNhaCungCap,
                                        tenDichVu: dichVuTreEms[h].tenDichVu,
                                        giaDichVu: dichVuTreEms[h].donGia,
                                        bgCTSaleId: element.bgCTSaleId,
                                        dmDichVuId: element.dmDichVuId,
                                        mapBgChietTinhKsTreEmId: dichVuTreEms[h].mapBgChietTinhKsTreEmId,
                                        mapBgChietTinhNhTreEmId: dichVuTreEms[h].mapBgChietTinhNhTreEmId,
                                        mapBgChietTinhOtoTreEmId: dichVuTreEms[h].mapBgChietTinhOtoTreEmId,
                                        tranxBgChietTinhKsId: dichVuTreEms[h].tranxBgChietTinhKsId,
                                        tranxBgChietTinhNhId: dichVuTreEms[h].tranxBgChietTinhNhId,
                                        tranxBgChietTinhOtoId: dichVuTreEms[h].tranxBgChietTinhOtoId,
                                        rowSpanLoaiDV: details.length === 0 && j === 0 && h === 0 ? rowSpanLDV : 0,
                                        rowSpanNcc: details.length === 0 ? 1 : 0,
                                        rowSpanDV: details.length === 0 ? 1 : 0,
                                    });
                            }
                        }
                    }
                }
                // else
                //   dataTe.push(
                //     mapDataTe(
                //       null,
                //       null,
                //       { id: "NULL" + Math.random(), rowSpan: 1, rowSpanDV: 1 },
                //       element
                //     )
                //   );
            }
            setDataTableNguoiLon(tinhToanThanhTable(dataNL));
            setDataTableTreEm(dataTe);
        }
    }, [dataDv]);
    // const mapDataTe = (details, dichVuTreEms, mapCTSaleTreEm, element) => {
    //   if (details) {
    //     return {
    //       ...details,
    //       mapBgChietTinhKsTreEmId: dichVuTreEms.mapBgChietTinhKsTreEmId,
    //       mapBgChietTinhNhTreEmId: dichVuTreEms.mapBgChietTinhNhTreEmId,
    //       tenDichVuTreEm: dichVuTreEms.tenDichVu,
    //       bgNhomTreEmId: dichVuTreEms.bgNhomTreEmId,
    //       tranxBgChietTinhKsId: dichVuTreEms.tranxBgChietTinhKsId,
    //       tranxBgChietTinhNhId: dichVuTreEms.tranxBgChietTinhNhId,
    //       tranxBgChietTinhOtoId: dichVuTreEms.tranxBgChietTinhOtoId,
    //       bgMapCTSaleHtId: mapCTSaleTreEm.bgMapCTSaleHtId,
    //       nccThongTinChungId: mapCTSaleTreEm.nccThongTinChungId,
    //       parentBgMapCTSaleHtId: mapCTSaleTreEm.parentBgMapCTSaleHtId,
    //       tenNhaCungCap: mapCTSaleTreEm.tenNhaCungCap,
    //       dmGiaApDungId: mapCTSaleTreEm.dmGiaApDungId,
    //       dmLoaiApDungId: mapCTSaleTreEm.dmLoaiApDungId,
    //       moTa: mapCTSaleTreEm.moTa,
    //       dieuKienFoc: mapCTSaleTreEm.dieuKienFoc,
    //       foc: mapCTSaleTreEm.foc,
    //       tenDichVu: element.tenDichVu,
    //       bgChietTinhOPId: element.bgChietTinhOPId,
    //       bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
    //       dmDichVuId: element.dmDichVuId,
    //     };
    //   }
    //   if (dichVuTreEms) {
    //     return {
    //       ...dichVuTreEms,
    //       tenDichVuTreEm: dichVuTreEms.tenDichVu,
    //       bgMapCTSaleHtId: mapCTSaleTreEm.bgMapCTSaleHtId,
    //       nccThongTinChungId: mapCTSaleTreEm.nccThongTinChungId,
    //       parentBgMapCTSaleHtId: mapCTSaleTreEm.parentBgMapCTSaleHtId,
    //       tenNhaCungCap: mapCTSaleTreEm.tenNhaCungCap,
    //       dmGiaApDungId: mapCTSaleTreEm.dmGiaApDungId,
    //       dmLoaiApDungId: mapCTSaleTreEm.dmLoaiApDungId,
    //       moTa: mapCTSaleTreEm.moTa,
    //       dieuKienFoc: mapCTSaleTreEm.dieuKienFoc,
    //       foc: mapCTSaleTreEm.foc,
    //       tenDichVu: element.tenDichVu,
    //       bgChietTinhOPId: element.bgChietTinhOPId,
    //       bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
    //       dmDichVuId: element.dmDichVuId,
    //     };
    //   }
    //   if (mapCTSaleTreEm) {
    //     return {
    //       ...mapCTSaleTreEm,
    //       tenDichVu: element.tenDichVu,
    //       bgChietTinhOPId: element.bgChietTinhOPId,
    //       bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
    //       dmDichVuId: element.dmDichVuId,
    //     };
    //   }
    //   if (element)
    //     return {
    //       ...element,
    //     };
    // };

    const mapDataNL = (dichVuNguoiLons, mapCTSaleNguoiLon, element) => {
        if (dichVuNguoiLons) {
            if (element.tenDichVu === DV_KS)
                return {
                    ...dichVuNguoiLons,
                    bgMapCTSaleHtId: mapCTSaleNguoiLon.bgMapCTSaleHtId,
                    nccThongTinChungId: mapCTSaleNguoiLon.nccThongTinChungId,
                    parentBgMapCTSaleHtId: mapCTSaleNguoiLon.parentBgMapCTSaleHtId,
                    tenNhaCungCap: mapCTSaleNguoiLon.tenNhaCungCap,
                    dmGiaApDungId: mapCTSaleNguoiLon.dmGiaApDungId,
                    dmLoaiApDungId: mapCTSaleNguoiLon.dmLoaiApDungId,
                    moTa: mapCTSaleNguoiLon.moTa,
                    dieuKienFoc: mapCTSaleNguoiLon.dieuKienFoc,
                    foc: mapCTSaleNguoiLon.foc,
                    tenDichVu: element.tenDichVu,
                    bgChietTinhOPId: element.bgChietTinhOPId,
                    bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
                    dmDichVuId: element.dmDichVuId,
                    bgCTSaleId: element.bgCTSaleId,
                };
            else {
                return {
                    ...dichVuNguoiLons,
                    bgMapCTSaleHtId: mapCTSaleNguoiLon.bgMapCTSaleHtId,
                    nccThongTinChungId: mapCTSaleNguoiLon.nccThongTinChungId,
                    parentBgMapCTSaleHtId: mapCTSaleNguoiLon.parentBgMapCTSaleHtId,
                    tenNhaCungCap: mapCTSaleNguoiLon.tenNhaCungCap,
                    dmGiaApDungId: mapCTSaleNguoiLon.dmGiaApDungId,
                    dmLoaiApDungId: mapCTSaleNguoiLon.dmLoaiApDungId,
                    moTa: mapCTSaleNguoiLon.moTa,
                    tenDichVu: element.tenDichVu,
                    bgChietTinhOPId: element.bgChietTinhOPId,
                    bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
                    dmDichVuId: element.dmDichVuId,
                    bgCTSaleId: element.bgCTSaleId,
                };
            }
        }
        if (mapCTSaleNguoiLon)
            return {
                ...mapCTSaleNguoiLon,
                tenDichVu: element.tenDichVu,
                bgChietTinhOPId: element.bgChietTinhOPId,
                bgMapCTSaleHtIdDv: element.bgMapCTSaleHtId,
                dmDichVuId: element.dmDichVuId,
                bgCTSaleId: element.bgCTSaleId,
            };
        if (element)
            return {
                ...element,
            };
    };

    const [columns, setColumns] = useState([]);
    useEffect(() => {
        if (dataNhomNgLon && dataNhomNgLon.length > 0) {
            setColumns([
                {
                    title: "STT",
                },
                {
                    title: t("typeService"),
                },
                {
                    title: t("supplier"),
                },
                {
                    title: t("service"),
                },
                {
                    title: t("luaChon"),
                },
                {
                    title: t("hotel.roomClass.conditionFoc"),
                },
                {
                    title: t("tour.donGia"),
                },
                {
                    title: t("tour.vanChuyen.soLuong"),
                },
                {
                    title: t("hotel.roomClass.intoMoney"),
                },
                {
                    title: t("thuevat"),
                },
                {
                    title: t("adultGroup"),
                },
                {
                    title: t("action"),
                },
            ]);
        } else {
            setColumns([
                {
                    title: "STT",
                },
                {
                    title: t("typeService"),
                },
                {
                    title: t("supplier"),
                },
                {
                    title: t("service"),
                },
                {
                    title: t("luaChon"),
                },
                {
                    title: t("hotel.roomClass.conditionFoc"),
                },
                {
                    title: t("tour.donGia"),
                },
                {
                    title: t("tour.vanChuyen.soLuong"),
                },
                {
                    title: t("hotel.roomClass.intoMoney"),
                },
                {
                    title: t("thuevat"),
                },
                {
                    title: t("action"),
                },
            ]);
        }
    }, [dataNhomNgLon]);
    const columnsTe = [
        {
            title: "STT",
            visible: true,
        },
        {
            title: t("typeService"),
            visible: true,
        },
        {
            title: t("supplier"),
            visible: true,
        },
        {
            title: t("service"),
            visible: true,
        },
        {
            title: t("childrenGroup"),
            visible: dataNhomTreEm.length > 0,
        },
        {
            title: t("ageOfChild"),
            visible: true,
        },
        {
            title: t("tour.percentage"),
            visible: true,
        },
        {
            title: t("tour.donGia"),
            visible: true,
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            visible: true,
        },
        {
            title: t("hotel.roomClass.intoMoney"),
            visible: true,
        },
        {
            title: t("thuevat"),
            visible: true,
        },
        {
            title: t("action"),
            visible: true,
        },
    ];
    const handleAddServices = (item) => {
        if (item.bgMapCTSaleHtId) setHanhDong("EDIT");
        else setHanhDong("ADD");
        setBgChietTinhSaleId(item.bgCTSaleId);
        setDmDichVuId(item.dmDichVuId);
        setBgMapCTSaleHtId(item.bgMapCTSaleHtId ? item.bgMapCTSaleHtId : 0);
        switch (item.tenDichVu) {
            case DV_KS:
                return setOpenKs(true);
            case DV_XE_OTO:
                return setOpenOto(true);
            case DV_NH:
                return setOpenNh(true);
            default:
                return null;
        }
    };
    const handleCancelKS = () => {
        setOpenKs(false);
    };

    const handleCancelOto = () => {
        setOpenOto(false);
    };

    const handleCancelNh = () => {
        setOpenNh(false);
    };
    const handleAddRowServices = (index, item) => {
        let dt = [];
        let crrFirstDV = dataTableNguoiLon.findIndex((it) => it.rowSpanDV !== 0 && it.tenDichVu === item.tenDichVu);
        setEditingId(crrFirstDV);
        let rowSpanDV = dataTableNguoiLon[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataTableNguoiLon.length; key++) {
            const element = dataTableNguoiLon[key];
            if (key === crrFirstDV) {
                dt.push({
                    id: Math.random() + "NEW",
                    rowSpan: 1,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
                    rowSpanDV: rowSpanDV,
                    tenDichVu: item.tenDichVu,
                    bgCTSaleId: item.bgCTSaleId,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    tenNhaCungCap: "",
                    dieuKienFoc: 0,
                    foc: 0,
                    donGia: "",
                    giaDichVu: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    dichVu: "",
                });

                element.rowSpanDV = 0;
            } else {
                // if (key === crrFirstDV) {
                //     element.rowSpanDV = rowSpanDV;
                // }
                // // if (key === crrFirst) {
                // //     element.rowSpan = rowSpan;
                // // }
                // if (key === index)
                //     dt.push({
                //         id: Math.random() + "NEW",
                //         rowSpan: 1,
                //         tenDichVu: item.tenDichVu,
                //         bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                //         nccThongTinChungId: item.nccThongTinChungId,
                //         parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                //         tenNhaCungCap: item.tenNhaCungCap,
                //         dmGiaApDungId: item.dmGiaApDungId,
                //         dmLoaiApDungId: item.dmLoaiApDungId,
                //         bgCTSaleId: item.bgCTSaleId,
                //         moTa: item.moTa,
                //         dieuKienFoc: item.dieuKienFoc,
                //         foc: item.foc,
                //         bgChietTinhOPId: item.bgChietTinhOPId,
                //         bgMapCTSaleHtIdDv: item.bgMapCTSaleHtId,
                //         dmDichVuId: item.dmDichVuId,
                //     });
            }
            dt.push(element);
        }
        setDataTableNguoiLon(dt);
    };
    const handleAddBySerivce = (dataTableNguoiLon, index, item) => {
        let dt = [];
        let crrFirst = dataTableNguoiLon.findIndex(
            (it) => it.rowSpan !== 0 && it.tenNhaCungCap === item.tenNhaCungCap && it.tenDichVu === item.tenDichVu
        );
        setEditingId(crrFirst);
        let crrFirstDV = dataTableNguoiLon.findIndex((it) => it.rowSpan !== 0 && it.tenDichVu === item.tenDichVu);
        let rowSpan = dataTableNguoiLon[crrFirst].rowSpan + 1;
        let rowSpanDV = dataTableNguoiLon[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataTableNguoiLon.length; key++) {
            const element = dataTableNguoiLon[key];
            if (key === crrFirst) {
                dt.push({
                    id: Math.random() + "NEW",
                    rowSpan: rowSpan,
                    tenDichVu: item.tenDichVu,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    nccThongTinChungId: item.nccThongTinChungId,
                    tenNhaCungCap: item.tenNhaCungCap,
                    bgCTSaleId: item.bgCTSaleId,
                    dieuKienFoc: item.dieuKienFoc,
                    foc: item.foc,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    rowSpanDV: rowSpanDV,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
                    donGia: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    dichVu: "",
                });
                element.rowSpanDV = 0;
                element.rowSpan = 0;
            }

            if (
                element.rowSpan !== 0 &&
                element.tenNhaCungCap === item.tenNhaCungCap &&
                element.tenDichVu === item.tenDichV
            ) {
                element.rowSpanDV = 0;
                element.rowSpan = 0;
            }
            dt.push(element);
        }
        setDataTableNguoiLon(dt);
    };
    const handleChangeTenNCC = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.tenNhaCungCap = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeDmDichVuId = (index, value, tenDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index && tenDichVu === DV_KS) item.dmKieuPhongId = value;
            if (key === index && tenDichVu === DV_NH) item.dmBuaAnId = value;
            if (key === index && tenDichVu === DV_XE_OTO) item.soXe = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeDieuKienFoc = (index, value, tenDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.dieuKienFoc = value;
            }

            return item;
        });
        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeDonGia = (index, value, tenDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.giaDichVu = value;
            }
            return item;
        });
        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeFoc = (index, value, tenDichVu) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.foc = value;
            }
            return item;
        });

        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeThanhTien = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.thanhTien = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleChangeVat = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.vat = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const tinhToanThanhTable = (data) => {
        let dtChange = [];
        dtChange = data.map((element) => {
            if (element.id.indexOf("NULL") === -1)
                if (element.tenDichVu === DV_NH) {
                    element.thanhTien = tinhThanhTienNH(
                        element.dieuKienFoc,
                        element.giaDichVu,
                        element.foc,
                        element.soLuong
                    );
                } else if (element.tenDichVu === DV_XE_OTO) {
                    element.thanhTien = element.soLuong * element.giaDichVu;
                } else {
                    let dataKS = data.filter(
                        (it) => it.tenDichVu === element.tenDichVu && it.tenNhaCungCap === element.tenNhaCungCap
                    );
                    let thanhTien = tinhThanhTienKS(
                        dataKS.map((it) => it.soLuong),
                        dataKS.map((it) => it.giaDichVu),
                        Math.floor(element.dieuKienFoc / element.foc)
                    );
                    for (let i = 0; i < dataKS.length; i++) {
                        if (dataKS[i].id === element.id) {
                            element.thanhTien = thanhTien[i];
                        }
                    }
                }
            return element;
        });
        return dtChange;
    };
    const handleChangeSoLuong = (index, value, tenDichVu) => {
        setEditingId(index);
        let data = [];
        data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.soLuong = value;
            }
            return item;
        });

        data = tinhToanThanhTable(data);
        setDataTableNguoiLon(data);
    };
    const handleChangeDichVu = (index, value) => {
        setEditingId(index);
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) item.dichVu = value;
            return item;
        });

        setDataTableNguoiLon(data);
    };
    const handleReload = () => {
        setEditingId(null);
        setReload(true);
    };

    const handleSave = (index, value, tenDichVu) => {
        let dichVus = [];
        let dichVuTreEms = [];
        let bgMapCTSaleHtId = value.id.indexOf("NEW") !== -1 ? value.id : value.bgMapCTSaleHtId;
        dataTableTreEm
            .filter((dt) => dt.bgMapCTSaleHtId === bgMapCTSaleHtId)
            .forEach((d) => {
                let idx = -1;
                dichVuTreEms.forEach((dv, index) => {
                    if (d.bgNhomTreEmId === dv.bgNhomTreEmId) {
                        idx = index;
                    }
                });
                if (idx === -1) {
                    dichVuTreEms.push({
                        bgNhomTreEmId: d.bgNhomTreEmId,
                        details: [
                            {
                                dmDoTuoiId: d.dmDoTuoiId,
                                phanTram: d.phanTram,
                                donGiaNhapTay: d.donGiaNhapTay,
                                soLuong: d.soLuong,
                                dmVatId: d.dmVatId,
                                apDung: value.id.indexOf("NEW") !== -1 || d.apDung,
                            },
                        ],
                    });
                } else {
                    dichVuTreEms[idx].details.push({
                        dmDoTuoiId: d.dmDoTuoiId,
                        phanTram: d.phanTram,
                        donGiaNhapTay: d.donGiaNhapTay,
                        soLuong: d.soLuong,
                        dmVatId: d.dmVatId,
                        apDung: value.id.indexOf("NEW") !== -1 || d.apDung,
                    });
                }
            });
        let giaDichVus = [];

        let dichVuData = {
            dmVatId: dmVat.find((d) => value.vat === d.vat)?.id,
            nccThongTinChungId: value.nccThongTinChungId,
            tenDichVu: value.dichVu,
            dmBuaAnId: value.dmBuaAnId,
            dmKieuPhongId: value.dmKieuPhongId,
            dmGiaApDungId: value.dmGiaApDungId,
            tranxGiaPhongId: 0,
            dmSoChoId: value.dmSoChoId,
            bgNhomNguoiLonId: value.bgNhomNguoiLonId,
            tranxNhaHangId: value.tranxNhaHangId || 0,
            nccDichVuNhaHangId: value.nccDichVuNhaHangId || 0,
            dichVuTreEms,
        };

        if (tenDichVu === DV_XE_OTO) {
            dichVuData = {
                ...dichVuData,
                giaOtoNhapTay: value.giaDichVu || 0,
                soLuong: value.soLuong || 0,
                bgdataNhomNgLon: value.bgdataNhomNgLon?.map((d) => ({ bgNhomNguoiLonId: d })) || [],
                soXe: value.soXe,
            };
        } else if (tenDichVu === DV_KS) {
            giaDichVus.push({
                dmKieuPhongId: value.dmKieuPhongId,
                maPhong: value.dichVu,
                soLuong: value.soLuong || 0,
                giaNhapTay: value.giaDichVu || 0,
            });
            for (let i = dataTableNguoiLon.length - 1; i >= 0; i--) {
                const elementV2 = dataTableNguoiLon[i];
                if (
                    value.bgMapCTSaleHtId === elementV2.bgMapCTSaleHtId &&
                    elementV2.tenDichVu === tenDichVu &&
                    elementV2.id.indexOf("NULL") === -1 &&
                    i !== index
                ) {
                    giaDichVus.push({
                        dmKieuPhongId: elementV2.dmKieuPhongId,
                        maPhong: elementV2.dichVu,
                        soLuong: elementV2.soLuong || 0,
                        giaNhapTay: elementV2.giaDichVu || 0,
                    });
                }
            }
            dichVuData = {
                ...dichVuData,
                giaNhaHangNhapTay: value.giaDichVu || 0,
                soLuong: value.soLuong || 0,
                dieuKienFoc: value.dieuKienFoc || 0,
                foc: value.foc || 0,
                giaDichVus: giaDichVus.length > 0 ? giaDichVus : null,
            };
        } else if (tenDichVu === DV_NH) {
            dichVuData = {
                ...dichVuData,
                giaNhaHangNhapTay: value.giaDichVu || 0,
                soLuong: value.soLuong || 0,
                dieuKienFoc: value.dieuKienFoc || 0,
                foc: value.foc || 0,
            };
        }

        dichVus.push(dichVuData);
        var ngayApDungs = [];
        for (let i = 0; i < soNgay; i++) {
            ngayApDungs.push(i + 1);
        }
        const data = {
            baoGiaTongQuanId: baoGiaTongQuanId,
            bgMapCTSaleHtId: value.bgMapCTSaleHtId || 0,
            nccThongTinChungId: value.nccThongTinChungId || 0,
            bgCTSaleId: value.bgCTSaleId || 0,
            tenNcc: value.tenNhaCungCap,
            dmDichVuId: value.dmDichVuId,
            dmGiaApDungId: value.dmGiaApDungId,
            dieuKienFoc: tenDichVu === DV_NH ? 0 : value.dieuKienFoc,
            foc: tenDichVu === DV_NH ? 0 : value.foc,
            dichVus: dichVus,
            ngayApDungs,
        };

        console.log(JSON.stringify(data));

        const saveService = (service, saveFunction) => {
            saveFunction(service).then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    handleReload();
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            });
        };

        if (tenDichVu === DV_KS) {
            saveService(data, khachSanService.saveCTSaleKhachSan);
        } else if (tenDichVu === DV_NH) {
            saveService(data, nhaHangService.saveChietTinhSaleNhaHang);
        } else if (tenDichVu === DV_XE_OTO) {
            saveService(data, otoServiceBG.saveCTSaleOto);
        }
    };
    const handleDelete = (key, value) => {
        const services = {
            "Xe ô tô": otoServiceBG.deleteCTSaleDichVuOto,
            "Khách sạn": khachSanService.deleteCTSaleDichVuKs,
            "Nhà hàng": nhaHangService.deleteCTSaleNhaHang,
        };

        const serviceMethod = services[value.tenDichVu];

        if (serviceMethod) {
            const params = {
                "Xe ô tô": {
                    baoGiaTongQuanId: baoGiaTongQuanId,
                    bgCTSaleId: value.bgCTSaleId,
                    tranxBgChietTinhOtoId: value.tranxBgChietTinhOtoId,
                },
                "Khách sạn": {
                    baoGiaTongQuanId: baoGiaTongQuanId,
                    bgCTSaleId: value.bgCTSaleId,
                    tranxBgChietTinhKsId: value.tranxBgChietTinhKsId,
                },
                "Nhà hàng": {
                    baoGiaTongQuanId: baoGiaTongQuanId,
                    bgCTSaleId: value.bgCTSaleId,
                    bgMapCTSaleHtId: value.bgMapCTSaleHtId,
                },
            };
            console.log(JSON.stringify(params[value.tenDichVu]));

            serviceMethod(params[value.tenDichVu]).then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    handleReload();
                    api.success({
                        message: t("stour"),
                        description: `${t("delete")} ${t("noti.success").toLowerCase()}`,
                        duration: 0,
                    });
                } else {
                    api.error({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            });
        }
    };
    const handleClear = () => {
        handleReload();
    };
    const handleChangeNhomNLOto = (index, event) => {
        const { value } = event.target; // Lấy giá trị từ sự kiện
        const newValue = typeof value === "string" ? value.split(",") : value; // Đảm bảo giá trị là mảng

        setEditingId(index);
        // Cập nhật dữ liệu người lớn với giá trị mới
        const updatedDataNguoiLon = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                return { ...item, bgdataNhomNgLon: newValue }; // Tạo bản sao để tránh thay đổi trực tiếp
            }
            return item;
        });

        // Lọc dữ liệu trẻ em liên quan dựa trên bgNhomNguoiLonId
        const relatedTE = dataNhomTreEm.filter((item) => newValue.includes(item.nhomNguoiLonId));

        // Lấy đối tượng người lớn đã cập nhật
        const updatedNguoiLonItem = updatedDataNguoiLon[index];

        // Gọi hàm xử lý trẻ em và cập nhật bảng người lớn
        handleAddRowServicesTE(dataTableTreEm, index, updatedNguoiLonItem, relatedTE);
        setDataTableNguoiLon(updatedDataNguoiLon);
    };
    const handleChangeNhomNL = (index, value) => {
        setEditingId(index);
        let itemTE;
        let data = dataTableNguoiLon.map((item, key) => {
            if (key === index) {
                item.bgNhomNguoiLonId = value;
                itemTE = item;
            }
            return item;
        });
        let relateTE = dataNhomTreEm.filter((item) => item.nhomNguoiLonId === value);
        console.log(relateTE);
        handleAddRowServicesTE(dataTableTreEm, index, itemTE, relateTE);
        setDataTableNguoiLon(data);
    };

    const handleAddRowServicesTE = (dataTableTreEm, index, item, relateTE) => {
        let bgMapCTSaleHtId = item.id.indexOf("NEW") !== -1 ? item.id : item.bgMapCTSaleHtId;
        var rowSpanLoaiDV = dataTableTreEm.filter((it) => it.loaiDichVu === item.tenDichVu).length;
        const dt = dataTableTreEm
            .filter((it) => it.bgMapCTSaleHtId !== bgMapCTSaleHtId)
            .map((it) => {
                if (it.loaiDichVu === item.tenDichVu) {
                    return { ...it, rowSpanLoaiDV: 0, rowSpanNcc: 0, rowSpanDV: 0 };
                }
                return it;
            });
        const rowTemplate = [];
        for (let i = 0; i < relateTE.length; i++) {
            rowSpanLoaiDV += relateTE[i].details.length;
        }
        for (let i = 0; i < relateTE.length; i++) {
            const element = relateTE[i];
            for (let y = 0; y < element.details.length; y++) {
                const elementDt = element.details[y];

                rowTemplate.push({
                    id: Math.random() + "NEW",
                    bgMapCTSaleHtId: bgMapCTSaleHtId,
                    rowSpanLoaiDV: i === 0 ? rowSpanLoaiDV : 0,
                    rowSpanNcc: i === 0 ? rowSpanLoaiDV : 0,
                    rowSpanDV: i === 0 ? rowSpanLoaiDV : 0,
                    rowSpanTenNhom: y === 0 ? element.details.length : 0,
                    loaiDichVu: item.tenDichVu,
                    tenNhaCungCap: item.tenNhaCungCap,
                    tenDichVu: item.dichVu,
                    giaDichVu: item.giaDichVu,
                    bgCTSaleId: item.bgCTSaleId,
                    dmDichVuId: item.dmDichVuId,
                    mapBgChietTinhKsTreEmId: item.mapBgChietTinhKsTreEmId,
                    mapBgChietTinhNhTreEmId: item.mapBgChietTinhNhTreEmId,
                    mapBgChietTinhOtoTreEmId: item.mapBgChietTinhOtoTreEmId,
                    tranxBgChietTinhKsId: item.tranxBgChietTinhKsId,
                    tranxBgChietTinhNhId: item.tranxBgChietTinhNhId,
                    tranxBgChietTinhOtoId: item.tranxBgChietTinhOtoId,
                    phanTram: 0,
                    soLuong: elementDt.soLuong,
                    dmDoTuoiId: elementDt.dmDoTuoiId,
                    dmVatId: 1,
                    tenNhomTreEm: element.tenNhom,
                    bgNhomTreEmId: element.id,
                    nhomNguoiLonId: elementDt.nhomNguoiLonId,
                });
            }
        }

        setDataTableTreEm(renderRowSpanTE([...rowTemplate, ...dt]));
    };
    const renderRowSpanTE = (list) => {
        // Step 1: Sort the list by `loaiDichVu` in descending order
        const result = list.sort((a, b) => b.loaiDichVu.localeCompare(a.loaiDichVu));

        // Helper function to calculate row spans
        const calculateRowSpan = (list, groupByKeys, rowSpanKey) => {
            const rowSpanMap = new Map();

            // Group the items based on `groupByKeys`
            list.forEach((item) => {
                const groupKey = groupByKeys.map((key) => item[key]).join("|");
                if (!rowSpanMap.has(groupKey)) {
                    rowSpanMap.set(groupKey, []);
                }
                rowSpanMap.get(groupKey).push(item);
            });

            // Assign rowSpan value to the first item in each group
            rowSpanMap.forEach((groupItems) => {
                groupItems[0][rowSpanKey] = groupItems.length;
                for (let i = 1; i < groupItems.length; i++) {
                    groupItems[i][rowSpanKey] = 0; // Set others to 0
                }
            });
        };

        // Step 2: Calculate rowSpan values for each level
        calculateRowSpan(list, ["loaiDichVu"], "rowSpanLoaiDV");
        calculateRowSpan(list, ["loaiDichVu", "tenNhaCungCap"], "rowSpanNcc");
        calculateRowSpan(list, ["loaiDichVu", "tenNhaCungCap", "tenDichVu"], "rowSpanDV");
        calculateRowSpan(list, ["loaiDichVu", "tenNhaCungCap", "tenDichVu", "tenNhomTreEm"], "rowSpanTenNhom");

        return result;
    };

    const tinhThanhTienNH = (numberOfRates, unitPrice, FOCConditions, countOfService) => {
        // Calculate FOC quantity
        const FOCQuantity = Math.floor(numberOfRates / FOCConditions);

        // Calculate total service cost
        const totalServiceCost = countOfService * unitPrice;

        // Calculate service cost after applying FOC policy
        return totalServiceCost - FOCQuantity ? FOCQuantity : 0 * unitPrice;
    };
    const tinhThanhTienKS = (numberOfRoomsBooked, unitPrices, FOCConditions) => {
        //FOCConditions dk foc
        //numberOfRoomsBooked: số lượng
        //unitPrices đơn giá
        // Define variables
        const tongSoLuongPhongNCC = numberOfRoomsBooked.reduce((acc, val) => acc + val, 0);
        // Calculate B5
        const lowestRoomCategoryPrice = Math.min(...unitPrices);
        const averageReducedPricePerRoom =
            (lowestRoomCategoryPrice * Math.floor(tongSoLuongPhongNCC / FOCConditions)) / tongSoLuongPhongNCC;

        // Calculate B6
        return numberOfRoomsBooked.map(
            (count, index) => count * unitPrices[index] - averageReducedPricePerRoom * count
        );
    };
    const table_nl = () => {
        return (
            <>
                <table>
                    <thead>
                        {columns.map((cl) => {
                            return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                        })}
                    </thead>
                    <tbody>
                        {dataTableNguoiLon?.map((item, key) => {
                            return (
                                <tr key={key}>
                                    <td>{key + 1}</td>
                                    {item.rowSpanDV && item.rowSpanDV !== 0 ? (
                                        <td rowSpan={item.rowSpanDV}>{item.tenDichVu}</td>
                                    ) : (
                                        <></>
                                    )}
                                    {item.id.indexOf("NULL") !== -1 ? (
                                        <>
                                            <>
                                                <td colSpan="100%">
                                                    {(editingId === null ? false : editingId !== key) ? (
                                                        <Link disabled>
                                                            {t("add") + " " + t("service").toLowerCase()}
                                                        </Link>
                                                    ) : (
                                                        <Link onClick={() => handleAddRowServices(key, item)}>
                                                            {t("add") + " " + t("service").toLowerCase()}
                                                        </Link>
                                                    )}
                                                </td>
                                            </>
                                        </>
                                    ) : (
                                        <>
                                            {item.rowSpan && item.rowSpan !== 0 ? (
                                                <td rowSpan={item.rowSpan}>
                                                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                        <TextField
                                                            disabled={editingId === null ? false : editingId !== key}
                                                            size="small"
                                                            value={item.tenNhaCungCap}
                                                            onChange={(e) => handleChangeTenNCC(key, e.target.value)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton size="small">
                                                                            <EditFilled
                                                                                onClick={() =>
                                                                                    handleAddServices(
                                                                                        item
                                                                                        // baoGiaHanhTrinhId,
                                                                                        // ngay
                                                                                    )
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton size="small">
                                                                            <CloseCircleFilled />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        {item.tenDichVu === DV_KS ? (
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleAddBySerivce(dataTableNguoiLon, key, item)
                                                                }
                                                            >
                                                                <PlusSquareFilled />
                                                            </IconButton>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Box>
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <TextField
                                                    disabled={editingId === null ? false : editingId !== key}
                                                    size="small"
                                                    value={item.dichVu}
                                                    onChange={(e) => handleChangeDichVu(key, e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                {item.tenDichVu === DV_KS ? (
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== key}
                                                        size="small"
                                                        select
                                                        value={item.dmKieuPhongId}
                                                        onChange={(e) =>
                                                            handleChangeDmDichVuId(key, e.target.value, item.tenDichVu)
                                                        }
                                                    >
                                                        {kieuPhong.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                ) : item.tenDichVu === DV_NH ? (
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== key}
                                                        size="small"
                                                        select
                                                        value={item.dmBuaAnId}
                                                        onChange={(e) =>
                                                            handleChangeDmDichVuId(key, e.target.value, item.tenDichVu)
                                                        }
                                                    >
                                                        {dmBuaAn.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                ) : (
                                                    <>
                                                        <TextField
                                                            disabled={editingId === null ? false : editingId !== key}
                                                            size="small"
                                                            value={item.dmDichVuId}
                                                            onChange={(e) =>
                                                                handleChangeDmDichVuId(
                                                                    key,
                                                                    e.target.value,
                                                                    item.tenDichVu
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </td>
                                            {item.rowSpan && item.rowSpan !== 0 ? (
                                                <td rowSpan={item.rowSpan}>
                                                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                        {item.tenDichVu === DV_XE_OTO ? (
                                                            <>
                                                                <TextField size="small" />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TextField
                                                                    disabled={
                                                                        editingId === null ? false : editingId !== key
                                                                    }
                                                                    size="small"
                                                                    onChange={(e) =>
                                                                        handleChangeDieuKienFoc(
                                                                            key,
                                                                            e.target.value,
                                                                            item.tenDichVu
                                                                        )
                                                                    }
                                                                    value={item.dieuKienFoc}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                {item.tenDichVu === DV_KS
                                                                                    ? t("room")
                                                                                    : t("set")}
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                                <TextField
                                                                    disabled={
                                                                        editingId === null ? false : editingId !== key
                                                                    }
                                                                    size="small"
                                                                    value={item.foc}
                                                                    onChange={(e) =>
                                                                        handleChangeFoc(
                                                                            key,
                                                                            e.target.value,
                                                                            item.tenDichVu
                                                                        )
                                                                    }
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                {t("hotel.roomClass.foc")}
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </Box>
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== key}
                                                        size="small"
                                                        value={item.giaDichVu}
                                                        onChange={(e) =>
                                                            handleChangeDonGia(key, e.target.value, item.tenDichVu)
                                                        }
                                                        type="number"
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled={editingId === null ? false : editingId !== key}
                                                        size="small"
                                                        onChange={(e) =>
                                                            handleChangeSoLuong(key, e.target.value, item.tenDichVu)
                                                        }
                                                        value={item.soLuong}
                                                        type="number"
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled
                                                        size="small"
                                                        value={item.thanhTien}
                                                        onChange={(e) => handleChangeThanhTien(key, e.target.value)}
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <TextField
                                                    disabled={editingId === null ? false : editingId !== key}
                                                    size="small"
                                                    select
                                                    value={item.vat}
                                                    onChange={(e) => handleChangeVat(key, e.target.value)}
                                                >
                                                    {dmVat.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </td>
                                            {dataNhomNgLon && dataNhomNgLon.length > 0 ? (
                                                <>
                                                    {item.tenDichVu === DV_XE_OTO ? (
                                                        <td>
                                                            <Select
                                                                disabled={
                                                                    editingId === null ? false : editingId !== key
                                                                }
                                                                size="small"
                                                                multiple
                                                                value={item.bgdataNhomNgLon ? item.bgdataNhomNgLon : []}
                                                                onChange={(e) => handleChangeNhomNLOto(key, e)}
                                                            >
                                                                {dataNhomNgLon.map((option) => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.tenNhom}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </td>
                                                    ) : (
                                                        <td>
                                                            <TextField
                                                                disabled={
                                                                    editingId === null ? false : editingId !== key
                                                                }
                                                                size="small"
                                                                select
                                                                value={item.bgNhomNguoiLonId}
                                                                onChange={(e) =>
                                                                    handleChangeNhomNL(key, e.target.value)
                                                                }
                                                            >
                                                                {dataNhomNgLon.map((option) => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.tenNhom}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </td>
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <IconButton
                                                        disabled={editingId === null ? false : editingId !== key}
                                                        onClick={() => handleSave(key, item, item.tenDichVu)}
                                                    >
                                                        <CheckOutlined />
                                                    </IconButton>
                                                    {editingId !== key ? (
                                                        <IconButton
                                                            onClick={() => handleDelete(key, item)}
                                                            disabled={editingId === null ? false : editingId !== key}
                                                        >
                                                            <DeleteOutlined />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton onClick={() => handleClear()}>
                                                            <CloseOutlined />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    };
    const handleAddAge = (dataTE, row) => {
        let indexAdd = dataTE.findIndex((it) => it.id === row.id);
        let dataRowAdd = { ...dataTE[indexAdd] };
        dataTE[indexAdd].rowSpanLoaiDV = 0;
        dataTE[indexAdd].rowSpanNcc = 0;
        dataTE[indexAdd].rowSpanDV = 0;

        dataTE.splice(indexAdd, 0, {
            id: Math.random() + "",
            rowSpanLoaiDV: 0,
            rowSpanNcc: 0,
            rowSpanDV: 0,
            loaiDichVu: row.loaiDichVu,
            tenNhaCungCap: row.tenNhaCungCap,
            tenDichVu: row.tenDichVu,
            giaDichVu: row.giaDichVu,
            bgCTSaleId: row.bgCTSaleId,
            dmDichVuId: row.dmDichVuId,
            mapBgChietTinhKsTreEmId: row.mapBgChietTinhKsTreEmId,
            mapBgChietTinhNhTreEmId: row.mapBgChietTinhNhTreEmId,
            mapBgChietTinhOtoTreEmId: row.mapBgChietTinhOtoTreEmId,
            tranxBgChietTinhKsId: row.tranxBgChietTinhKsId,
            tranxBgChietTinhNhId: row.tranxBgChietTinhNhId,
            tranxBgChietTinhOtoId: row.tranxBgChietTinhOtoId,
            phanTram: 0,
            soLuong: 0,
            dmDoTuoiId: 1,
            dmVatId: 1,
        });

        let crrFirstLoaiDichVu = dataTE.findIndex((it) => it.loaiDichVu === row.loaiDichVu);
        let crrFirstNcc = dataTE.findIndex(
            (it) => it.rowSpanNcc !== 0 && it.loaiDichVu === row.loaiDichVu && it.tenNhaCungCap === row.tenNhaCungCap
        );
        let crrFirstDV = dataTE.findIndex(
            (it) =>
                it.rowSpanDV !== 0 &&
                it.loaiDichVu === row.loaiDichVu &&
                it.tenNhaCungCap === row.tenNhaCungCap &&
                it.tenDichVu === row.tenDichVu
        );
        if (crrFirstLoaiDichVu !== -1) {
            dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV += dataRowAdd.rowSpanLoaiDV + 1;
            // dataTE[crrFirstLoaiDichVu].stt = dataRowAdd.stt;
        }
        if (crrFirstNcc !== -1) {
            dataTE[crrFirstNcc].rowSpanNcc += 1;
        } else {
            dataTE[indexAdd].rowSpanNcc += dataRowAdd.rowSpanNcc + 1;
            // dataTE[indexAdd].rowSpanNcc += 2;
        }
        if (crrFirstDV !== -1) {
            dataTE[crrFirstDV].rowSpanDV += 1;
        } else {
            dataTE[indexAdd].rowSpanDV += 2;
        }

        setDataTableTreEm([...dataTE]);
    };

    const handleSaveCTDoTuoi = async (row) => {
        let action = "edit";
        let dataReq = {
            baoGiaTongQuanId,
            bgCTSaleId: row.bgCTSaleId,
            dmDoTuoiId: row.dmDoTuoiId,
            phanTram: row.phanTram,
            soLuong: row.soLuong,
            dmVatId: row.dmVatId,
            apDung: true,
        };
        let response = { status: 500 };
        if (row.loaiDichVu === DV_KS) {
            if (row.tranxBgCtKsSaleTreEmId) dataReq.tranxBgCtKsSaleTreEmId = row.tranxBgCtKsSaleTreEmId;
            else {
                dataReq.tranxBgCtKsSaleTreEmId = 0;
                action = "add";
            }
            dataReq.mapBgChietTinhKsTreEmId = row.mapBgChietTinhKsTreEmId;
            dataReq.tranxBgChietTinhKsId = row.tranxBgChietTinhKsId;
            response = await khachSanService.saveChietTinhDoTuoi(dataReq);
        } else if (row.loaiDichVu === DV_NH) {
            if (row.tranxBgCtNhSaleTreEmId) dataReq.tranxBgCtNhSaleTreEmId = row.tranxBgCtNhSaleTreEmId;
            else {
                dataReq.tranxBgCtNhSaleTreEmId = 0;
                action = "add";
            }
            dataReq.mapBgCtNhSaleTreEmId = row.mapBgChietTinhNhTreEmId;
            dataReq.tranxBgChietTinhNhId = row.tranxBgChietTinhNhId;
            response = await nhaHangService.saveChietTinhDoTuoi(dataReq);
        } else {
            if (row.tranxBgCtNhSaleTreEmId) dataReq.tranxBgCtOtoSaleTreEmId = row.tranxBgCtOtoSaleTreEmId;
            else {
                dataReq.tranxBgCtOtoSaleTreEmId = 0;
                action = "add";
            }
            dataReq.mapBgCtOtoSaleTreEmId = row.mapBgChietTinhOtoTreEmId;
            dataReq.tranxBgChietTinhOtoId = row.tranxBgChietTinhOtoId;
            dataReq.giaNguoiLon = row.giaDichVu;
        }
        if (response.status === 200 && response.data.code === "00") {
            handleReload();
            showNoti("success", t(action) + " " + t("noti.success").toLowerCase());
        } else {
            showNoti("error", t("add") + " " + t("noti.error").toLowerCase());
        }
    };

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: 0,
        });
    };

    const handleDeleteAge = async (dataTE, row) => {
        let isDeleted = false;
        let res = { status: 500 };
        if (row.loaiDichVu === DV_KS && row.tranxBgCtKsSaleTreEmId) {
            res = await khachSanService.deleteChietTinhDoTuoi({
                baoGiaTongQuanId,
                bgCTSaleId: row.bgCTSaleId,
                tranxBgCtKsSaleTreEmId: row.tranxBgCtKsSaleTreEmId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else if (row.loaiDichVu === DV_NH && row.tranxBgCtNhSaleTreEmId) {
            res = await nhaHangService.deleteChietTinhDoTuoi({
                baoGiaTongQuanId,
                bgCTSaleId: row.bgCTSaleId,
                tranxBgCtNhSaleTreEmId: row.tranxBgCtNhSaleTreEmId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else if (row.loaiDichVu === DV_XE_OTO && row.tranxBgCtOtoSaleTreEmId) {
            res = await otoService.deleteChietTinhDoTuoi({
                baoGiaTongQuanId,
                bgCTSaleId: row.bgCTSaleId,
                tranxBgCtKsSaleTreEmId: row.tranxBgChietTinhKsId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else {
            isDeleted = true;
            showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
        }
        if (isDeleted) {
            let indexDeleted = dataTE.findIndex((it) => it.id === row.id);
            let dataDeleted = dataTE[indexDeleted];
            dataTE.splice(indexDeleted, 1);
            let crrFirstLoaiDichVu = dataTE.findIndex(
                (it) => it.rowSpanLoaiDV !== 0 && it.loaiDichVu === row.loaiDichVu
            );
            let crrFirstNcc = dataTE.findIndex(
                (it) =>
                    it.rowSpanNcc !== 0 && it.loaiDichVu === row.loaiDichVu && it.tenNhaCungCap === row.tenNhaCungCap
            );
            let crrFirstDV = dataTE.findIndex(
                (it) =>
                    it.rowSpanDV !== 0 &&
                    it.loaiDichVu === row.loaiDichVu &&
                    it.tenNhaCungCap === row.tenNhaCungCap &&
                    it.tenDichVu === row.tenDichVu
            );
            if (crrFirstLoaiDichVu !== -1) {
                dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV -= 1;
            } else {
                dataTE[indexDeleted].rowSpanLoaiDV = dataDeleted.rowSpanLoaiDV - 1;
                dataTE[indexDeleted].stt = dataDeleted.stt;
            }
            if (crrFirstNcc !== -1) {
                dataTE[crrFirstNcc].rowSpanNcc -= 1;
            } else {
                if (dataDeleted.rowSpanNcc !== 0) dataTE[indexDeleted].rowSpanNcc = dataDeleted.rowSpanNcc - 1;
                else dataTE[indexDeleted].rowSpanNcc = 1;
            }
            if (crrFirstDV !== -1) {
                dataTE[crrFirstDV].rowSpanDV -= 1;
            } else {
                if (dataDeleted.rowSpanDV !== 0) dataTE[indexDeleted].rowSpanDV = dataDeleted.rowSpanDV - 1;
                else dataTE[indexDeleted].rowSpanDV = 1;
            }

            setDataTableTreEm([...dataTE]);
        }
    };

    const handleShowNotiDeleteTE = (res) => {
        if (res.status === 200 && res.data.code === "00") {
            showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
            return true;
        } else {
            showNoti("error", t("delete") + " " + t("noti.error").toLowerCase());
            return false;
        }
    };

    const handleChangItemTE = (dataTE, row, e) => {
        let indexChange = dataTE.findIndex((it) => it.id === row.id);
        let name = e.target.name;
        let value = e.target.value;
        dataTE[indexChange][name] = value;
        if (name !== "dmDoTuoiId") {
            dataTE[indexChange].thanhTien =
                (dataTE[indexChange].phanTram / 100) * dataTE[indexChange].soLuong * dataTE[indexChange].giaDichVu;
        }
        setDataTableTreEm([...dataTE]);
    };

    const table_te = () => {
        let stt = 1;
        return (
            <>
                <table>
                    <thead>
                        {columnsTe
                            .filter((it) => it.visible)
                            .map((cl) => {
                                return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                            })}
                    </thead>
                    <tbody>
                        {dataTableTreEm?.map((it, key) => {
                            return (
                                <tr key={it.id}>
                                    {it.rowSpanLoaiDV !== 0 ? (
                                        <>
                                            <td rowSpan={it.rowSpanLoaiDV}>{stt++}</td>
                                            <td rowSpan={it.rowSpanLoaiDV}>{it.loaiDichVu}</td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {it.rowSpanNcc !== 0 ? <td rowSpan={it.rowSpanNcc}>{it.tenNhaCungCap}</td> : null}
                                    {it.rowSpanDV !== 0 ? <td rowSpan={it.rowSpanDV}>{it.tenDichVu}</td> : null}
                                    {it.id.indexOf("NEW_ADD") !== -1 ? (
                                        <>
                                            <td colSpan="100%">
                                                <Link onClick={() => handleAddAge(dataTableTreEm, it)}>
                                                    {t("add") + " " + t("age").toLowerCase()}
                                                </Link>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            {it.rowSpanTenNhom !== 0 ? (
                                                <td rowSpan={it.rowSpanTenNhom}>{it.tenNhomTreEm}</td>
                                            ) : null}
                                            <td width={"10%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={it.dmDoTuoiId}
                                                        size="small"
                                                        name="dmDoTuoiId"
                                                        select
                                                        disabled={dataNhomTreEm.length > 0}
                                                        onChange={(e) => handleChangItemTE(dataTableTreEm, it, e)}
                                                    >
                                                        {dmDoTuoi.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.do_tuoi}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                            </td>
                                            <td width={"8%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        type="number"
                                                        value={it.phanTram}
                                                        size="small"
                                                        name="phanTram"
                                                        onChange={(e) => handleChangItemTE(dataTableTreEm, it, e)}
                                                        InputProps={{
                                                            endAdornment: <>%</>,
                                                        }}
                                                    />
                                                </Box>
                                            </td>
                                            <td width={"10%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        value={it.giaDichVu}
                                                        size="small"
                                                        name="giaDichVu"
                                                        disabled={true}
                                                    ></TextField>
                                                </Box>
                                            </td>
                                            <td width={"8%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        type="number"
                                                        value={it.soLuong}
                                                        name="soLuong"
                                                        size="small"
                                                        onChange={(e) => handleChangItemTE(dataTableTreEm, it, e)}
                                                    />
                                                </Box>
                                            </td>
                                            <td width={"10%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        value={it.giaDichVu * it.soLuong * (it.phanTram / 100)}
                                                        size="small"
                                                        disabled={true}
                                                    />
                                                </Box>
                                            </td>
                                            <td width={"9%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        value={it.dmVatId}
                                                        size="small"
                                                        name="dmVatId"
                                                        select
                                                        onChange={(e) => handleChangItemTE(dataTableTreEm, it, e)}
                                                    >
                                                        {dmVat.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                            </td>
                                            <td width={"9%"}>
                                                <Row>
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <IconButton>
                                                            <CheckIcon onClick={() => handleSaveCTDoTuoi(it)} />
                                                        </IconButton>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <IconButton>
                                                            <Delete
                                                                onClick={() => handleDeleteAge(dataTableTreEm, it)}
                                                            />
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    };

    const item_date = [
        {
            key: "1",
            label: (
                <Row>
                    <Col span={20}>
                        <div
                            style={{
                                textTransform: "none",
                                fontSize: "13px",
                                fontWeight: "bold",
                            }}
                        >
                            {t("adultService")}
                        </div>
                    </Col>
                </Row>
            ),
            children: <>{table_nl()}</>,
        },
        {
            key: "2",
            label: (
                <Row>
                    <Col span={20}>
                        <div
                            style={{
                                textTransform: "none",
                                fontSize: "13px",
                                fontWeight: "bold",
                            }}
                        >
                            {t("childrenService")}
                        </div>
                    </Col>
                </Row>
            ),
            children: <>{table_te()}</>,
        },
    ];
    const children_dv = (
        <>
            <Row>
                <Col span={24}>
                    <Collapse defaultActiveKey={"1"} items={item_date} />
                </Col>
            </Row>
        </>
    );
    const cauHinh = [
        {
            key: "1",
            label: (
                <div
                    style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        fontWeight: "bold",
                    }}
                >
                    {t("serviceProvided")}
                </div>
            ),
            children: children_dv,
        },
        {
            key: "2",
            label: (
                <div
                    style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        fontWeight: "bold",
                    }}
                >
                    {t("totalPrice")}
                </div>
            ),
            children: (
                <>
                    <TongKet baoGiaTongQuanId={baoGiaTongQuanId} tongGia={tongGia} setReload={setReload} />
                </>
            ),
        },
    ];

    return (
        <>
            {contextHolder}
            <Collapse defaultActiveKey={"1"} items={cauHinh} />
            <ModalCapNhatKS
                isOpen={openKs}
                onReset={handleReload}
                handleCancel={handleCancelKS}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                baoGiaHanhTrinhId={0}
                baoGiaTongQuanId={baoGiaTongQuanId}
                bgChietTinhSaleId={bgChietTinhSaleId}
                dmDichVuId={dmDichVuId}
                bgMapCTSaleHtId={bgMapCTSaleHtId}
                soLuongTreEmBG={soLuongTreEmBG}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={0}
                bgHanhTrinhs={bgHanhTrinhs}
            />
            <ModalCapNhatOto
                isOpen={openOto}
                onReset={handleReload}
                handleCancel={handleCancelOto}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                baoGiaHanhTrinhId={0}
                baoGiaTongQuanId={baoGiaTongQuanId}
                bgChietTinhSaleId={bgChietTinhSaleId}
                dmDichVuId={dmDichVuId}
                bgMapCTSaleHtId={bgMapCTSaleHtId}
                soLuongTreEmBG={soLuongTreEmBG}
                soLuongNgLonBG={soLuongNgLonBG}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={0}
                bgHanhTrinhs={bgHanhTrinhs}
            />
            <ModalCapNhatNhaHang
                isOpen={openNH}
                onReset={handleReload}
                handleCancel={handleCancelNh}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                baoGiaHanhTrinhId={0}
                baoGiaTongQuanId={baoGiaTongQuanId}
                bgChietTinhSaleId={bgChietTinhSaleId}
                dmDichVuId={dmDichVuId}
                bgMapCTSaleHtId={bgMapCTSaleHtId}
                soLuongTreEmBG={soLuongTreEmBG}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={0}
                bgHanhTrinhs={bgHanhTrinhs}
            />
        </>
    );
};
export default ViewServies;
