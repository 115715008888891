import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import { Button, Image, Modal,  notification } from "antd";
import TourService from "../../../../../api/tour/tour.service";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { FileImageFilled } from "@ant-design/icons";
import ModalChuongTrinhTour from "../../addTourSp/tabChuongTrinhTour/ModalChuongTrinhTour";
import ChietTinhService from "../../../../../api/tour/chiettinh.service";
import {TIME_DURATION} from "../../../../../constants";

ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
        ],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
};
function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [imgFile, setImgFile] = useState([]);
    const [visible, setVisible] = useState([]);
    const [scaleStep, setScaleStep] = useState(1);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const showImg = (value, key) => {
        var visible = [];
        for (let i = 0; i < imgFile.length; i++) {
            if (i == key) {
                visible.push(value);
            }
        }
        setVisible(visible);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        TourService.getByIdChuongTrinhTour({
            id: row.id,
        })
            .then(function (response) {
                // console.log("Response: getByIdChuongTrinhTour====", response.data);
                if (response.data.data) setImgFile(response.data.data.imgFile);
            })
            .catch(function (response) {
                console.log("Error response: ====", response);
            });
    }, [props.row]);
    const handleFinish = (status, values) => {
        setIsModalOpen(status);
        TourService.saveChuongTrinhTour(values)
            .then(function (response) {
                // console.log("Response: saveChuongTrinhTour====", response.data);
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                    duration: 0,
                });
                if (response.status === 401) {
                }
            });
    };
    const deleteData = (idDT) => {
        let data = {
            id: idDT,
        };
        TourService.clearChuongtrinhTour(data)
            .then(function (response) {
                // console.log("Response: saveChuongTrinhTour====" + response.data);
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                // console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                    duration: TIME_DURATION,
                });
                if (response.status === 401) {
                }
            });
    };
    return (
        <React.Fragment>
            {contextHolder}
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.ngay}
                </TableCell>
                {row.hanhTrinh.length > 0 ? (
                    <>
                        <TableCell>{row.hanhTrinh} &nbsp;&nbsp;</TableCell>
                        <TableCell>
                            {row.dmDichVus != null
                                ? row.dmDichVus.map(function (item, i) {
                                      if (i > 0) return <>{" ," + item.tenDichVu}</>;
                                      return <>{item.tenDichVu}</>;
                                  })
                                : null}
                        </TableCell>
                        <TableCell>{row.diemDi.diaDiem}</TableCell>
                        <TableCell>
                            {row.diemDen
                                ? row.diemDen.map(function (item, i) {
                                      return <div key={i}>{item.diaDiem}</div>;
                                  })
                                : null}
                        </TableCell>
                        <TableCell>
                            {imgFile
                                ? imgFile.map(function (item, i) {
                                      var vs = visible && visible[i] ? visible[i] : false;
                                      return (
                                          <>
                                              <Button
                                                  key={i}
                                                  icon={<FileImageFilled />}
                                                  onClick={() => showImg(true, i)}
                                              />
                                              <Image
                                                  style={{
                                                      display: "none",
                                                  }}
                                                  fallback={
                                                      item.base64Value
                                                          ? "data:image/png;base64," + item.base64Value
                                                          : null
                                                  }
                                                  preview={
                                                      visible && visible[i]
                                                          ? {
                                                                visible,
                                                                scaleStep,
                                                                onVisibleChange: (value) => {
                                                                    showImg(value, i);
                                                                },
                                                            }
                                                          : false
                                                  }
                                              />
                                          </>
                                      );
                                  })
                                : null}
                        </TableCell>
                    </>
                ) : (
                    <TableCell>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                showModal();
                            }}
                        >
                            {t("add") + " " + t("trip")}
                        </Link>
                    </TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={row.noiDung}
                                // onReady={(editor) => {
                                //     // You can store the "editor" and use when it is needed.
                                //     console.log("Editor is ready to use!", editor);
                                // }}
                                // onBlur={(event, editor) => {
                                //     console.log("Blur.", editor);
                                // }}
                                // onFocus={(event, editor) => {
                                //     console.log("Focus.", editor);
                                // }}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <ModalChuongTrinhTour
                id={row.id}
                ngay={row.ngay}
                isOpen={isModalOpen}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
            />

            <ModalChuongTrinhTour
                id={row.id}
                ngay={row.ngay}
                isOpen={isModalOpen}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                key={row.id}
            />
        </React.Fragment>
    );
}
const ModalQuanLyChuongTrinh = ({ isOpen, tourChietTinhId, onCancel, tourThongTinChungId }) => {
    const { t, i18n } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [isOpenLoaiTien, setIsOpenLoaiTien] = useState(false);
    const navigate = useNavigate();
    const handleDv = (data) => {
        let text = "";
    };
    const handleDiemDi = (data) => {
        let text = "";
        if (data != undefined) {
        }
    };
    const handleDiemDen = (data) => {
        let text = "";
    };
    const showModalLoaiTien = () => {
        checkLoaiTien();
    };
    const checkLoaiTien = () => {
        let data = {
            typeData: 0,
            tourThongTinChungId: tourThongTinChungId,
        };
        ChietTinhService.getChietTinh(data)
            .then(function (response) {
                // console.log("Responsecode: ====" + response.data);
                if (response.data.code === "03") {
                    setIsOpenLoaiTien(true);
                } else {
                    return navigate("/dich-vu/tour-san-pham/edit?id="+ tourThongTinChungId);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
    };
    useEffect(() => {
        let data = {
            tourThongTinChungId: tourThongTinChungId,
        };
        TourService.getChuongTrinhTour(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataArr = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataArr.push({
                                stt: i + 1,
                                id: data[i].id,
                                ngay: data[i].ngay,
                                hanhTrinh: data[i].hanhTrinh,
                                noiDung: data[i].noiDung,
                                dmDichVus: data[i].dmDichVus,
                                diemDi: data[i].diemDi,
                                diemDen: data[i].diemDen,
                                imgFile: data[i].imgFile,
                                history: [
                                    {
                                        content: data[i].noiDung,
                                    },
                                ],
                            });
                        }
                    }
                    setDataSource(dataArr);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, [tourThongTinChungId]);
    // const direct = (record, status) => {
    //     return navigate("/dich-vu/tour-san-pham/" + status + "?id=" + record.id);
    // };
    const chuongTrinhTour = (record, status) => {
        // return navigate("/dich-vu/tour-san-pham/chiet-tinh-tour/" + status + "?id=" + record.id);
        // return navigate("/dich-vu/tour-san-pham/edit?id=" + tourThongTinChungId);
        return window.open("/dich-vu/tour-san-pham/edit?id=" + tourThongTinChungId,'_blank', 'rel=noopener noreferrer');
    };
    const handleCancel = () => {
        onCancel(false);
    };
    return (
        <>
            <Modal
                title={t("quanLyChuonTrinh")}
                open={isOpen}
                onCancel={handleCancel}
                footer={
                    <Button variant="contained" onClick={(e) => handleCancel()}>
                        {t("close")}
                    </Button>
                }
                width={1200}
                // bodyStyle={{ background: "#F5F5F5" }}
            >
                <TableContainer component={Paper}>
                    {contextHolder}
                    <Table aria-label="collapsible table">
                        <caption style={{ marginLeft: "85%" }}>
                            <Link component="button" variant="body2" onClick={chuongTrinhTour}>
                                {t("chuongTrinhTour")}
                            </Link>
                        </caption>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{t("day")}</TableCell>
                                <TableCell>{t("trip")}</TableCell>
                                <TableCell>{t("typeService")}</TableCell>
                                <TableCell>{t("point")}</TableCell>
                                <TableCell>{t("destination")}</TableCell>
                                <TableCell>{t("hinhAnh")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataSource.map((row) => (
                                <Row key={row.ngay} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Modal>
        </>
    );
};
export default ModalQuanLyChuongTrinh;
