import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Col, Row, Radio } from "antd";

export default function TongKet({ tongChietTinhResponses, danhSachKhoangKhach, changeTongKet, tyGia }) {
    const { t } = useTranslation();
    const [valueRadio, setValueRadio] = useState("total");
    const [giaNets, setGiaNets] = useState([]);
    const [tyGiaTab, setTyGiaTab] = React.useState(0);
    const onChangeRadio = (e) => {
        console.log("radio checked", e.target.value);
        setValueRadio(e.target.value);
        changeTongKet(e.target.value);
    };
    const columns = [
        {
            headerName: t("tour.soLuongKhach"),
        },
    ];
    const [columnTb, setColumnTb] = useState(columns);
    const [items, setItems] = useState([]);
    useEffect(() => {
        let column = [];
        let item_ = [];
        if (tongChietTinhResponses) {
            setTyGiaTab(tyGia);
            if (tongChietTinhResponses.length > 0) {
                setGiaNets(tongChietTinhResponses);
                item_.push(
                    {
                        id: "giaNet",
                        label: t("tour.giaNet"),
                        // giaNets: tongChietTinhResponses,
                    },
                    {
                        id: "giaBanDaiLy",
                        label: t("tour.giaBan") + " (" + t("tour.daiLy") + ")",
                        // giaNets: tongChietTinhResponses,
                    },
                    {
                        id: "giaBanKhachLe",
                        label: t("tour.giaBan") + " (" + t("tour.khachLe") + ")",
                        // giaNets: tongChietTinhResponses,
                    },
                    {
                        id: "foc",
                        label: "FOC",
                        // giaNets: tongChietTinhResponses,
                    },
                    {
                        id: "tongChiPhiDaiLy",
                        label: t("tour.tongChiPhi") + " (" + t("tour.daiLy") + ")",
                        // giaNets: tongChietTinhResponses,
                    },
                    {
                        id: "tongChiPhiKhachLe",
                        label: t("tour.tongChiPhi") + " (" + t("tour.khachLe") + ")",
                        // giaNets: tongChietTinhResponses,
                    },
                    {
                        id: "tongChiPhiTreEm",
                        label: t("tour.tong") + " " + t("tour.chiPhiTreEm").toLowerCase(),
                        // giaNets: tongChietTinhResponses,
                    }
                );
            }
        }
        setItems(item_);
        if (danhSachKhoangKhach !== undefined) {
            for (let j = 0; j < danhSachKhoangKhach.length; j++) {
                let checkVal = column.findIndex((c) => c.field === "giaDichVu" + danhSachKhoangKhach[j].id);
                if (checkVal === -1)
                    column.push({
                        headerName: danhSachKhoangKhach[j].dmLoaiKhachId === 1 ? danhSachKhoangKhach[j].soKhachToiThieu +"Pax"
                            : danhSachKhoangKhach[j].soKhachToiThieu + '-' +
                            danhSachKhoangKhach[j].soKhachToiDa + "Pax",
                    });
            }
            setColumnTb([...columns, ...column]);
        }
    }, [tyGia, tyGiaTab, tongChietTinhResponses, danhSachKhoangKhach]);
    const rows = [...items];
    const options = [
        {
            label: t("tour.tong"),
            value: "total",
        },
        {
            label: t("tour.chiTiet"),
            value: "detail",
        },
    ];
    const isNumeric = (str) => {
        if (typeof str != "string") return false; // we only process strings!
        return (
            !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ); // ...and ensure strings of whitespace fail
    };
    const handleData = (value) => {
        let number = 0;

        if (value !== undefined && value !== null) {
            number = parseInt(value);
            if (isNumeric(tyGiaTab)) {
                number = parseInt(value) * parseInt(tyGiaTab);
            }
        }
        return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") ;
    };
    return (
        <Box
            sx={{
                width: "100%",
                "& .bold": {
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.6)",
                },
                "& .header-name": {
                    backgroundColor: "#f7f7f7",
                    fontWeight: 600,
                },
            }}
        >
            <table>
                <thead>
                    <th colSpan="100%" style={{ textIndent: "10px" }}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={16} style={{ textAlign: "left" }}>
                                {t("tour.tongKet")}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                <Radio.Group options={options} onChange={onChangeRadio} value={valueRadio} />
                            </Col>
                        </Row>
                    </th>
                </thead>
                <thead>
                    {columnTb.map((cl) => {
                        return cl.headerName === t("tour.soLuongKhach") ? (
                            <th colSpan={4}>{cl.headerName}</th>
                        ) : (
                            <th>{cl.headerName}</th>
                        );
                    })}
                </thead>
                <tbody>
                    {rows.map((it) => {
                        console.log("=map=====")
                        return (
                            <>
                                <tr>
                                    {/*{it.id === "GIA_NET" ? (*/}
                                    <>
                                        <td style={{ textAlign: "right" }} colSpan={4}>
                                            {it.label}
                                        </td>
                                        {giaNets.length > 0 ? (
                                            <>
                                                {giaNets.map((item) => {
                                                    return it.id !== undefined ? (
                                                        <td>{handleData(item[it.id])}</td>
                                                    ) : (
                                                        <td>0.00</td>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                    {/*    ) :*/}
                                    {/*    (<>*/}
                                    {/*    </>)*/}
                                    {/*}*/}
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        </Box>
    );
}
