import React from 'react';
import { Col, Row, Space, Input, Dropdown, Form, Select, theme, Affix} from "antd";
import {BarsOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom"
import AddIcon from "@mui/icons-material/Add";
import {Button} from "@mui/material";


const {Search} = Input;
const {useToken} = theme;
const FormHdvTd = ({dataAdd, keySide, handleSearch}) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [bottom, setBottom] = React.useState(10);
    const onClickAdd = () => {
        switch (keySide) {
            case 5:
                return navigate('/ncc/huong-dan-vien-tu-do/new');
            default:
                return null;
        }
    }
    const items = [
        {
            key: "1",
            label: <h3>{t("search")}</h3>,
        },
        {
            key: "2",
            label: <h3>{t("search")}</h3>,
        },
    ];
    const handleClear = () => {
        form.resetFields();
    };
    const onSearch = (value) => {
        handleSearch(value)
    }
    const {token} = useToken();
    const onFinish = (values) => {
    };
    const contentStyle = {
        width: "300px",
        overflowY: "scroll",
        height: "400px",
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        border: "4px solid #CFC8C8",
    };
    const menuStyle = {
        boxShadow: 'none',
    };
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}><Search allowClear
                                                                placeholder={t("fullname") + '/ ' + t("phone")}
                                                                onSearch={onSearch}/></Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                <Dropdown
                    menu={{
                        items,
                    }}
                    dropdownRender={(menu) => (
                        <div style={contentStyle}>
                            {/*{React.cloneElement(menu, {*/}
                            {/*    style: menuStyle,*/}
                            {/*})}*/}
                            <Form
                                form={form}
                                style={{marginLeft: "10px"}}
                                onFinish={onFinish}
                                layout="vertical"
                            >
                                <h4>{t("search") + ', ' + t("lookup").toLowerCase()}</h4>

                                <Form.Item
                                    name="hoVaTen"
                                    label={t("fullname")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    name="loaiThe"
                                    label={t("cardType")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select/>
                                </Form.Item>
                                <Form.Item
                                    name="ngonNgu"
                                    label="language"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select/>
                                </Form.Item>
                                <Form.Item
                                    name="ngonNgu"
                                    label={t("language")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select/>
                                </Form.Item>
                                <Form.Item
                                    name="sdt"
                                    label={t("phone")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    label={t("email")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    name="tinhId"
                                    label={t("location.city")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Affix offsetBottom={bottom}>
                                    <Form.Item
                                        wrapperCol={{
                                            span: 5,
                                            offset: 5,
                                        }}
                                    >

                                        <Space>
                                            <Button type="primary" htmlType="submit">
                                                {t("action.apply")}
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor: "#898989",
                                                    color: "white",
                                                }}
                                                key="clear"
                                                onClick={(e) => handleClear(e)}
                                            >
                                                {t("action.reset")}
                                            </Button>
                                        </Space>

                                    </Form.Item>
                                </Affix>
                            </Form>
                        </div>
                    )}
                >
                    <Button icon={<BarsOutlined/>} type="dashed">
                        {t("filter")}
                    </Button>
                </Dropdown>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                {/*<Space size="small">*/}
                {/*    /!*<Button icon={<DownloadOutlined/>} type="default">*!/*/}
                {/*    /!*    {t("exportFile")}*!/*/}
                {/*    /!*</Button>*!/*/}
                {/*    /!*<Button icon={<ImportOutlined/>} type="dashed">*!/*/}
                {/*    /!*    {t("importFile")}*!/*/}
                {/*    /!*</Button>*!/*/}
                {/*    <Button onClick={onClickAdd} icon={<PlusOutlined/>} type="primary">*/}
                {/*        {t("add")}*/}
                {/*    </Button>*/}
                {/*</Space>*/}
                <Button sx={{marginTop:"5px"}} variant="outlined" onClick={onClickAdd} startIcon={<AddIcon />} color="warning">
                    {t("add")}
                </Button>
            </Col>
        </Row>

    )
}
export default FormHdvTd