import React, {  } from "react";
import {  Row } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// const { Search } = Input;

const FormTC = ({ dataAdd, keySide, handleSearch }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const onClickAdd = () => {
        switch (keySide) {
            case 9:
                return navigate("/ncc/ve-thang-canh/new");
            default:
                return null;
        }
    };
    // const onSearch = (value) => {
    //     handleSearch(value);
    // };
    return (
        <Row>
            {/*<Col xs={6} sm={6} md={6} lg={6} xl={6}></Col>*/}
            {/*<Col xs={9} sm={9} md={9} lg={9} xl={9}></Col>*/}
            {/*<Col xs={6} sm={6} md={6} lg={6} xl={6}>*/}
            {/*    <Space size="large">*/}
            {/*        /!*<Button icon={<DownloadOutlined/>} type="default">*!/*/}
            {/*        /!*    {t("exportFile")}*!/*/}
            {/*        /!*</Button>*!/*/}
            {/*        /!*<Button icon={<ImportOutlined/>} type="dashed">*!/*/}
            {/*        /!*    {t("importFile")}*!/*/}
            {/*        /!*</Button>*!/*/}
            {/*        <Button onClick={onClickAdd} icon={<PlusOutlined />} type="primary">*/}
            {/*            {t("add")}*/}
            {/*        </Button>*/}
            {/*    </Space>*/}
            {/*</Col>*/}
            <Button sx={{marginTop:"5px"}} variant="outlined" onClick={onClickAdd} startIcon={<AddIcon />} color="warning">
                {t("add")}
            </Button>
        </Row>
    );
};
export default FormTC;
