import {
    CheckOutlined,
    CloseCircleFilled,
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    PlusSquareFilled,
} from "@ant-design/icons";
import CheckIcon from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import { Box, IconButton, InputAdornment, Link, MenuItem, Select, TextField } from "@mui/material";
import { Col, Collapse, Row, notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../api/auth_header";
import baoGiaService from "../../../api/bao_gia/baoGiaService";
import khachSanService from "../../../api/bao_gia/sale_rate/khach_san/khachSanService";
import nhaHangService from "../../../api/bao_gia/sale_rate/nha_hang/nhaHangService";
import otoService from "../../../api/bao_gia/sale_rate/oto/otoService";
import dm_do_tuoi from "../../../api/category/dm_do_tuoi";
import dm_gia_ap_dung from "../../../api/category/dm_gia_ap_dung";
import otoServiceS from "../../../api/tour/oto/oto.service";
import { API_BASE_URL, DV_KS, DV_NH, DV_XE_OTO } from "../../../constants";
import TongKet from "./TongKet";
import ModalCapNhatKS from "./modalCapNhat/ModalCapNhatKS";
import ModalCapNhatNhaHang from "./modalCapNhat/ModalCapNhatNhaHang";
import ModalCapNhatOto from "./modalCapNhat/ModalCapNhatOto";
const ViewDates = ({
    dataNgay,
    baoGiaTongQuanId,
    ngayKhoiHanh,
    isEffect,
    setReload,
    dataNhomNgLon,
    dataNhomTreEm,
    soLuongNgLonBG,
    soLuongTreEmBG,
    soNgay,
    bgHanhTrinhs,
    tongGia,
    editingId,
    setEditingId,
}) => {
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [dataCT, setDataCT] = useState([]);
    const [openNH, setOpenNh] = useState(false);
    const [openKs, setOpenKs] = useState(false);
    const [openOto, setOpenOto] = useState(false);
    const [dataDichVu, setDataDichVu] = useState([]);
    const [baoGiaHanhTrinhId, setBaoGiaHanhTrinhId] = useState(0);
    const [bgChietTinhSaleId, setBgChietTinhSaleId] = useState(0);
    const [bgMapCTSaleHtId, setBgMapCTSaleHtId] = useState(0);
    const [ngay, setNgay] = useState(0);
    const [dmDichVuId, setDmDichVuId] = useState(0);
    const [hanhDong, setHanhDong] = useState("ADD");
    const [tuNgay, setTuNgay] = useState("");
    const [denNgay, setDenNgay] = useState("");
    const [dmDoTuoi, setDmDoTuoi] = useState([]);
    const [dmVat, setDmVat] = useState([]);
    const [dataDoTuoi, setDataDoTuoi] = useState([]);
    const [kieuPhong, setKieuPhong] = useState([]);
    const [dmBuaAn, setDmBuaAn] = useState([]);
    const [dmSoCho, setDmSoCho] = useState([]);
    //   const [tongGia, setTongGia] = useState([]);
    // let dataNhomTreEm = [];
    useEffect(() => {
        let data = {};
        dm_do_tuoi
            .getDmDoTuoi(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataDotTuoi = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataDotTuoi.push({
                                value: data[i].id,
                                label: data[i].do_tuoi,
                            });
                        }
                    }
                    setDataDoTuoi(dataDotTuoi);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmKieuPhong",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let dataQMP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQMP.push({
                                    value: data[i].id,
                                    label: data[i].kieu_phong,
                                });
                            }
                        }
                        setKieuPhong(dataQMP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        dm_gia_ap_dung
            .getDmBuaAn()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    let dataBA = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataBA.push({
                                value: data[i].id,
                                label: data[i].ten_bua_an,
                            });
                        }
                    }
                    setDmBuaAn(dataBA);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        otoServiceS
            .getDmSoCho()
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dmSoCho = [];
                    data.forEach((item) => {
                        if (item.hieu_luc) {
                            dmSoCho.push({
                                value: item.id,
                                label: item.so_cho_str,
                            });
                        }
                    });
                    setDmSoCho(dmSoCho);
                }
            })
            .catch((response) => {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function async(response) {
                if (response.status === 200) {
                    let dataDV = [];
                    let data = response.data.data;
                    if (data?.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDV.push({
                                    id: data[i].id,
                                    value: data[i].ten_dich_vu,
                                });
                            }
                        }
                        setDataDichVu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
        getDmDoTuoi();
        getDmVat();
    }, []);
    useEffect(() => {
        if (isEffect) {
            let dateArr = ngayKhoiHanh?.split("/");
            if (dataNgay?.length > 0) {
                if (dateArr) {
                    handleSetTuNgayAndDenNgay(dateArr, dataNgay.length);
                }
                let newArr = [...dataNgay];
                for (let i = 0; i < dataNgay.length; i++) {
                    let dataTableNL = [];
                    let dataTableTE = [];
                    for (let j = 0; j < dataNgay[i].dichVusResponses?.length; j++) {
                        for (let k = 0; k < dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon.length; k++) {
                            let rowSpanDV = 0;
                            let rowSpan = 0;

                            for (
                                let index = 0;
                                index < dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dichVuNguoiLons.length;
                                index++
                            ) {
                                const element =
                                    dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dichVuNguoiLons[index];

                                if (dataNgay[i].dichVusResponses[j].tenDichVu === DV_KS) {
                                    debugger;
                                    if (
                                        dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dichVuNguoiLons[index]
                                            .giaDichVuKhachSans.length === 0
                                    ) {
                                        for (
                                            let x = 0;
                                            x <
                                            dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dichVuNguoiLons[index]
                                                .giaDichVuKhachSans.length;
                                            x++
                                        ) {
                                            const elementDv =
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dichVuNguoiLons[
                                                    index
                                                ].giaDichVuKhachSans[x];
                                            if (k === 0 && index === 0 && x === 0) {
                                                rowSpanDV = dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon.reduce(
                                                    (num, item) => {
                                                        item.dichVuNguoiLons.forEach((dvNL) => {
                                                            dvNL.giaDichVuKhachSans.forEach((dv) => {
                                                                num++;
                                                            });
                                                        });
                                                        return num;
                                                    },
                                                    1
                                                );
                                                // rowSpanDV += 1;
                                            } else rowSpanDV = 0;
                                            if (x === 0) {
                                                rowSpan =
                                                    dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k]
                                                        .dichVuNguoiLons[x].giaDichVuKhachSans.length;
                                            } else rowSpan = 0;
                                            dataTableNL.push({
                                                id: Math.random() + "",
                                                ...element,
                                                ngayId: dataNgay[i].ngayId,
                                                tenDichVu: dataNgay[i].dichVusResponses[j].tenDichVu,
                                                bgCTSaleId: dataNgay[i].dichVusResponses[j].bgCTSaleId,
                                                dmDichVuId: dataNgay[i].dichVusResponses[j].dmDichVuId,
                                                bgMapCTSaleHtId:
                                                    dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k]
                                                        .bgMapCTSaleHtId,
                                                nccThongTinChungId:
                                                    dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k]
                                                        .nccThongTinChungId,
                                                parentBgMapCTSaleHtId:
                                                    dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k]
                                                        .parentBgMapCTSaleHtId,
                                                tenNhaCungCap:
                                                    dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].tenNhaCungCap,
                                                dmGiaApDungId:
                                                    dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dmGiaApDungId,
                                                dmLoaiApDungId:
                                                    dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dmLoaiApDungId,
                                                dmVatId: dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dmVatId,
                                                moTa: dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].moTa,
                                                dieuKienFoc:
                                                    dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dieuKienFoc,
                                                foc: dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].foc,
                                                rowSpanDV: rowSpanDV,
                                                rowSpan: rowSpan,
                                                ...elementDv,
                                            });
                                        }
                                    } else {
                                        if (k === 0 && index === 0) {
                                            rowSpanDV = 2;
                                            rowSpan = 1;
                                        }
                                        dataTableNL.push({
                                            id: Math.random() + "",
                                            ...element,
                                            ngayId: dataNgay[i].ngayId,
                                            tenDichVu: dataNgay[i].dichVusResponses[j].tenDichVu,
                                            bgCTSaleId: dataNgay[i].dichVusResponses[j].bgCTSaleId,
                                            dmDichVuId: dataNgay[i].dichVusResponses[j].dmDichVuId,
                                            bgMapCTSaleHtId:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].bgMapCTSaleHtId,
                                            nccThongTinChungId:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].nccThongTinChungId,
                                            parentBgMapCTSaleHtId:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k]
                                                    .parentBgMapCTSaleHtId,
                                            tenNhaCungCap:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].tenNhaCungCap,
                                            dmGiaApDungId:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dmGiaApDungId,
                                            dmLoaiApDungId:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dmLoaiApDungId,
                                            dmVatId: dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dmVatId,
                                            moTa: dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].moTa,
                                            dieuKienFoc:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dieuKienFoc,
                                            foc: dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].foc,
                                            rowSpanDV: rowSpanDV,
                                            rowSpan: rowSpan,
                                        });
                                    }
                                } else {
                                    let bgNhomNguoiLons = [];
                                    if (k === 0 && index === 0) {
                                        rowSpanDV = dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon.length;
                                        rowSpanDV += 1;
                                    } else rowSpanDV = 0;
                                    if (dataNgay[i].dichVusResponses[j].tenDichVu === DV_XE_OTO) {
                                        for (
                                            let q = 0;
                                            q <
                                            dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dichVuNguoiLons.length;
                                            q++
                                        ) {
                                            const dtNL =
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dichVuNguoiLons[q];
                                            if (element.tranxBgChietTinhOtoId === dtNL.tranxBgChietTinhOtoId) {
                                                bgNhomNguoiLons.push(dtNL.bgNhomNguoiLonId);
                                            }
                                        }
                                    } else {
                                        debugger;
                                    }
                                    if (bgNhomNguoiLons.length >= 2) {
                                        rowSpanDV = rowSpanDV - bgNhomNguoiLons.length + 1;
                                    }
                                    if (
                                        bgNhomNguoiLons.length >= 2 &&
                                        dataTableNL.length > 0 &&
                                        dataTableNL.findIndex(
                                            (dt) => dt.tranxBgChietTinhOtoId === element.tranxBgChietTinhOtoId
                                        ) !== -1
                                    ) {
                                        let ind = dataTableNL.findIndex(
                                            (dt) => dt.tenDichVu === dataNgay[i].dichVusResponses[j].tenDichVu
                                        );
                                        dataTableNL[ind].rowSpanDV =
                                            dataTableNL[ind].rowSpanDV - bgNhomNguoiLons.length + 1;
                                    } else
                                        dataTableNL.push({
                                            id: Math.random() + "",
                                            ...element,
                                            ngayId: dataNgay[i].ngayId,
                                            tenDichVu: dataNgay[i].dichVusResponses[j].tenDichVu,
                                            bgCTSaleId: dataNgay[i].dichVusResponses[j].bgCTSaleId,
                                            dmDichVuId: dataNgay[i].dichVusResponses[j].dmDichVuId,
                                            bgMapCTSaleHtId:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].bgMapCTSaleHtId,
                                            nccThongTinChungId:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].nccThongTinChungId,
                                            parentBgMapCTSaleHtId:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k]
                                                    .parentBgMapCTSaleHtId,
                                            tenNhaCungCap:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].tenNhaCungCap,
                                            dmGiaApDungId:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dmGiaApDungId,
                                            dmLoaiApDungId:
                                                dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dmLoaiApDungId,
                                            dmVatId: dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].dmVatId,
                                            moTa: dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon[k].moTa,
                                            rowSpanDV: rowSpanDV,
                                            bgNhomNguoiLons: bgNhomNguoiLons,
                                            rowSpan: 1,
                                            giaDichVu: element.donGia,
                                        });
                                }
                            }
                        }

                        // mapping data tre em
                        const dataTblTE = processData(
                            dataNgay[i].dichVusResponses[j],
                            dataNgay[i].dichVusResponses[j].mapCTSaleTreEm,
                            dataNhomTreEm
                        );
                        dataTableTE = [...dataTableTE, ...dataTblTE];

                        let rowSpanDV = dataNgay[i].dichVusResponses[j].mapCTSaleNguoiLon.length === 0 ? 1 : 0;
                        if (
                            dataTableNL[dataTableNL.length - 1] &&
                            dataTableNL[dataTableNL.length - 1].rowSpanDV !== 0 &&
                            dataTableNL[dataTableNL.length - 1].tenDichVu === dataNgay[i].dichVusResponses[j].tenDichVu
                        ) {
                            rowSpanDV = 0;
                        }
                        dataTableNL.push({
                            id: "NULL" + Math.random(),
                            ngayId: dataNgay[i].ngayId,
                            tenDichVu: dataNgay[i].dichVusResponses[j].tenDichVu,
                            bgCTSaleId: dataNgay[i].dichVusResponses[j].bgCTSaleId,
                            dmDichVuId: dataNgay[i].dichVusResponses[j].dmDichVuId,
                            rowSpan: 1,
                            rowSpanDV: rowSpanDV,
                        });
                    }
                    newArr[i].id = "CT" + i;
                    debugger;
                    // newArr[i].dataTableNL = tinhToanThanhTable(dataTableNL);
                    newArr[i].dataTableNL = dataTableNL;
                    newArr[i].dataTableTE = dataTableTE;
                }
                setDataCT(newArr);
            } else {
                baoGiaService
                    .getHanhTrinhByTongQuanId({ baoGiaTongQuanId: baoGiaTongQuanId })
                    .then(async function (response) {
                        if (response.status === 200) {
                            let data = await response.data.data;
                            let newArr = [];
                            let dateArr = ngayKhoiHanh?.split("/");
                            data = data.sort((a, b) => (a.ngay > b.ngay ? 1 : -1));
                            if (dateArr) {
                                handleSetTuNgayAndDenNgay(dateArr, data.length);
                            }
                            for (let i = 0; i < data?.length; i++) {
                                let date = new Date(dateArr[2], dateArr[1], parseInt(dateArr[0]) + i);
                                let thu = date.getDay() % 6;
                                let thuVN = "";
                                switch (thu) {
                                    case 0:
                                        thuVN = "Thứ Hai";
                                        break;
                                    case 1:
                                        thuVN = "Thứ Ba";
                                        break;
                                    case 2:
                                        thuVN = "Thứ Tư";
                                        break;
                                    case 3:
                                        thuVN = "Thứ Năm";
                                        break;
                                    case 4:
                                        thuVN = "Thứ Sáu";
                                        break;
                                    case 5:
                                        thuVN = "Thứ Bảy";
                                        break;
                                    case 6:
                                        thuVN = "Chủ Nhật";
                                        break;
                                    default:
                                        break;
                                }
                                let dataTableNL = [];
                                let dataTableTE = [];

                                for (let index = 0; index < data[i].dmDichVuIds.length; index++) {
                                    const element = data[i].dmDichVuIds[index];
                                    let tenDichVu = dataDichVu.find((d) => d.id === element)?.value;
                                    // debugger;
                                    if (tenDichVu) {
                                        dataTableNL.push({
                                            dmDichVuId: element,
                                            id: "NEW_ADD" + Math.random(),
                                            tenDichVu: dataDichVu.find((d) => d.id === element)?.value,
                                        });
                                    }
                                }
                                newArr.push({
                                    id: Math.random() + "",
                                    ngay:
                                        "Ngày " +
                                        data[i].ngay +
                                        " (" +
                                        thuVN +
                                        ", " +
                                        date.toLocaleDateString("en-GB") +
                                        "): " +
                                        data[i].tenHanhTrinh,
                                    dataTableNL: dataTableNL,
                                });
                                newArr.push({
                                    id: Math.random() + "",
                                    ngay:
                                        "Ngày " +
                                        data[i].ngay +
                                        " (" +
                                        thuVN +
                                        ", " +
                                        date.toLocaleDateString("en-GB") +
                                        "): " +
                                        data[i].tenHanhTrinh,
                                    dataTableTE: dataTableTE,
                                });
                                setDataCT(newArr);
                            }
                        }
                    });
            }
        }
    }, [baoGiaTongQuanId, dataDichVu, dataNgay, isEffect, ngayKhoiHanh]);

    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result.toLocaleDateString("en-GB");
    };

    const handleSetTuNgayAndDenNgay = (dateArr, days) => {
        let date = dateArr[1] + "/" + dateArr[0] + "/" + dateArr[2];
        let denNgay = addDays(date, days);
        setTuNgay(ngayKhoiHanh);
        setDenNgay(denNgay);
    };

    // Hàm tiện ích để tính rowSpan
    const calculateRowSpan = (items) => {
        return items.reduce((total, item) => total + item.details.length, 0);
    };

    // Hàm tiện ích để tìm chỉ số đầu tiên có details.length > 0
    const findFirstIndexWithDetails = (items) => {
        return items.findIndex((item) => item.details.length > 0);
    };

    // Hàm chính để xử lý dữ liệu
    const processData = (dichVu, mapCTSaleTreEm, dataNhomTreEm) => {
        const dataTableTE = [];

        mapCTSaleTreEm.forEach((mapCTSale, k) => {
            if (!mapCTSale) return;

            const dichVuTreEms = mapCTSale.dichVuTreEms;
            let rowSpanLDV = mapCTSaleTreEm.reduce((num, item) => {
                item.dichVuTreEms.forEach((it) => it.details.forEach(() => num++));
                return num;
            }, 0);

            if (dataNhomTreEm?.length === 0) {
                rowSpanLDV += mapCTSaleTreEm.length * dichVuTreEms.length;
            }

            dichVuTreEms.forEach((dichVuTreEm, h) => {
                dichVuTreEm.details.forEach((detail, g) => {
                    const firtIndexLoaiDichVu = mapCTSaleTreEm.findIndex((dt) =>
                        dt.dichVuTreEms.some((it) => it.details.length > 0)
                    );
                    const firtIndexTenNcc = findFirstIndexWithDetails(dichVuTreEms);

                    let rowSpanDV = 0;
                    let rowSpanLoaiDV = 0;
                    let rowSpanNcc = 0;

                    if (dataNhomTreEm?.length > 0) {
                        if (k === firtIndexLoaiDichVu && h === firtIndexTenNcc && g === 0) {
                            rowSpanLoaiDV = rowSpanLDV;
                        }
                        if (h === firtIndexTenNcc && g === 0) {
                            rowSpanNcc = calculateRowSpan(dichVuTreEms);
                        }
                    } else {
                        if (k === 0 && h === 0 && g === 0) {
                            rowSpanLoaiDV = rowSpanLDV;
                        }
                        if (h === 0 && g === 0) {
                            rowSpanNcc = calculateRowSpan(dichVuTreEms) + dichVuTreEms.length;
                        }
                    }

                    if (g === 0) {
                        rowSpanDV = dichVuTreEm.details.length;
                        if (dataNhomTreEm?.length === 0) rowSpanDV++;
                    }

                    const commonData = {
                        loaiDichVu: dichVu.tenDichVu,
                        bgCTSaleId: dichVu.bgCTSaleId,
                        dmDichVuId: dichVu.dmDichVuId,
                        bgMapCTSaleHtId: mapCTSale.bgMapCTSaleHtId,
                        nccThongTinChungId: mapCTSale.nccThongTinChungId,
                        parentBgMapCTSaleHtId: mapCTSale.parentBgMapCTSaleHtId,
                        tenNhaCungCap: mapCTSale.tenNhaCungCap,
                        dmGiaApDungId: mapCTSale.dmGiaApDungId,
                        dmLoaiApDungId: mapCTSale.dmLoaiApDungId,
                        moTa: mapCTSale.moTa,
                        dieuKienFoc: mapCTSale.dieuKienFoc,
                        foc: mapCTSale.foc,
                        mapBgChietTinhKsTreEmId: dichVuTreEm.mapBgChietTinhKsTreEmId,
                        mapBgChietTinhNhTreEmId: dichVuTreEm.mapBgChietTinhNhTreEmId,
                        mapBgChietTinhOtoTreEmId: dichVuTreEm.mapBgChietTinhOtoTreEmId,
                        tenDichVu: dichVuTreEm.tenDichVu,
                        giaDichVu: dichVuTreEm.donGia,
                        bgNhomTreEmId: dichVuTreEm.bgNhomTreEmId,
                        tranxBgChietTinhKsId: dichVuTreEm.tranxBgChietTinhKsId,
                        tranxBgChietTinhNhId: dichVuTreEm.tranxBgChietTinhNhId,
                        tranxBgChietTinhOtoId: dichVuTreEm.tranxBgChietTinhOtoId,
                        rowSpanNcc: rowSpanNcc,
                        rowSpanLoaiDV: rowSpanLoaiDV,
                        rowSpanDV: rowSpanDV,
                    };

                    dataTableTE.push({
                        id: Math.random().toString(),
                        ...detail,
                        ...commonData,
                    });
                });

                if (dataNhomTreEm?.length === 0) {
                    dataTableTE.push({
                        id: "NEW_ADD" + Math.random().toString(),
                        loaiDichVu: dichVu.tenDichVu,
                        tenNhaCungCap: mapCTSale.tenNhaCungCap,
                        tenDichVu: dichVuTreEm.tenDichVu,
                        giaDichVu: dichVuTreEm.donGia,
                        bgCTSaleId: dichVu.bgCTSaleId,
                        dmDichVuId: dichVu.dmDichVuId,
                        mapBgChietTinhKsTreEmId: dichVuTreEm.mapBgChietTinhKsTreEmId,
                        mapBgChietTinhNhTreEmId: dichVuTreEm.mapBgChietTinhNhTreEmId,
                        mapBgChietTinhOtoTreEmId: dichVuTreEm.mapBgChietTinhOtoTreEmId,
                        tranxBgChietTinhKsId: dichVuTreEm.tranxBgChietTinhKsId,
                        tranxBgChietTinhNhId: dichVuTreEm.tranxBgChietTinhNhId,
                        tranxBgChietTinhOtoId: dichVuTreEm.tranxBgChietTinhOtoId,
                        rowSpanLoaiDV: dichVuTreEm.details.length === 0 && k === 0 && h === 0 ? rowSpanLDV : 0,
                        rowSpanNcc: dichVuTreEm.details.length === 0 ? 1 : 0,
                        rowSpanDV: dichVuTreEm.details.length === 0 ? 1 : 0,
                    });
                }
            });
        });

        return dataTableTE;
    };

    const getDmVat = () => {
        dm_gia_ap_dung
            .getDmVat()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmVat(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const getDmDoTuoi = () => {
        dm_gia_ap_dung
            .getDmDoTuoi()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setDmDoTuoi(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    };

    const [columns_date, setColumns_date] = useState([]);
    useEffect(() => {
        if (dataNhomNgLon && dataNhomNgLon.length > 0) {
            setColumns_date([
                {
                    title: "STT",
                },
                {
                    title: t("typeService"),
                },
                {
                    title: t("supplier"),
                },
                {
                    title: t("service"),
                },
                {
                    title: t("luaChon"),
                },
                {
                    title: t("hotel.roomClass.conditionFoc"),
                },
                {
                    title: t("tour.donGia"),
                },
                {
                    title: t("tour.vanChuyen.soLuong"),
                },
                {
                    title: t("hotel.roomClass.intoMoney"),
                },
                {
                    title: t("thuevat"),
                },
                {
                    title: t("adultGroup"),
                },
                {
                    title: t("action"),
                },
            ]);
        } else {
            setColumns_date([
                {
                    title: "STT",
                },
                {
                    title: t("typeService"),
                },
                {
                    title: t("supplier"),
                },
                {
                    title: t("service"),
                },
                {
                    title: t("luaChon"),
                },
                {
                    title: t("hotel.roomClass.conditionFoc"),
                },
                {
                    title: t("tour.donGia"),
                },
                {
                    title: t("tour.vanChuyen.soLuong"),
                },
                {
                    title: t("hotel.roomClass.intoMoney"),
                },
                {
                    title: t("thuevat"),
                },
                {
                    title: t("action"),
                },
            ]);
        }
    }, [dataNhomNgLon]);
    const columns_table_te = [
        {
            title: "STT",
            visible: true,
        },
        {
            title: t("typeService"),
            visible: true,
        },
        {
            title: t("supplier"),
            visible: true,
        },
        {
            title: t("service"),
            visible: true,
        },
        {
            title: t("childrenGroup"),
            visible: dataNhomTreEm.length > 0,
        },
        {
            title: t("age") + " " + t("children"),
            visible: true,
        },
        {
            title: t("tour.percentage"),
            visible: true,
        },
        {
            title: t("tour.donGia"),
            visible: true,
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            visible: true,
        },
        {
            title: t("hotel.roomClass.intoMoney"),
            visible: true,
        },
        {
            title: t("thuevat"),
            visible: true,
        },
        {
            title: t("action"),
            visible: true,
        },
    ];

    const handleCancelKS = () => {
        setOpenKs(false);
    };

    const handleCancelOto = () => {
        setOpenOto(false);
    };

    const handleCancelNh = () => {
        setOpenNh(false);
    };

    const handleAddServicesDv = (item, baoGiaHanhTrinhId, ngay) => {
        if (item.bgMapCTSaleHtId) setHanhDong("EDIT");
        else setHanhDong("ADD");
        debugger;
        setNgay(ngay);
        setBaoGiaHanhTrinhId(baoGiaHanhTrinhId);
        setBgChietTinhSaleId(item.bgCTSaleId);
        setDmDichVuId(item.dmDichVuId);
        setBgMapCTSaleHtId(item.bgMapCTSaleHtId ? item.bgMapCTSaleHtId : 0);
        switch (item.tenDichVu) {
            case DV_KS:
                return setOpenKs(true);
            case DV_XE_OTO:
                setOpenOto(true);
                break;
            case DV_NH:
                setOpenNh(true);
                break;
            default:
                return null;
        }
    };

    const handleDeleteServiceDv = (item) => {};

    const handleAddRowServices = (dataS, index, id, item) => {
        let dt = [];
        let crrFirstDV = dataS.findIndex((it) => it.rowSpanDV !== 0 && it.tenDichVu === item.tenDichVu);
        setEditingId(crrFirstDV + "" + id);
        let rowSpanDV = dataS[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataS.length; key++) {
            // debugger;
            const element = dataS[key];
            if (key === crrFirstDV) {
                dt.push({
                    id: Math.random() + "",
                    rowSpan: 1,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
                    rowSpanDV: rowSpanDV,
                    tenDichVu: item.tenDichVu,
                    bgCTSaleId: item.bgCTSaleId,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    tenNhaCungCap: "",
                    dieuKienFoc: 0,
                    foc: 0,
                    donGia: "",
                    giaDichVu: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    dichVu: "",
                    ngayId: dataS[crrFirstDV].ngayId,
                });
                element.rowSpanDV = 0;
            } else {
            }
            dt.push(element);
        }
        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = dt;
                }
                return ct;
            })
        );
    };
    const handleAddBySerivce = (dataTableNguoiLon, index, id, item) => {
        let dt = [];
        let crrFirst = dataTableNguoiLon.findIndex(
            (it) => it.rowSpan !== 0 && it.tenNhaCungCap === item.tenNhaCungCap && it.tenDichVu === item.tenDichVu
        );
        setEditingId(crrFirst + "" + id);
        let crrFirstDV = dataTableNguoiLon.findIndex((it) => it.rowSpan !== 0 && it.tenDichVu === item.tenDichVu);
        let rowSpan = dataTableNguoiLon[crrFirst].rowSpan + 1;
        let rowSpanDV = dataTableNguoiLon[crrFirstDV].rowSpanDV + 1;
        for (let key = 0; key < dataTableNguoiLon.length; key++) {
            const element = dataTableNguoiLon[key];
            if (key === crrFirst) {
                dt.push({
                    id: Math.random() + "NEW",
                    rowSpan: rowSpan,
                    tenDichVu: item.tenDichVu,
                    bgMapCTSaleHtId: item.bgMapCTSaleHtId,
                    nccThongTinChungId: item.nccThongTinChungId,
                    tenNhaCungCap: item.tenNhaCungCap,
                    bgCTSaleId: item.bgCTSaleId,
                    dieuKienFoc: item.dieuKienFoc,
                    foc: item.foc,
                    bgChietTinhOPId: item.bgChietTinhOPId,
                    rowSpanDV: 0,
                    parentBgMapCTSaleHtId: item.parentBgMapCTSaleHtId,
                    bgMapCTSaleHtIdDv: item.bgMapCTSaleHtIdDv,
                    donGia: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    dichVu: "",
                });
                element.rowSpanDV = 0;
                element.rowSpan = 0;
            }

            if (
                element.rowSpan !== 0 &&
                element.tenNhaCungCap === item.tenNhaCungCap &&
                element.tenDichVu === item.tenDichV &&
                key > crrFirst
            ) {
                element.rowSpanDV = 0;
                element.rowSpan = 0;
            }
            dt.push(element);
        }
        dt[crrFirstDV].rowSpanDV = rowSpanDV;
        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = dt;
                }
                return ct;
            })
        );
    };
    const handleChangeTenNCC = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = dataS.map((item, key) => {
            if (key === index) item.tenNhaCungCap = value;
            return item;
        });

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleClearNCC = (dataS, index, id) => {
        setEditingId(index + "" + id);
        let data = dataS.map((item, key) => {
            if (key === index) {
                return {
                    id: item.id,
                    rowSpan: item.rowSpan,
                    tenDichVu: item.tenDichVu,
                    parentBgMapCTSaleHtId: "",
                    bgChietTinhOPId: "",
                    bgMapCTSaleHtIdDv: "",
                    rowSpanDV: item.rowSpanDV,
                    bgCTSaleId: "",
                    bgMapCTSaleHtId: "",
                    soLuong: "",
                    thueVat: "",
                    thanhTien: "",
                    dichVu: "",
                    foc: 0,
                    tenNhaCungCap: "",
                    dieuKienFoc: 0,
                    ngayId: item.ngayId,
                    donGia: 0,
                    giaDichVu: 0,
                };
            }
            return item;
        });

        setDataCT([
            ...dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            }),
        ]);
    };

    const handleChangeDichVu = (dataS, index, value, id, tenDichVu) => {
        setEditingId(index + "" + id);
        let data = dataS.map((item, key) => {
            if (tenDichVu === DV_XE_OTO && key === index) item.soXe = value;
            if (!tenDichVu && key === index) item.dichVu = value;
            return item;
        });

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };

    const handleClear = () => {
        handleReload();
    };
    const handleDelete = (key, value) => {
        const services = {
            "Xe ô tô": otoService.deleteCTSaleDichVuOto,
            "Khách sạn": khachSanService.deleteCTSaleDichVuKs,
            "Nhà hàng": nhaHangService.deleteCTSaleNhaHang,
        };

        const serviceMethod = services[value.tenDichVu];

        if (serviceMethod) {
            const params = {
                "Xe ô tô": {
                    baoGiaTongQuanId: baoGiaTongQuanId,
                    bgCTSaleId: value.bgCTSaleId,
                    tranxBgChietTinhOtoId: value.tranxBgChietTinhOtoId,
                },
                "Khách sạn": {
                    baoGiaTongQuanId: baoGiaTongQuanId,
                    bgCTSaleId: value.bgCTSaleId,
                    tranxBgChietTinhKsId: value.tranxBgChietTinhKsId,
                },
                "Nhà hàng": {
                    baoGiaTongQuanId: baoGiaTongQuanId,
                    bgCTSaleId: value.bgCTSaleId,
                    bgMapCTSaleHtId: value.bgMapCTSaleHtId,
                },
            };
            console.log(JSON.stringify(params[value.tenDichVu]));

            serviceMethod(params[value.tenDichVu]).then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    handleReload();
                    api.success({
                        message: t("stour"),
                        description: `${t("delete")} ${t("noti.success").toLowerCase()}`,
                        duration: 0,
                    });
                } else {
                    api.error({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            });
        }
    };
    const tinhToanThanhTable = (data) => {
        // Create a map to store the calculated values for each service provider
        const calculatedValuesMap = new Map();

        // Loop through the data to calculate and store the values
        data.forEach((element) => {
            // Check if the ID contains "NULL"
            if (element.id.indexOf("NULL") === -1) {
                let thanhTien;
                // Check the service type
                switch (element.tenDichVu) {
                    case DV_NH:
                        // Calculate the value using the provided function
                        thanhTien = tinhThanhTienNH(
                            element.dieuKienFoc,
                            element.giaDichVu,
                            element.foc,
                            element.soLuong
                        );
                        break;
                    case DV_XE_OTO:
                        // Calculate the value directly
                        thanhTien = element.soLuong * element.giaDichVu;
                        break;
                    default:
                        let dataKS;
                        // Check if the value has already been calculated for the service provider
                        if (!calculatedValuesMap.has(element.tenNhaCungCap)) {
                            // If not calculated, filter the data for the same service provider and service type
                            dataKS = data.filter(
                                (it) => it.tenDichVu === element.tenDichVu && it.tenNhaCungCap === element.tenNhaCungCap
                            );
                            // Calculate the values using the provided function
                            const thanhTienKS = tinhThanhTienKS(
                                dataKS.map((it) => it.soLuong),
                                dataKS.map((it) => it.giaDichVu),
                                Math.floor(element.dieuKienFoc / element.foc)
                            );
                            // Store the calculated values in the map
                            calculatedValuesMap.set(element.tenNhaCungCap, thanhTienKS);
                        }
                        // Check if dataKS is defined before accessing its properties
                        if (dataKS) {
                            // Retrieve the calculated values from the map
                            thanhTien = calculatedValuesMap.get(element.tenNhaCungCap)[
                                dataKS.findIndex((it) => it.id === element.id)
                            ];
                        }
                        break;
                }
                // Assign the calculated value to the element
                element.thanhTien = thanhTien;
            }
        });
        return data;
    };
    const tinhThanhTienNH = (numberOfRates, unitPrice, FOCConditions, countOfService) => {
        // debugger;
        // Calculate FOC quantity
        const FOCQuantity = Math.floor(numberOfRates / FOCConditions);

        // Calculate total service cost
        const totalServiceCost = countOfService * unitPrice;

        // Calculate service cost after applying FOC policy
        return totalServiceCost - FOCQuantity ? FOCQuantity : 0 * unitPrice;
    };
    const tinhThanhTienKS = (numberOfRoomsBooked, unitPrices, FOCConditions) => {
        //FOCConditions dk foc
        //numberOfRoomsBooked: số lượng
        //unitPrices đơn giá
        // Define variables
        const tongSoLuongPhongNCC = numberOfRoomsBooked.reduce((acc, val) => acc + val, 0);
        // Calculate B5
        const lowestRoomCategoryPrice = Math.min(...unitPrices);
        const averageReducedPricePerRoom =
            (lowestRoomCategoryPrice * Math.floor(tongSoLuongPhongNCC / FOCConditions)) / tongSoLuongPhongNCC;

        // Calculate B6
        return numberOfRoomsBooked.map(
            (count, index) => count * unitPrices[index] - averageReducedPricePerRoom * count
        );
    };
    const handleSave = (dataTableNguoiLon, index, value, tenDichVu, ngay) => {
        let dichVus = [];
        let dichVuTreEms = [];
        let dataTableTreEm = dataCT.filter((dt) => dt.ngayId === ngay)[0].dataTableTE;
        let bgMapCTSaleHtId = value.id.indexOf("NEW") !== -1 ? value.id : value.bgMapCTSaleHtId;
        dataTableTreEm
            .filter((dt) => dt.bgMapCTSaleHtId === bgMapCTSaleHtId)
            .forEach((d) => {
                let idx = -1;
                dichVuTreEms.forEach((dv, index) => {
                    if (d.bgNhomTreEmId === dv.bgNhomTreEmId) {
                        idx = index;
                    }
                });
                if (idx === -1) {
                    dichVuTreEms.push({
                        bgNhomTreEmId: d.bgNhomTreEmId,
                        details: [
                            {
                                dmDoTuoiId: d.dmDoTuoiId,
                                phanTram: d.phanTram,
                                donGiaNhapTay: d.donGiaNhapTay,
                                soLuong: d.soLuong,
                                dmVatId: d.dmVatId,
                                apDung: value.id.indexOf("NEW") !== -1 || d.apDung,
                            },
                        ],
                    });
                } else {
                    dichVuTreEms[idx].details.push({
                        dmDoTuoiId: d.dmDoTuoiId,
                        phanTram: d.phanTram,
                        donGiaNhapTay: d.donGiaNhapTay,
                        soLuong: d.soLuong,
                        dmVatId: d.dmVatId,
                        apDung: value.id.indexOf("NEW") !== -1 || d.apDung,
                    });
                }
            });
        let giaDichVus = [];

        let dichVuData = {
            dmVatId: dmVat.find((d) => value.vat === d.vat)?.id,
            nccThongTinChungId: value.nccThongTinChungId,
            tenDichVu: value.dichVu,
            dmBuaAnId: value.dmBuaAnId,
            dmKieuPhongId: value.dmKieuPhongId,
            dmGiaApDungId: value.dmGiaApDungId,
            tranxGiaPhongId: 0,
            dmSoChoId: value.dmSoChoId,
            bgNhomNguoiLonId: value.bgNhomNguoiLonId,
            tranxNhaHangId: value.tranxNhaHangId || 0,
            nccDichVuNhaHangId: value.nccDichVuNhaHangId || 0,
            dichVuTreEms,
        };

        if (tenDichVu === DV_XE_OTO) {
            dichVuData = {
                ...dichVuData,
                giaOtoNhapTay: value.giaDichVu || 0,
                soLuong: value.soLuong || 0,
                bgdataNhomNgLon: value.bgdataNhomNgLon?.map((d) => ({ bgNhomNguoiLonId: d })) || [],
                soXe: value.soXe,
            };
        } else if (tenDichVu === DV_KS) {
            giaDichVus.push({
                dmKieuPhongId: value.dmKieuPhongId,
                maPhong: value.dichVu,
                soLuong: value.soLuong || 0,
                giaNhapTay: value.giaDichVu || 0,
            });
            for (let i = dataTableNguoiLon.length - 1; i >= 0; i--) {
                const elementV2 = dataTableNguoiLon[i];
                if (
                    value.bgMapCTSaleHtId === elementV2.bgMapCTSaleHtId &&
                    elementV2.tenDichVu === tenDichVu &&
                    elementV2.id.indexOf("NULL") === -1 &&
                    i !== index
                ) {
                    giaDichVus.push({
                        dmKieuPhongId: elementV2.dmKieuPhongId,
                        maPhong: elementV2.dichVu,
                        soLuong: elementV2.soLuong || 0,
                        giaNhapTay: elementV2.giaDichVu || 0,
                    });
                }
            }
            dichVuData = {
                ...dichVuData,
                giaNhaHangNhapTay: value.giaDichVu || 0,
                soLuong: value.soLuong || 0,
                dieuKienFoc: value.dieuKienFoc || 0,
                foc: value.foc || 0,
                giaDichVus: giaDichVus.length > 0 ? giaDichVus : null,
            };
        } else if (tenDichVu === DV_NH) {
            dichVuData = {
                ...dichVuData,
                giaNhaHangNhapTay: value.giaDichVu || 0,
                soLuong: value.soLuong || 0,
                dieuKienFoc: value.dieuKienFoc || 0,
                foc: value.foc || 0,
            };
        }

        dichVus.push(dichVuData);

        let data = {
            dmVatId: tenDichVu === DV_XE_OTO || tenDichVu === DV_NH ? dmVat.find((d) => value.vat === d.vat)?.id : 0,
            ngay: value.ngayId,
            baoGiaTongQuanId: baoGiaTongQuanId,
            bgMapCTSaleHtId: value.bgMapCTSaleHtId || 0,
            nccThongTinChungId: value.nccThongTinChungId || 0,
            bgCTSaleId: value.bgCTSaleId || 0,
            tenNcc: value.tenNhaCungCap,
            ngayApDungs: [value.ngayId],
            dmDichVuId: value.dmDichVuId,
            dmGiaApDungId: value.dmGiaApDungId,
            dieuKienFoc: value.dieuKienFoc,
            foc: value.foc,
            dichVus: dichVus,
        };

        console.log(JSON.stringify(data));

        const serviceMap = {
            "Khách sạn": khachSanService.saveCTSaleKhachSan,
            "Nhà hàng": nhaHangService.saveChietTinhSaleNhaHang,
            "Xe ô tô": otoService.saveCTSaleOto,
        };

        const serviceFunction = serviceMap[tenDichVu];

        if (serviceFunction) {
            serviceFunction(data).then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    handleReload();
                    api["success"]({
                        message: t("stour"),
                        description: value.bgMapCTSaleHtId
                            ? t("edit")
                            : t("add") + " " + t("noti.success").toLowerCase(),
                        duration: 0,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: 0,
                    });
                }
            });
        }
    };
    const handleReload = () => {
        setEditingId(null);
        setReload(true);
    };
    const handleChangeThanhTien = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = dataS.map((item, key) => {
            if (key === index) item.thanhTien = value;
            return item;
        });

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleChangeDmDichVuId = (dataS, index, value, id, tenDichVu) => {
        setEditingId(index + "" + id);
        debugger;
        let data = [
            ...dataS.map((item, key) => {
                if (key === index && tenDichVu === DV_KS) item.dmKieuPhongId = value;
                if (key === index && tenDichVu === DV_NH) item.dmBuaAnId = value;
                if (key === index && tenDichVu === DV_XE_OTO) item.dmSoChoId = value;
                return item;
            }),
        ];

        setDataCT([
            ...dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = [...data];
                }
                return ct;
            }),
        ]);
    };
    const handleChangeVat = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = dataS.map((item, key) => {
            if (key === index) item.vat = value;
            return item;
        });

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleChangeNhomNLOto = (dataS, index, event, id) => {
        const {
            target: { value },
        } = event;
        const newValue = typeof value === "string" ? value.split(",") : value; // Đảm bảo giá trị là mảng
        setEditingId(index + "" + id);
        // Cập nhật dữ liệu người lớn với giá trị mới
        const updatedDataNguoiLon = dataS.map((item, key) => {
            if (key === index) {
                return { ...item, bgdataNhomNgLon: newValue }; // Tạo bản sao để tránh thay đổi trực tiếp
            }
            return item;
        });

        // Lọc dữ liệu trẻ em liên quan dựa trên bgNhomNguoiLonId
        const relatedTE = dataNhomTreEm.filter((item) => newValue.includes(item.nhomNguoiLonId));

        // Lấy đối tượng người lớn đã cập nhật
        const updatedNguoiLonItem = updatedDataNguoiLon[index];
        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = updatedNguoiLonItem;
                    ct.dataTableTE = handleAddRowServicesTE(ct.dataTableTE, index, updatedNguoiLonItem, relatedTE);
                }
                return ct;
            })
        );
    };
    const handleChangeNhomNL = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let itemTE;
        let data = dataS.map((item, key) => {
            if (key === index) {
                item.bgNhomNguoiLonId = value;
                itemTE = item;
            }
            return item;
        });
        let relateTE = dataNhomTreEm.filter((item) => item.nhomNguoiLonId === value);
        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                    ct.dataTableTE = handleAddRowServicesTE(ct.dataTableTE, index, itemTE, relateTE);
                }
                return ct;
            })
        );
    };
    const handleAddRowServicesTE = (dataTableTreEm, index, item, relateTE) => {
        let bgMapCTSaleHtId = item.id.indexOf("NEW") !== -1 ? item.id : item.bgMapCTSaleHtId;
        var rowSpanLoaiDV = dataTableTreEm.filter((it) => it.loaiDichVu === item.tenDichVu).length;
        const dt = dataTableTreEm
            .filter((it) => it.bgMapCTSaleHtId !== bgMapCTSaleHtId)
            .map((it) => {
                if (it.loaiDichVu === item.tenDichVu) {
                    return { ...it, rowSpanLoaiDV: 0, rowSpanNcc: 0, rowSpanDV: 0 };
                }
                return it;
            });
        const rowTemplate = [];
        for (let i = 0; i < relateTE.length; i++) {
            rowSpanLoaiDV += relateTE[i].details.length;
        }
        for (let i = 0; i < relateTE.length; i++) {
            const element = relateTE[i];
            for (let y = 0; y < element.details.length; y++) {
                const elementDt = element.details[y];

                rowTemplate.push({
                    id: Math.random() + "NEW",
                    bgMapCTSaleHtId: bgMapCTSaleHtId,
                    rowSpanLoaiDV: i === 0 ? rowSpanLoaiDV : 0,
                    rowSpanNcc: i === 0 ? rowSpanLoaiDV : 0,
                    rowSpanDV: i === 0 ? rowSpanLoaiDV : 0,
                    rowSpanTenNhom: y === 0 ? element.details.length : 0,
                    loaiDichVu: item.tenDichVu,
                    tenNhaCungCap: item.tenNhaCungCap,
                    tenDichVu: item.dichVu,
                    giaDichVu: item.giaDichVu,
                    bgCTSaleId: item.bgCTSaleId,
                    dmDichVuId: item.dmDichVuId,
                    mapBgChietTinhKsTreEmId: item.mapBgChietTinhKsTreEmId,
                    mapBgChietTinhNhTreEmId: item.mapBgChietTinhNhTreEmId,
                    mapBgChietTinhOtoTreEmId: item.mapBgChietTinhOtoTreEmId,
                    tranxBgChietTinhKsId: item.tranxBgChietTinhKsId,
                    tranxBgChietTinhNhId: item.tranxBgChietTinhNhId,
                    tranxBgChietTinhOtoId: item.tranxBgChietTinhOtoId,
                    phanTram: 0,
                    soLuong: elementDt.soLuong,
                    dmDoTuoiId: elementDt.dmDoTuoiId,
                    dmVatId: 1,
                    tenNhomTreEm: element.tenNhom,
                    bgNhomTreEmId: element.id,
                    nhomNguoiLonId: elementDt.nhomNguoiLonId,
                });
            }
        }

        return renderRowSpanTE([...rowTemplate, ...dt]);
    };
    const renderRowSpanTE = (list) => {
        // Step 1: Sort the list by `loaiDichVu` in descending order
        const result = list.sort((a, b) => b.loaiDichVu.localeCompare(a.loaiDichVu));

        // Helper function to calculate row spans
        const calculateRowSpan = (list, groupByKeys, rowSpanKey) => {
            const rowSpanMap = new Map();

            // Group the items based on `groupByKeys`
            list.forEach((item) => {
                const groupKey = groupByKeys.map((key) => item[key]).join("|");
                if (!rowSpanMap.has(groupKey)) {
                    rowSpanMap.set(groupKey, []);
                }
                rowSpanMap.get(groupKey).push(item);
            });

            // Assign rowSpan value to the first item in each group
            rowSpanMap.forEach((groupItems) => {
                groupItems[0][rowSpanKey] = groupItems.length;
                for (let i = 1; i < groupItems.length; i++) {
                    groupItems[i][rowSpanKey] = 0; // Set others to 0
                }
            });
        };

        // Step 2: Calculate rowSpan values for each level
        calculateRowSpan(list, ["loaiDichVu"], "rowSpanLoaiDV");
        calculateRowSpan(list, ["loaiDichVu", "tenNhaCungCap"], "rowSpanNcc");
        calculateRowSpan(list, ["loaiDichVu", "tenNhaCungCap", "tenDichVu"], "rowSpanDV");
        calculateRowSpan(list, ["loaiDichVu", "tenNhaCungCap", "tenDichVu", "tenNhomTreEm"], "rowSpanTenNhom");

        return result;
    };
    const handleChangeSoLuong = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = tinhToanThanhTable(
            dataS.map((item, key) => {
                if (key === index) {
                    item.soLuong = value;
                }
                return item;
            })
        );

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleChangeDonGia = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = tinhToanThanhTable(
            dataS.map((item, key) => {
                if (key === index) {
                    item.giaDichVu = value;
                }
                return item;
            })
        );

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleChangeFoc = (dataS, index, value, id) => {
        setEditingId(index + "" + id);
        let data = tinhToanThanhTable(
            dataS.map((item, key) => {
                if (key === index) item.foc = value;
                return item;
            })
        );

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleChangeDieuKienFoc = (dataS, index, value, id, tenDichVu) => {
        setEditingId(index + "" + id);
        let data = tinhToanThanhTable(
            dataS.map((item, key) => {
                if (key === index) item.dieuKienFoc = value;
                return item;
            })
        );

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableNL = data;
                }
                return ct;
            })
        );
    };
    const handleAddAge = (dataTE, index, id, row) => {
        let indexAdd = dataTE.findIndex((it) => it.id === row.id);
        let dataRowAdd = { ...dataTE[indexAdd] };
        dataTE[indexAdd].rowSpanLoaiDV = 0;
        dataTE[indexAdd].rowSpanNcc = 0;
        dataTE[indexAdd].rowSpanDV = 0;

        dataTE.splice(indexAdd, 0, {
            id: Math.random() + "",
            rowSpanLoaiDV: 0,
            rowSpanNcc: 0,
            rowSpanDV: 0,
            loaiDichVu: row.loaiDichVu,
            tenNhaCungCap: row.tenNhaCungCap,
            tenDichVu: row.tenDichVu,
            giaDichVu: row.giaDichVu,
            bgCTSaleId: row.bgCTSaleId,
            dmDichVuId: row.dmDichVuId,
            mapBgChietTinhKsTreEmId: row.mapBgChietTinhKsTreEmId,
            mapBgChietTinhNhTreEmId: row.mapBgChietTinhNhTreEmId,
            mapBgChietTinhOtoTreEmId: row.mapBgChietTinhOtoTreEmId,
            tranxBgChietTinhKsId: row.tranxBgChietTinhKsId,
            tranxBgChietTinhNhId: row.tranxBgChietTinhNhId,
            tranxBgChietTinhOtoId: row.tranxBgChietTinhOtoId,
            phanTram: 0,
            soLuong: 0,
            dmDoTuoiId: 1,
            dmVatId: 1,
        });

        let crrFirstLoaiDichVu = dataTE.findIndex((it) => it.loaiDichVu === row.loaiDichVu);
        let crrFirstNcc = dataTE.findIndex(
            (it) => it.rowSpanNcc !== 0 && it.loaiDichVu === row.loaiDichVu && it.tenNhaCungCap === row.tenNhaCungCap
        );
        let crrFirstDV = dataTE.findIndex(
            (it) =>
                it.rowSpanDV !== 0 &&
                it.loaiDichVu === row.loaiDichVu &&
                it.tenNhaCungCap === row.tenNhaCungCap &&
                it.tenDichVu === row.tenDichVu
        );
        if (crrFirstLoaiDichVu !== -1) {
            dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV += dataRowAdd.rowSpanLoaiDV + 1;
            // dataTE[crrFirstLoaiDichVu].stt = dataRowAdd.stt;
        }
        if (crrFirstNcc !== -1) {
            dataTE[crrFirstNcc].rowSpanNcc += 1;
        } else {
            dataTE[indexAdd].rowSpanNcc += dataRowAdd.rowSpanNcc + 1;
            // dataTE[indexAdd].rowSpanNcc += 2;
        }
        if (crrFirstDV !== -1) {
            dataTE[crrFirstDV].rowSpanDV += 1;
        } else {
            dataTE[indexAdd].rowSpanDV += 2;
        }

        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableTE = dataTE;
                }
                return ct;
            })
        );
    };

    const handleSaveCTDoTuoi = async (row) => {
        debugger;
        let action = "edit";
        let dataReq = {
            baoGiaTongQuanId,
            bgCTSaleId: row.bgCTSaleId,
            dmDoTuoiId: row.dmDoTuoiId,
            phanTram: row.phanTram,
            soLuong: row.soLuong,
            dmVatId: row.dmVatId,
            apDung: true,
        };
        let response = { status: 500 };
        if (row.loaiDichVu === DV_KS) {
            if (row.tranxBgCtKsSaleTreEmId) dataReq.tranxBgCtKsSaleTreEmId = row.tranxBgCtKsSaleTreEmId;
            else {
                dataReq.tranxBgCtKsSaleTreEmId = 0;
                action = "add";
            }
            dataReq.mapBgChietTinhKsTreEmId = row.mapBgChietTinhKsTreEmId;
            dataReq.tranxBgChietTinhKsId = row.tranxBgChietTinhKsId;
            response = await khachSanService.saveChietTinhDoTuoi(dataReq);
        } else if (row.loaiDichVu === DV_NH) {
            if (row.tranxBgCtNhSaleTreEmId) dataReq.tranxBgCtNhSaleTreEmId = row.tranxBgCtNhSaleTreEmId;
            else {
                dataReq.tranxBgCtNhSaleTreEmId = 0;
                action = "add";
            }
            dataReq.mapBgCtNhSaleTreEmId = row.mapBgChietTinhNhTreEmId;
            dataReq.tranxBgChietTinhNhId = row.tranxBgChietTinhNhId;
            response = await nhaHangService.saveChietTinhDoTuoi(dataReq);
        } else {
            if (row.tranxBgCtNhSaleTreEmId) dataReq.tranxBgCtOtoSaleTreEmId = row.tranxBgCtOtoSaleTreEmId;
            else {
                dataReq.tranxBgCtOtoSaleTreEmId = 0;
                action = "add";
            }
            dataReq.mapBgCtOtoSaleTreEmId = row.mapBgChietTinhOtoTreEmId;
            dataReq.tranxBgChietTinhOtoId = row.tranxBgChietTinhOtoId;
            dataReq.giaNguoiLon = row.giaDichVu;
            response = await otoService.saveChietTinhDoTuoi(dataReq);
        }
        if (response.status === 200 && response.data.code === "00") {
            handleReload();
            showNoti("success", t(action) + " " + t("noti.success").toLowerCase());
        } else {
            showNoti("error", t("add") + " " + t("noti.error").toLowerCase());
        }
    };

    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: 0,
        });
    };

    const handleDeleteAge = async (dataTE, id, row) => {
        // debugger;
        let isDeleted = false;
        let res = { status: 500 };
        if (row.loaiDichVu === DV_KS && row.tranxBgCtKsSaleTreEmId) {
            res = await khachSanService.deleteChietTinhDoTuoi({
                baoGiaTongQuanId,
                bgCTSaleId: row.bgCTSaleId,
                tranxBgCtKsSaleTreEmId: row.tranxBgCtKsSaleTreEmId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else if (row.loaiDichVu === DV_NH && row.tranxBgCtNhSaleTreEmId) {
            res = await nhaHangService.deleteChietTinhDoTuoi({
                baoGiaTongQuanId,
                bgCTSaleId: row.bgCTSaleId,
                tranxBgCtNhSaleTreEmId: row.tranxBgCtNhSaleTreEmId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else if (row.loaiDichVu === DV_XE_OTO && row.tranxBgCtOtoSaleTreEmId) {
            res = await otoService.deleteChietTinhDoTuoi({
                baoGiaTongQuanId,
                bgCTSaleId: row.bgCTSaleId,
                tranxBgCtOtoSaleTreEmId: row.tranxBgChietTinhOtoId,
            });
            handleShowNotiDeleteTE(res);
            handleReload();
        } else {
            isDeleted = true;
            showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
        }
        if (isDeleted) {
            let indexDeleted = dataTE.findIndex((it) => it.id === row.id);
            let dataDeleted = dataTE[indexDeleted];
            dataTE.splice(indexDeleted, 1);
            let crrFirstLoaiDichVu = dataTE.findIndex(
                (it) => it.rowSpanLoaiDV !== 0 && it.loaiDichVu === row.loaiDichVu
            );
            let crrFirstNcc = dataTE.findIndex(
                (it) =>
                    it.rowSpanNcc !== 0 && it.loaiDichVu === row.loaiDichVu && it.tenNhaCungCap === row.tenNhaCungCap
            );
            let crrFirstDV = dataTE.findIndex(
                (it) =>
                    it.rowSpanDV !== 0 &&
                    it.loaiDichVu === row.loaiDichVu &&
                    it.tenNhaCungCap === row.tenNhaCungCap &&
                    it.tenDichVu === row.tenDichVu
            );
            if (crrFirstLoaiDichVu !== -1) {
                dataTE[crrFirstLoaiDichVu].rowSpanLoaiDV -= 1;
            } else {
                dataTE[indexDeleted].rowSpanLoaiDV = dataDeleted.rowSpanLoaiDV - 1;
                dataTE[indexDeleted].stt = dataDeleted.stt;
            }
            if (crrFirstNcc !== -1) {
                dataTE[crrFirstNcc].rowSpanNcc -= 1;
            } else {
                if (dataDeleted.rowSpanNcc !== 0) dataTE[indexDeleted].rowSpanNcc = dataDeleted.rowSpanNcc - 1;
                else dataTE[indexDeleted].rowSpanNcc = 1;
            }
            if (crrFirstDV !== -1) {
                dataTE[crrFirstDV].rowSpanDV -= 1;
            } else {
                if (dataDeleted.rowSpanDV !== 0) dataTE[indexDeleted].rowSpanDV = dataDeleted.rowSpanDV - 1;
                else dataTE[indexDeleted].rowSpanDV = 1;
            }

            setDataCT(
                dataCT.map((ct) => {
                    if (ct.id === id) {
                        ct.dataTableTE = dataTE;
                    }
                    return ct;
                })
            );
        }
    };

    const handleShowNotiDeleteTE = (res) => {
        if (res.status === 200 && res.data.code === "00") {
            showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
            return true;
        } else {
            showNoti("error", t("delete") + " " + t("noti.error").toLowerCase());
            return false;
        }
    };

    const handleChangItemTE = (dataTE, id, row, e) => {
        let indexChange = dataTE.findIndex((it) => it.id === row.id);
        let name = e.target.name;
        let value = e.target.value;
        dataTE[indexChange][name] = value;
        if (name !== "dmDoTuoiId") {
            dataTE[indexChange].thanhTien =
                (dataTE[indexChange].phanTram / 100) * dataTE[indexChange].soLuong * dataTE[indexChange].giaDichVu;
        }
        setDataCT(
            dataCT.map((ct) => {
                if (ct.id === id) {
                    ct.dataTableTE = dataTE;
                }
                return ct;
            })
        );
    };

    const children = (dataS, id, baoGiaHanhTrinhId, ngay) => {
        return (
            <>
                <table>
                    <thead>
                        {columns_date.map((cl) => {
                            return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                        })}
                    </thead>
                    <tbody>
                        {dataS?.map((item, key) => {
                            return (
                                <tr key={key}>
                                    <td>{key + 1}</td>
                                    {item.rowSpanDV && item.rowSpanDV !== 0 ? (
                                        <td rowSpan={item.rowSpanDV}>{item.tenDichVu}</td>
                                    ) : (
                                        <></>
                                    )}
                                    {item.id.indexOf("NULL") !== -1 ? (
                                        <>
                                            <>
                                                <td colSpan="100%">
                                                    <Link
                                                        onClick={() => handleAddRowServices(dataS, key, id, item)}
                                                        disabled={
                                                            editingId === null ? false : editingId !== key + "" + id
                                                        }
                                                    >
                                                        {t("add") + " " + t("service").toLowerCase()}
                                                    </Link>
                                                </td>
                                            </>
                                        </>
                                    ) : (
                                        <>
                                            {item.rowSpan && item.rowSpan !== 0 ? (
                                                <td rowSpan={item.rowSpan}>
                                                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                        <TextField
                                                            disabled={
                                                                item.nccThongTinChungId
                                                                    ? true
                                                                    : editingId === null
                                                                    ? false
                                                                    : editingId !== key + "" + id
                                                            }
                                                            size="small"
                                                            value={item.tenNhaCungCap}
                                                            onChange={(e) =>
                                                                handleChangeTenNCC(dataS, key, e.target.value, id)
                                                            }
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton size="small">
                                                                            <EditFilled
                                                                                onClick={() =>
                                                                                    handleAddServicesDv(
                                                                                        item,
                                                                                        baoGiaHanhTrinhId,
                                                                                        ngay
                                                                                    )
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            size="small"
                                                                            disabled={
                                                                                !item.nccThongTinChungId ? true : false
                                                                            }
                                                                            onClick={() =>
                                                                                handleClearNCC(dataS, key, id)
                                                                            }
                                                                        >
                                                                            <CloseCircleFilled />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        {item.tenDichVu === DV_KS ? (
                                                            <IconButton
                                                                onClick={() => handleAddBySerivce(dataS, key, id, item)}
                                                            >
                                                                <PlusSquareFilled />
                                                            </IconButton>
                                                        ) : null}
                                                    </Box>
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <TextField
                                                    disabled={
                                                        item.nccThongTinChungId
                                                            ? true
                                                            : editingId === null
                                                            ? false
                                                            : editingId !== key + "" + id
                                                    }
                                                    size="small"
                                                    value={item.dichVu}
                                                    onChange={(e) => handleChangeDichVu(dataS, key, e.target.value, id)}
                                                />
                                            </td>
                                            <td>
                                                {item.tenDichVu === DV_KS ? (
                                                    <TextField
                                                        disabled={
                                                            item.nccThongTinChungId
                                                                ? true
                                                                : editingId === null
                                                                ? false
                                                                : editingId !== key + "" + id
                                                        }
                                                        size="small"
                                                        select
                                                        value={item.dmKieuPhongId}
                                                        onChange={(e) =>
                                                            handleChangeDmDichVuId(
                                                                dataS,
                                                                key,
                                                                e.target.value,
                                                                id,
                                                                item.tenDichVu
                                                            )
                                                        }
                                                    >
                                                        {kieuPhong.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                ) : item.tenDichVu === DV_NH ? (
                                                    <TextField
                                                        disabled={
                                                            item.nccThongTinChungId
                                                                ? true
                                                                : editingId === null
                                                                ? false
                                                                : editingId !== key + "" + id
                                                        }
                                                        size="small"
                                                        select
                                                        value={item.dmBuaAnId}
                                                        onChange={(e) =>
                                                            handleChangeDmDichVuId(
                                                                dataS,
                                                                key,
                                                                e.target.value,
                                                                id,
                                                                item.tenDichVu
                                                            )
                                                        }
                                                    >
                                                        {dmBuaAn.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                ) : (
                                                    <>
                                                        <TextField
                                                            disabled={
                                                                editingId === null ? false : editingId !== key + "" + id
                                                            }
                                                            placeholder="Nhập số xe"
                                                            size="small"
                                                            value={item.soXe}
                                                            onChange={(e) =>
                                                                handleChangeDichVu(
                                                                    dataS,
                                                                    key,
                                                                    e.target.value,
                                                                    id,
                                                                    item.tenDichVu
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </td>
                                            {item.rowSpan && item.rowSpan !== 0 ? (
                                                <td rowSpan={item.rowSpan}>
                                                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                        {item.tenDichVu === DV_XE_OTO ? (
                                                            <>
                                                                <TextField size="small" disabled />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <TextField
                                                                    disabled={
                                                                        item.nccThongTinChungId
                                                                            ? true
                                                                            : editingId === null
                                                                            ? false
                                                                            : editingId !== key + "" + id
                                                                    }
                                                                    size="small"
                                                                    onChange={(e) =>
                                                                        handleChangeDieuKienFoc(
                                                                            dataS,
                                                                            key,
                                                                            e.target.value,
                                                                            id,
                                                                            item.tenDichVu
                                                                        )
                                                                    }
                                                                    value={item.dieuKienFoc}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                {item.tenDichVu === DV_KS
                                                                                    ? t("room")
                                                                                    : t("set")}
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                                <TextField
                                                                    disabled={
                                                                        item.nccThongTinChungId
                                                                            ? true
                                                                            : editingId === null
                                                                            ? false
                                                                            : editingId !== key + "" + id
                                                                    }
                                                                    size="small"
                                                                    value={item.foc}
                                                                    onChange={(e) =>
                                                                        handleChangeFoc(
                                                                            dataS,
                                                                            key,
                                                                            e.target.value,
                                                                            id,
                                                                            item.tenDichVu
                                                                        )
                                                                    }
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                {t("hotel.roomClass.foc")}
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </Box>
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled={
                                                            item.nccThongTinChungId
                                                                ? true
                                                                : editingId === null
                                                                ? false
                                                                : editingId !== key + "" + id
                                                        }
                                                        size="small"
                                                        type="number"
                                                        value={item.giaDichVu}
                                                        onChange={(e) =>
                                                            handleChangeDonGia(dataS, key, e.target.value, id)
                                                        }
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled={
                                                            item.nccThongTinChungId
                                                                ? true
                                                                : editingId === null
                                                                ? false
                                                                : editingId !== key + "" + id
                                                        }
                                                        size="small"
                                                        onChange={(e) =>
                                                            handleChangeSoLuong(dataS, key, e.target.value, id)
                                                        }
                                                        value={item.soLuong}
                                                        type="number"
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        disabled
                                                        size="small"
                                                        value={item.thanhTien}
                                                        // onChange={(e) =>
                                                        //     handleChangeThanhTien(dataS, key, e.target.value, id)
                                                        // }
                                                    />
                                                </Box>
                                            </td>
                                            <td>
                                                <TextField
                                                    disabled={
                                                        item.nccThongTinChungId
                                                            ? true
                                                            : editingId === null
                                                            ? false
                                                            : editingId !== key + "" + id
                                                    }
                                                    size="small"
                                                    select
                                                    value={item.vat}
                                                    onChange={(e) => handleChangeVat(dataS, key, e.target.value, id)}
                                                >
                                                    {dmVat.map((option) => (
                                                        <MenuItem key={option.vat} value={option.vat}>
                                                            {option.vat_str}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </td>
                                            {dataNhomNgLon && dataNhomNgLon.length > 0 ? (
                                                <>
                                                    {item.tenDichVu === DV_XE_OTO ? (
                                                        <td>
                                                            <Select
                                                                disabled={
                                                                    item.nccThongTinChungId
                                                                        ? true
                                                                        : editingId === null
                                                                        ? false
                                                                        : editingId !== key + "" + id
                                                                }
                                                                size="small"
                                                                multiple
                                                                value={item.bgNhomNguoiLons ? item.bgNhomNguoiLons : []}
                                                                onChange={(e) =>
                                                                    handleChangeNhomNLOto(dataS, key, e, id)
                                                                }
                                                            >
                                                                {dataNhomNgLon.map((option) => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.tenNhom}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </td>
                                                    ) : (
                                                        <td>
                                                            <TextField
                                                                disabled={
                                                                    item.nccThongTinChungId
                                                                        ? true
                                                                        : editingId === null
                                                                        ? false
                                                                        : editingId !== key + "" + id
                                                                }
                                                                size="small"
                                                                select
                                                                value={item.bgNhomNguoiLonId}
                                                                onChange={(e) =>
                                                                    handleChangeNhomNL(dataS, key, e.target.value, id)
                                                                }
                                                            >
                                                                {dataNhomNgLon.map((option) => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.tenNhom}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </td>
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <IconButton
                                                        disabled={
                                                            item.nccThongTinChungId && editingId !== key + "" + id
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={() =>
                                                            handleSave(dataS, key, item, item.tenDichVu, ngay)
                                                        }
                                                    >
                                                        <CheckOutlined />
                                                    </IconButton>
                                                    {editingId !== key + "" + id ? (
                                                        <IconButton
                                                            onClick={() => handleDelete(key, item)}
                                                            disabled={
                                                                editingId === null ? false : editingId !== key + "" + id
                                                            }
                                                        >
                                                            <DeleteOutlined />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton onClick={() => handleClear()}>
                                                            <CloseOutlined />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    };

    const childrenTE = (dataTE, id, baoGiaHanhTrinhId, ngay) => {
        let stt = 1;
        return (
            <>
                <table>
                    <thead>
                        {columns_table_te
                            .filter((it) => it.visible)
                            .map((cl) => {
                                return <th style={{ fontWeight: "normal" }}>{cl.title}</th>;
                            })}
                    </thead>
                    <tbody>
                        {dataTE?.map((it, key) => {
                            return (
                                <tr key={it.id}>
                                    {it.rowSpanLoaiDV !== 0 ? (
                                        <>
                                            <td rowSpan={it.rowSpanLoaiDV}>{stt++}</td>
                                            <td rowSpan={it.rowSpanLoaiDV}>{it.loaiDichVu}</td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {it.rowSpanNcc !== 0 ? <td rowSpan={it.rowSpanNcc}>{it.tenNhaCungCap}</td> : null}
                                    {it.rowSpanDV !== 0 ? <td rowSpan={it.rowSpanDV}>{it.tenDichVu}</td> : null}
                                    {it.id.indexOf("NEW_ADD") !== -1 ? (
                                        <>
                                            <td colSpan="100%">
                                                <Link onClick={() => handleAddAge(dataTE, key, id, it)}>
                                                    {t("add") + " " + t("age").toLowerCase()}
                                                </Link>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            {it.rowSpanTenNhom !== 0 ? (
                                                <td rowSpan={it.rowSpanTenNhom}>{it.tenNhomTreEm}</td>
                                            ) : null}
                                            <td width={"10%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={it.dmDoTuoiId}
                                                        size="small"
                                                        name="dmDoTuoiId"
                                                        select
                                                        disabled={
                                                            dataNhomTreEm.length > 0 || it.nccThongTinChungId
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) => handleChangItemTE(dataTE, id, it, e)}
                                                    >
                                                        {dmDoTuoi.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.do_tuoi}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                            </td>
                                            <td width={"8%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        type="number"
                                                        disabled={it.nccThongTinChungId ? true : false}
                                                        value={it.phanTram}
                                                        size="small"
                                                        name="phanTram"
                                                        onChange={(e) => handleChangItemTE(dataTE, id, it, e)}
                                                        InputProps={{
                                                            endAdornment: <>%</>,
                                                        }}
                                                    />
                                                </Box>
                                            </td>
                                            <td width={"10%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        value={it.giaDichVu}
                                                        size="small"
                                                        name="giaDichVu"
                                                        disabled={true}
                                                    ></TextField>
                                                </Box>
                                            </td>
                                            <td width={"8%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        type="number"
                                                        value={it.soLuong}
                                                        name="soLuong"
                                                        size="small"
                                                        onChange={(e) => handleChangItemTE(dataTE, id, it, e)}
                                                        disabled={it.nccThongTinChungId ? true : false}
                                                    />
                                                </Box>
                                            </td>
                                            <td width={"10%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        value={it.giaDichVu * it.soLuong * (it.phanTram / 100)}
                                                        size="small"
                                                        disabled={true}
                                                    />
                                                </Box>
                                            </td>
                                            <td width={"9%"}>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        value={it.dmVatId}
                                                        size="small"
                                                        name="dmVatId"
                                                        select
                                                        disabled={it.nccThongTinChungId ? true : false}
                                                        onChange={(e) => handleChangItemTE(dataTE, id, it, e)}
                                                    >
                                                        {dmVat.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.vat_str}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Box>
                                            </td>
                                            <td width={"9%"}>
                                                <Row>
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <IconButton>
                                                            <CheckIcon onClick={() => handleSaveCTDoTuoi(it)} />
                                                        </IconButton>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <IconButton>
                                                            <Delete onClick={() => handleDeleteAge(dataTE, id, it)} />
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    };

    const style = {
        h4: { fontSize: "16px", fontWeight: "bold" },
    };
    const table_html = () => {
        return (
            <>
                {dataCT.map((item) => {
                    return (
                        <Collapse
                            defaultActiveKey={item.baoGiaHanhTrinhId}
                            items={[
                                {
                                    key: "1",
                                    label: <div style={style.h4}>{item.ngay}</div>,
                                    children: children(item.dataTableNL, item.id, item.baoGiaHanhTrinhId, item.ngayId),
                                },
                            ]}
                        />
                    );
                })}
            </>
        );
    };

    const table_html_te = () => {
        return (
            <>
                {dataCT.map((item) => {
                    return (
                        <Collapse
                            defaultActiveKey={item.baoGiaHanhTrinhId}
                            items={[
                                {
                                    key: "1",
                                    label: <div style={style.h4}>{item.ngay}</div>,
                                    children: childrenTE(
                                        item.dataTableTE,
                                        item.id,
                                        item.baoGiaHanhTrinhId,
                                        item.ngayId
                                    ),
                                },
                            ]}
                        />
                    );
                })}
            </>
        );
    };

    const items = [
        {
            key: "1",
            label: (
                <Row>
                    <Col span={20}>
                        <div
                            style={{
                                textTransform: "none",
                                fontSize: "13px",
                                fontWeight: "bold",
                            }}
                        >
                            {t("adultService")}
                        </div>
                    </Col>
                </Row>
            ),
            children: <>{table_html()}</>,
        },
        {
            key: "2",
            label: (
                <Row>
                    <Col span={20}>
                        <div
                            style={{
                                textTransform: "none",
                                fontSize: "13px",
                                fontWeight: "bold",
                            }}
                        >
                            {t("childrenService")}
                        </div>
                    </Col>
                </Row>
            ),
            children: <>{table_html_te()}</>,
        },
    ];
    const childrenDichVuCungCap = (
        <>
            <Row>
                <Col span={24}>
                    <Collapse defaultActiveKey={"1"} items={items} />
                </Col>
            </Row>
        </>
    );
    const cauHinh = [
        {
            key: "1",
            label: (
                <div
                    style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        fontWeight: "bold",
                    }}
                >
                    {t("serviceProvided")}
                </div>
            ),
            children: childrenDichVuCungCap,
        },
        {
            key: "2",
            label: (
                <div
                    style={{
                        textTransform: "uppercase",
                        fontSize: "13px",
                        fontWeight: "bold",
                    }}
                >
                    {t("totalPrice")}
                </div>
            ),
            children: (
                <>
                    <TongKet baoGiaTongQuanId={baoGiaTongQuanId} tongGia={tongGia} setReload={setReload} />
                </>
            ),
        },
    ];
    return (
        <>
            {contextHolder}
            <Collapse defaultActiveKey={"1"} items={cauHinh} />
            <ModalCapNhatKS
                isOpen={openKs}
                onReset={handleReload}
                handleCancel={handleCancelKS}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                baoGiaHanhTrinhId={baoGiaHanhTrinhId}
                baoGiaTongQuanId={baoGiaTongQuanId}
                bgChietTinhSaleId={bgChietTinhSaleId}
                dmDichVuId={dmDichVuId}
                bgMapCTSaleHtId={bgMapCTSaleHtId}
                soLuongTreEmBG={soLuongTreEmBG}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={ngay}
                bgHanhTrinhs={bgHanhTrinhs}
                tuNgay={tuNgay}
                denNgay={denNgay}
            />
            <ModalCapNhatOto
                isOpen={openOto}
                onReset={handleReload}
                handleCancel={handleCancelOto}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                baoGiaHanhTrinhId={baoGiaHanhTrinhId}
                baoGiaTongQuanId={baoGiaTongQuanId}
                bgChietTinhSaleId={bgChietTinhSaleId}
                dmDichVuId={dmDichVuId}
                bgMapCTSaleHtId={bgMapCTSaleHtId}
                soLuongTreEmBG={soLuongTreEmBG}
                soLuongNgLonBG={soLuongNgLonBG}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={ngay}
                bgHanhTrinhs={bgHanhTrinhs}
                tuNgay={tuNgay}
                denNgay={denNgay}
            />
            <ModalCapNhatNhaHang
                isOpen={openNH}
                onReset={handleReload}
                handleCancel={handleCancelNh}
                dataNhomNgLon={dataNhomNgLon}
                dataNhomTreEm={dataNhomTreEm}
                baoGiaHanhTrinhId={baoGiaHanhTrinhId}
                baoGiaTongQuanId={baoGiaTongQuanId}
                bgChietTinhSaleId={bgChietTinhSaleId}
                dmDichVuId={dmDichVuId}
                bgMapCTSaleHtId={bgMapCTSaleHtId}
                soLuongTreEmBG={soLuongTreEmBG}
                hanhDong={hanhDong}
                soNgay={soNgay}
                ngay={ngay}
                bgHanhTrinhs={bgHanhTrinhs}
                tuNgay={tuNgay}
                denNgay={denNgay}
            />
        </>
    );
};
export default ViewDates;
