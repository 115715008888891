import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {useTranslation} from "react-i18next";
import {Button, Image, Tag, notification, Typography, Col} from "antd";
import TourService from "../../../../../api/tour/tour.service";
import {useNavigate} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import MuiLink from "@mui/material/Link";
import {FileImageFilled} from "@ant-design/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ModalChuongTrinhTour from "./ModalChuongTrinhTour";
import ModalChonLoaiTien from "./ModalChonLoaiTien";
import ChietTinhService from "../../../../../api/tour/chiettinh.service";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {TIME_DURATION} from "../../../../../constants";

const {Text} = Typography;

const Link = withStyles({
    root: {
        "&[disabled]": {
            color: "grey",
            cursor: "default",
            "&:hover": {
                textDecoration: "none",
            },
        },
    },
})(MuiLink);

ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
        ],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    language: "en",
};

function Row(props) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [imgFile, setImgFile] = useState([]);
    const [visible, setVisible] = useState([]);
    const [scaleStep, setScaleStep] = useState(1);
    const [title, setTitle] = useState("");
    const showModal = (value) => {
        setIsModalOpen(true);
        setTitle(value);
    };

    const showImg = (value, key) => {
        var visible = [];
        for (let i = 0; i < imgFile.length; i++) {
            if (i === key) {
                visible.push(value);
            }
        }
        setVisible(visible);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        apiGetData();
    }, []);
    const apiGetData = () => {
        TourService.getByIdChuongTrinhTour({
            id: row.id,
        })
            .then(function (response) {
                // console.log("Response: getByIdChuongTrinhTour====", response.data);
                if (response.data.data) setImgFile(response.data.data.imgFile);
            })
            .catch(function (response) {
                console.log("Error response: ====", response);
            });
    };
    const handleFinish = (status, values, text) => {
        setIsModalOpen(status);
        console.log("handleFinish: " + JSON.stringify(values));
        TourService.saveChuongTrinhTour(values)
            .then(function (response) {
                console.log("Response: saveChuongTrinhTour====", response.data);
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description: text + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    props.updateApi();
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase() + " ",
                    duration: TIME_DURATION,
                });
                if (response.status === 401) {
                }
            });
    };
    const deleteData = (idDT) => {
        let data = {
            id: idDT,
        };
        TourService.clearChuongtrinhTour(data)
            .then(function (response) {
                // console.log("Response: saveChuongTrinhTour====" + response.data);
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    props.updateApi();
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                    duration: TIME_DURATION,
                });
                if (response.status === 401) {
                }
            });
    };
    const deleteDay = (idCT) => {
        let data = {
            id: idCT,
        };
        TourService.deleteChuongtrinhTour(data)
            .then(function (response) {
                // console.log("Response: saveChuongTrinhTour====" + response.data);
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    props.updateApi();
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                    duration: TIME_DURATION,
                });
                if (response.status === 401) {
                }
            });
    };

    return (
        <React.Fragment>
            {contextHolder}
            <TableRow sx={{"& > *": {borderBottom: "unset"}}}>
                {props.isDisable ? (
                    <TableCell component="th" scope="row">
                        <RemoveCircleOutlineIcon onClick={(e) => {
                            deleteDay(row.id)
                        }}/>
                    </TableCell>
                ) : (
                    ""
                )}
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>

                <TableCell component="th" scope="row">
                    {row.ngay}
                </TableCell>
                {row.hanhTrinh !== null && row.hanhTrinh !== "" ? (
                    <>
                        <TableCell>
                            {row.hanhTrinh} &nbsp;&nbsp;
                            {/*<CachedIcon fontSize="small"*/}
                            {/*            onClick={() => {*/}
                            {/*                reload(row.ngay)*/}
                            {/*            }}*/}
                            {/*/>*/}
                            <EditIcon
                                fontSize="small"
                                onClick={() => {
                                    showModal("edit");
                                }}
                            />
                            <DeleteIcon
                                fontSize="small"
                                onClick={(e) => {
                                    deleteData(row.id);
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            {row.dmDichVus != null
                                ? row.dmDichVus.map(function (item, i) {
                                    return (
                                        <Tag color="blue" key={i}>
                                            {item.tenDichVu}
                                        </Tag>
                                    );
                                })
                                : null}
                        </TableCell>
                        <TableCell>{row.diemDi.diaDiem}</TableCell>
                        <TableCell>
                            {row.diemDen
                                ? row.diemDen.map(function (item, i) {
                                    return <div key={i}>{item.diaDiem}</div>;
                                })
                                : null}
                        </TableCell>
                        <TableCell>
                            {imgFile
                                ? imgFile.map(function (item, i) {
                                    var vs = visible && visible[i] ? visible[i] : false;
                                    return (
                                        <>
                                            <Button
                                                key={i}
                                                icon={<FileImageFilled/>}
                                                onClick={() => showImg(true, i)}
                                            />
                                            <Image
                                                style={{
                                                    display: "none",
                                                }}
                                                fallback={
                                                    item && item.base64Value
                                                        ? "data:image/png;base64," + item.base64Value
                                                        : null
                                                }
                                                preview={
                                                    visible && visible[i]
                                                        ? {
                                                            visible,
                                                            scaleStep,
                                                            onVisibleChange: (value) => {
                                                                showImg(value, i);
                                                            },
                                                        }
                                                        : false
                                                }
                                            />
                                        </>
                                    );
                                })
                                : null}
                        </TableCell>
                    </>
                ) : (
                    <TableCell>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                showModal("add");
                            }}
                        >
                            {t("add") + " " + t("trip")}
                        </Link>
                    </TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={row.noiDung}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log("Editor is ready to use!", editor);
                                }}
                                onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                }}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <ModalChuongTrinhTour
                id={row.id}
                ngay={row.ngay}
                isOpen={isModalOpen}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                title={title}
            />
        </React.Fragment>
    );
}

export default function ChuongTrinhTour({dvId, soNgay}) {
    const {t, i18n} = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [isOpenLoaiTien, setIsOpenLoaiTien] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const navigate = useNavigate();

    const showModalLoaiTien = () => {
        checkLoaiTien();
    };
    const checkLoaiTien = () => {
        let data = {
            typeData: 0,
            tourThongTinChungId: dvId,
        };
        ChietTinhService.getChietTinh(data)
            .then(function (response) {
                if (response.data.code === "03") {
                    setIsOpenLoaiTien(true);
                } else {
                    let data = response.data.data;
                    return navigate(
                        "/dich-vu/tour-san-pham/chiet-tinh-tour/tour-id?=" + dvId + "&ty-gia-goc?=" + data.tyGiaGoc.id
                    );
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
    };
    const handleFinishLoaiTien = (loaiTien) => {
        setIsOpenLoaiTien(false);
        chietTinhTour(loaiTien);
    };
    const handleCancelLoaiTien = () => {
        setIsOpenLoaiTien(false);
    };
    useEffect(() => {
        getCTTour();
    }, [dvId, soNgay]);
    const getCTTour = () => {
        let data = {
            tourThongTinChungId: dvId,
        };
        TourService.getChuongTrinhTour(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    console.log(data);

                    let dataArr = [];
                    if (data !== null) {
                        if (data.length >= 0) {
                            if (parseInt(soNgay) < data.length) {
                                setIsDisable(true);
                            }
                            for (let i = 0; i < data.length; i++) {
                                dataArr.push({
                                    stt: i + 1,
                                    id: data[i].id,
                                    ngay: data[i].ngay,
                                    hanhTrinh: data[i].hanhTrinh,
                                    noiDung: data[i].noiDung,
                                    dmDichVus: data[i].dmDichVus,
                                    diemDi: data[i].diemDi,
                                    diemDen: data[i].diemDen,
                                    imgFile: data[i].imgFile,
                                    history: [
                                        {
                                            content: data[i].noiDung,
                                        },
                                    ],
                                });
                            }
                        }
                    } else {
                        for (let i = 0; i < soNgay; i++) {
                            dataArr.push({
                                stt: i + 1,
                                id: 0,
                                ngay: t("day") + " " + (i + 1),
                                diemDi: {diemDen: ""},
                                history: [],
                            });
                        }
                    }
                    setDataSource(dataArr);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    // useEffect(() => {
    //     let check = false;
    //     dataSource.forEach((dt) => (!dt.hanhTrinh ? (check = true) : null));
    //     setIsDisable(check);
    // }, [dataSource]);
    // const direct = (record, status) => {
    //     return navigate("/dich-vu/tour-san-pham/" + status + "?id=" + record.id);
    // };
    const chietTinhTour = (loaiTien) => {
        return navigate("/dich-vu/tour-san-pham/chiet-tinh-tour/tour-id?=" + dvId + "&ty-gia-goc?=" + loaiTien);
    };
    return (
        <>
            <TableContainer component={Paper}>
                {contextHolder}
                <Table aria-label="collapsible table">
                    <caption>
                        {isDisable ? (
                            <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{display: "inline-block"}}>
                                <Text type="danger">{t("checkCauHinhTGTour")}</Text>
                            </Col>
                        ) : (
                            <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{display: "inline-block"}}></Col>
                        )}
                        <Col
                            xs={20}
                            sm={20}
                            md={20}
                            lg={20}
                            xl={10}
                            style={{display: "inline-block", marginLeft: "50%"}}
                        >
                            <Link disabled={isDisable} component="button" variant="body2" onClick={showModalLoaiTien}>
                                {t("chietTinhTour")}
                            </Link>
                        </Col>
                    </caption>
                    <TableHead
                        sx={{
                            "& th": {
                                backgroundColor: "rgb(226, 226, 226)",
                                color: "black",
                                fontWeight: "bold",
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell/>
                            <TableCell>{t("day")}</TableCell>
                            <TableCell>{t("trip")}</TableCell>
                            <TableCell>{t("typeService")}</TableCell>
                            <TableCell>{t("point")}</TableCell>
                            <TableCell>{t("destination")}</TableCell>
                            <TableCell>{t("hinhAnh")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSource.map((row) => {
                            return <Row key={row.ngay} row={row} updateApi={getCTTour} isDisable={isDisable}/>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ModalChonLoaiTien isOpen={isOpenLoaiTien} onCancel={handleCancelLoaiTien} onSave={handleFinishLoaiTien}/>
        </>
    );
}
