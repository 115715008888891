import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { FormHelperText, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Buttons from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Affix, Cascader, Col, Input, Modal, Row, Space, Switch, Upload, message, notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import authHeader from "../../../../../api/auth_header";
import CategoryService from "../../../../../api/category.service";
import "../../../../../assets/css/services/tour.css";
import "../../../../../assets/css/supplier/GeInfo.css";
import {API_BASE_URL, data_getDM, maxFile, TIME_DURATION} from "../../../../../constants";

const { Dragger } = Upload;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const GeneralInfo = ({ dvName, dvId, addId, isView, setSoNgayAll, newId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [maTour, setMaTour] = useState("");
    const [maTourErrorText, setMaTourErrorText] = React.useState("");
    const [tenTour, setTenTour] = useState("");
    const [tenTourErrorText, setTenTourErrorText] = useState("");
    const [soNgayErrorText, setSoNgayErrorText] = useState("");
    const [diemDiCuThe, setDiemDiCuThe] = useState("");
    const [soDem, setSoDem] = useState("");
    const [tamUng, setTamUng] = useState(0);
    const [loaiHinhDulichErrorText, setLoaiHinhDulichErrorText] = useState("");
    const [soDemErrorText, setSoDemErrorText] = useState("");
    const [soNgay, setSoNgay] = useState(0);
    const [quocGiaId, setQuocGiaId] = useState("");
    const [diaDiem, setDiaDiem] = useState(null);
    // const [diaChiCuThe, setDiaChiCuThe] = useState("");
    const [loaiHinhDulich, setLoaiHinhDulich] = useState([]);
    const [dmLoaiHinhDulich, setDmLoaiHinhDulich] = useState([]);
    const [ghiChu, setGhiChu] = useState("");
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTP, setDataTinhTP] = useState([]);
    const [dataTinhTP_Dd, setDataTinhTP_Dd] = useState([]);
    const [fileListPdf, setFileListPdf] = useState([]);
    const [id, setId] = useState(0);
    const [diemDi, setDiemDi] = useState();
    const [tinhId, setTinhId] = useState();
    const [huyenId, setHuyenId] = useState();
    const [xaId, setXaId] = useState();
    const [trangThai, setTrangThai] = useState(false);
    const [dmLoaiTourId, setDmLoaiTourId] = useState(5);
    const [loaiTour, setLoaiTour] = useState("Private");
    const props = {
        name: "file",
        multiple: true,
        maxCount: maxFile,
        // disabled: fileListPdf.length >= 5? true: false,
        onRemove: (file) => {
            const index = fileListPdf.indexOf(file);
            const newFileList = fileListPdf.slice();
            newFileList.splice(index, 1);
            setFileListPdf(newFileList);
        },
        beforeUpload: (file) => {
            const isPNG = file.type === "application/pdf";
            if (!isPNG) {
                message.error(file.name + " " + t("errorUploadPDF"));
            }
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                message.error("Image must smaller than 3MB!");
            }
            return isPNG || Upload.LIST_IGNORE;
            // setFileListPdf([...fileListPdf, file]);
            // return false;
        },
        onChange(info) {
            let newFileList = [...info.fileList];
            newFileList = newFileList.map((file) => {
                if (file.response) {
                    // Component will show file.url as link
                    file.status = "done";
                }
                return file;
            });

            setFileListPdf(newFileList);
        },
        fileListPdf,
    };

    useEffect(() => {
        CategoryService.getDmLoaiHinhDuLich(data_getDM)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                id: data[i].id,
                                value: data[i].loai_hinh_du_lich,
                            });
                            setDmLoaiHinhDulich(dataLst);
                        }
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        if (dvId !== 0) {
            let apiData = {
                id: dvId,
            };
            // console.log("===apiData===", apiData);
            let fileImg = [];
            let filePdf = [];
            axios({
                method: "post",
                url: API_BASE_URL + "tour/thongtinchung/getDataById",
                data: apiData,
                headers: authHeader(),
            })
                .then(function (response) {
                    if (response.status === 200 && response.data.code === "00") {
                        console.log("Response: ====", response.data.data);
                        let data = response.data.data;
                        setId(data.id);
                        setSoNgayAll(data.soNgay);
                        setSoNgay(data.soNgay);
                        setMaTour(data.maTour);
                        setSoDem(data.soDem);
                        setTenTour(data.tenTour);
                        setTamUng(data.tamUng);
                        setTrangThai(data.trangThai);
                        if (data.loaiHinhDuLich) {
                            var loaiHinhDuLich = [];
                            data.loaiHinhDuLich.forEach((element) => {
                                loaiHinhDuLich.push(element.id);
                            });
                            setLoaiHinhDulich(loaiHinhDuLich);
                        }
                        setQuocGiaId(data.diemDi.quocGiaId);
                        debugger;
                        handleChangeQG_DiemDi({
                            target: {
                                value: data.diemDi.quocGiaId,
                            },
                        });
                        setDiaDiem(data.diemDi.diaDiem);
                        setTinhId(data.diemDi.tinhId);
                        setXaId(data.diemDi.xaId);
                        setHuyenId(data.diemDi.huyenId);
                        setDiemDiCuThe(data.diemDi.diaChiCuThe);
                        if (data.diemDen) {
                            var items = [];
                            items = data.diemDen.map((i) => {
                                return {
                                    quocGiaId: i.quocGiaId,
                                    tinhId: i.tinhId,
                                    huyenId: i.huyenId,
                                    xaId: i.xaId,
                                    diaDiem: i.diaDiem,
                                    diaChiCuThe: i.diaChiCuThe,
                                };
                            });
                            setItems(items);
                        }
                        setGhiChu(data.ghiChu);
                        setDmLoaiTourId(data.dmLoaiTourId);
                        setLoaiTour(data.loaiTour);
                        setTenTour(data.tenTour);
                        if (data.imgFile != null || data.imgFile.length > 0) {
                            for (let k = 0; k < data.imgFile.length; k++) {
                                fileImg.push({
                                    id: data.imgFile[k].id,
                                    uid: data.imgFile[k].id,
                                    name: data.imgFile[k].tenFile,
                                    status: "done",
                                    thumbUrl: "data:image/png;base64," + data.imgFile[k].base64Value,
                                    preview: "data:image/png;base64," + data.imgFile[k].base64Value,
                                });
                            }
                        }
                        setFileList(fileImg);
                        for (let h = 0; h < data.pdfFile.length; h++) {
                            filePdf.push({
                                id: data.pdfFile[h].id,
                                uid: data.pdfFile[h].id,
                                name: data.pdfFile[h].tenFile,
                                status: "done",
                                thumbUrl: "data:application/pdf;base64," + data.pdfFile[h].base64Value,
                                preview: "data:application/pdf;base64," + data.pdfFile[h].base64Value,
                            });
                        }
                        setFileListPdf(filePdf);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                });
        }
    }, [dvId]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachQuocGia",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    // const [valueRadio, setValueRadio] = useState(1);
    // const [valueRadioPay, setValueRadioPay] = useState();
    const onChangeRadio = (e) => {
        console.log("radio checked", e.target.value);
        setDmLoaiTourId(e.target.value);
        setLoaiTour(e.target.value === 1 ? "S.I.C" : "Private" );
    };
    // const onChangeRadioPay = (e) => {
    //     console.log("radio checked", e.target.value);
    //     setValueRadioPay(e.target.value);
    // };
    // const [codTrue, setCodTrue] = useState();
    // const [codFalse, setCodFalse] = useState();
    // const handleInputChange = (e) => {
    //     if (e.target.name === "codTrue") {
    //         setCodTrue(e.target.value);
    //     } else {
    //         setCodFalse(e.target.value);
    //     }
    // };
    const [fileList, setFileList] = useState([]);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };
    const handleExit = () => {
        navigate("/ncc/" + dvName);
    };
    const handleChangeImg = ({ fileList: newFileList }) => {
        console.log("handleChangeImg ====", newFileList);

        if (newFileList.length > 0) {
            for (let i in newFileList) {
                newFileList[i].status = "done";
            }
        }
        setFileList(newFileList);
    };
    const beforeUploadImg = (file) => {
        const isPNG =
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/giff";
        if (!isPNG) {
            message.error(`${file.name} ` + " " + t("errorUploadImg"));
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error("Image must smaller than 3MB!");
        }

        return isPNG || Upload.LIST_IGNORE;
    };
    const handleChangeQG_DiemDi = (e) => {
        setQuocGiaId(e.target.value);
        let data = {
            dm_quoc_gia_id: e.target.value,
        };
        console.log(data);

        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ==== danhSachDiaDiem", response);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = {
                                    value: o.tinh_thanhpho,
                                    label: o.tinh_thanhpho,
                                    id: o.dm_tinh_id,
                                    children: [],
                                };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = { value: o.quan_huyen, label: o.quan_huyen, id: o.dm_huyen_id, children: [] };
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                    id: o.dm_xa_id,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        setDataTinhTP(result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const handleChangeQG_item = (e, i) => {
        setQuocGiaId(e.target.value);
        setDiemDi(null);
        handleItemChange(e.target.name, i, e.target.value);
        let data = {
            dm_quoc_gia_id: e.target.value,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachDiaDiem",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;

                    if (data.length > 0) {
                        let result = data.reduce((acc, o) => {
                            let tinhId = o.dm_tinh_id;
                            let cur = acc[tinhId];
                            if (!cur) {
                                cur = {
                                    value: o.tinh_thanhpho,
                                    label: o.tinh_thanhpho,
                                    id: o.dm_tinh_id,
                                    children: [],
                                };
                                acc[tinhId] = cur;
                            }
                            cur.children.push(o);
                            return acc;
                        }, {});
                        result = Object.values(result).map((v) => {
                            let dic = v.children.reduce((acc, o) => {
                                let huyenId = o.dm_huyen_id;
                                let cur = acc[huyenId];
                                if (!cur) {
                                    cur = { value: o.quan_huyen, label: o.quan_huyen, id: o.dm_huyen_id, children: [] };
                                    acc[huyenId] = cur;
                                }
                                cur.children.push({
                                    value: o.phuong_xa,
                                    label: o.phuong_xa,
                                    id: o.dm_xa_id,
                                });
                                return acc;
                            }, {});
                            v.children = Object.values(dic);
                            return v;
                        });
                        handleItemChange("TinhTp", i, result);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    const onChangeCascader_item = (value, selectedOptions, index) => {
        if (selectedOptions !== undefined) {
            if(selectedOptions.length > 0) {
                for (let i = 0; i < selectedOptions.length; i++) {
                    if (i === 0) {
                        handleItemChange("tinhId", index, selectedOptions[i].id);
                    }
                    if (i === 1) {
                        handleItemChange("huyenId", index, selectedOptions[i].id);
                    }
                    if (i === 2) {
                        handleItemChange("xaId", index, selectedOptions[i].id);
                    }
                    handleItemChange("diaDiem", index, value.join(","));
                }
            }
        }else {
            // handleItemChange("tinhId", index, "");
            // handleItemChange("huyenId", index, "");
            // handleItemChange("xaId", index, "");
            handleItemChange("diaDiem", index, "");
        }
    };
    const onChangeCascader_diemDi = (value, selectedOptions) => {
        console.log(value, selectedOptions);
        let tinhId = 0;
        let huyenId = 0;
        let xaId = 0;
        if (selectedOptions !== undefined && value !== undefined) {
            if (selectedOptions.length > 0) {
                for (let i = 0; i < selectedOptions.length; i++) {
                    if (i === 0) {
                        tinhId = selectedOptions[i].id;
                    }
                    if (i === 1) {
                        huyenId = selectedOptions[i].id;
                    }
                    if (i === 2) {
                        xaId = selectedOptions[i].id;
                    }
                }
            }
            setDiaDiem(value.join(","));
            setDiemDi({
                quocGiaId: quocGiaId,
                diaDiem: value.join(","),
                tinhId: tinhId,
                huyenId: huyenId,
                xaId: xaId,
                diaChiCuThe: diemDiCuThe,
            });
        } else {
            setDiaDiem("");
            setDiemDi(null);
        }
    };
    const filterCascader = (inputValue, path) => {
        path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const onFinish = async (e) => {
        e.preventDefault();
        if (!maTour) {
            setMaTourErrorText(t("validate") + " " + t("maTour").toLowerCase() + "!");
        } else {
            setMaTourErrorText("");
        }
        if (!tenTour) {
            setTenTourErrorText(t("validate") + " " + t("tenTour").toLowerCase() + "!");
        } else {
            setTenTourErrorText("");
        }
        if (!soNgay) {
            setSoNgayErrorText(t("validate") + " " + t("soNgay").toLowerCase() + "!");
        } else {
            setSoNgayErrorText("");
        }
        if (!soDem) {
            setSoDemErrorText(t("validate") + " " + t("soDem").toLowerCase() + "!");
        } else {
            setSoDemErrorText("");
        }
        if (loaiHinhDulich.length <= 0) {
            setLoaiHinhDulichErrorText(t("validate") + " " + t("typeTourism").toLowerCase() + "!");
        } else {
            setLoaiHinhDulichErrorText("");
        }

        let dataImg = [];
        if (fileList.length > 0) {
            for (let j in fileList) {
                dataImg.push({
                    id: fileList[j].id !== undefined ? fileList[j].id : 0,
                    tenFile: fileList[j].name,
                    base64Value: fileList[j].thumbUrl.split("base64,")[1],
                });
            }
        }
        let dataPdf = [];
        if (fileListPdf.length > 0) {
            for (let k in fileListPdf) {
                let base64 = await getBase64(fileListPdf[k].originFileObj);
                dataPdf.push({
                    id: fileListPdf[k].id !== undefined ? fileListPdf[k].id : 0,
                    tenFile: fileListPdf[k].name,
                    base64Value: base64.split("base64,")[1],
                });
            }
        }
        var loaiHinhDuLichDt = loaiHinhDulich.map((lt) => {
            return {
                id: lt,
                loaiHinhDuLich: dmLoaiHinhDulich.find((e) => e.id === lt).value,
            };
        });
        let data = {
            id: id, //thêm mới để 0
            maTour: maTour,
            tenTour: tenTour,
            soNgay: soNgay ? parseInt(soNgay) : 0,
            soDem: soDem ? parseInt(soDem) : 0,
            tamUng: parseInt(tamUng),
            loaiHinhDuLich: loaiHinhDuLichDt,
            diemDi: diemDi,
            diemDen: items,
            ghiChu: ghiChu,
            trangThai: trangThai,
            dmLoaiTourId: dmLoaiTourId,
            loaiTour: loaiTour,
            pdfFile: dataPdf,
            imgFile: dataImg,
        };

        console.log("Received data of form: ", JSON.stringify(data));
        if (maTour && tenTour && soNgay && soDem) {
            axios({
                method: "post",
                url: API_BASE_URL + "tour/thongtinchung/saveData",
                data: data,
                headers: authHeader(),
            })
                .then(function (response) {
                    console.log("response ====: ", response);
                    if (response.status === 200 && response.data.code === "00") {
                        newId(response.data.data);
                        setSoNgayAll(soNgay);
                        addId(true);
                        localStorage.setItem("SO_NGAY", soNgay);
                        let txt = id !== 0 ? t("edit") : t("add")
                        api["success"]({
                            message: t("stour"),
                            description: txt + " " + t("noti.success").toLowerCase(),
                            duration: TIME_DURATION,
                        });
                        setId(response.data.data);
                    } else {
                        addId(false);
                        api["error"]({
                            message: t("stour"),
                            description: t("add") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
                            duration: TIME_DURATION,
                        });
                    }
                })
                .catch(function (response) {
                    addId(false);
                    api["error"]({
                        message: t("stour"),
                        description: t("add") + " " + t("noti.error").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                });
        }
    };
    const handleClear = () => {
        // form.resetFields();
    };
    const handleCancels = () => {
        // form.resetFields();
        navigate("/dich-vu/" + dvName);
    };
    const handleChangeDv = (event) => {
        const {
            target: { value },
        } = event;
        setLoaiHinhDulich(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };
    const [bottom, setBottom] = React.useState(50);
    const onChangeTrangThai = (checked) => {
        setTrangThai(checked);
        console.log(`switch to ${checked}`);
    };
    const [items, setItems] = useState([
        { quocGiaId: 0, tinhId: 0, huyenId: 0, xaId: 0, diaDiem: "", diaChiCuThe: "", TinhTp: "" },
    ]);

    const handleAddField = () => {
        setItems([
            ...items,
            { quocGiaId: 0, tinhId: 0, huyenId: 0, xaId: 0, diaDiem: "", diaChiCuThe: "", TinhTp: "" },
        ]);
    };

    const handleRemoveField = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };

    const handleItemChange = (name, index, value) => {
        const newItems = [...items];
        newItems[index][name] = value;
        setItems(newItems);
    };
    const handleChaneDiemDen = (value, index) => {
        setItems(
            items.map((it, ind) => {
                if (ind === index)
                    return {
                        ...it,
                        diaChiCuThe: value,
                    };
                else return it;
            })
        );
    };
    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <TextField
                                    name="tenTour"
                                    id="tenTour"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    required
                                    fullWidth
                                    label={t("tenTour")}
                                    value={tenTour}
                                    onChange={(e) => setTenTour(e.target.value)}
                                    error={!!tenTourErrorText}
                                    helperText={tenTourErrorText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    name="maTour"
                                    id="maTour"
                                    fullWidth
                                    required
                                    label={t("maTour")}
                                    value={maTour}
                                    onChange={(e) => setMaTour(e.target.value)}
                                    error={!!maTourErrorText}
                                    helperText={maTourErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                                <TextField
                                    id="tamUng"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    label={t("tamUng")}
                                    value={tamUng}
                                    onChange={(e) => setTamUng(e.target.value)}
                                />
                            </Col>

                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                                <TextField
                                    name="soNgay"
                                    id="soNgay"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    required
                                    fullWidth
                                    type="number"
                                    label={t("soNgay")}
                                    value={soNgay}
                                    onChange={(e) => setSoNgay(e.target.value)}
                                    error={!!soNgayErrorText}
                                    helperText={soNgayErrorText}
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={5}>
                                <TextField
                                    name="soDem"
                                    id="soDem"
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    required
                                    fullWidth
                                    type="number"
                                    label={t("soDem")}
                                    value={soDem}
                                    onChange={(e) => setSoDem(e.target.value)}
                                    error={!!soDemErrorText}
                                    helperText={soDemErrorText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <FormControl sx={{ marginBottom: 2, width: "100%" }} error={!!loaiHinhDulichErrorText}>
                                    <InputLabel id="demo-multiple-checkbox-label">{t("typeTourism") + " *"}</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={loaiHinhDulich}
                                        onChange={handleChangeDv}
                                        input={<OutlinedInput label={t("typeTourism")} />}
                                        // renderValue={(selected) => selected.map(obj=> dataDichVu[obj - 1].value).join(", ")}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                {selected.map((dvid) => (
                                                    <Chip
                                                        key={dvid}
                                                        label={
                                                            dmLoaiHinhDulich.length > 0
                                                                ? dmLoaiHinhDulich?.find((e) => e.id === dvid).value
                                                                : ""
                                                        }
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {dmLoaiHinhDulich.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                <Checkbox checked={loaiHinhDulich.indexOf(option.id) > -1} />
                                                <ListItemText primary={option.value} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{loaiHinhDulichErrorText}</FormHelperText>
                                </FormControl>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                <Space.Compact
                                    size="large"
                                    style={{
                                        width: "100%",
                                        marginBottom: 15,
                                        height: "56px",
                                    }}
                                    className=""
                                >
                                    <Input
                                        style={{
                                            width: "25%",
                                            borderRadius: "4px",
                                        }}
                                        readOnly
                                        defaultValue={t("point")}
                                    />
                                    <TextField
                                        xs={{
                                            width: "75%",
                                            borderRadius: "4px",
                                        }}
                                        label={t("location.country")}
                                        select
                                        SelectProps={{
                                            MenuProps: {
                                                sx: { height: "300px" },
                                            },
                                        }}
                                        fullWidth
                                        name="quocGiaId"
                                        onChange={handleChangeQG_DiemDi}
                                        value={quocGiaId}
                                    >
                                        {dataQuocGia.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Space.Compact>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={13} >
                                <Cascader
                                    style={{
                                        width: "100%",
                                        borderRadius: "4px",
                                    }}
                                    size="large"
                                    options={dataTinhTP}
                                    onChange={onChangeCascader_diemDi}
                                    showSearch={{
                                        filterCascader,
                                    }}
                                    placeholder={
                                        t("location.city") +
                                        " - " +
                                        t("location.district") +
                                        " - " +
                                        t("location.wards")
                                    }
                                    onSearch={(value) => console.log(value)}
                                    value={diaDiem !== null ? diaDiem.split(",") : []}
                                />
                            </Col>
                        </Row>

                        <Row style={{marginTop:"22px"}}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("diemDiCuThe")}
                                    value={diemDiCuThe}
                                    onChange={(e) => setDiemDiCuThe(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                {items.map((item, index) => (
                                    <>
                                        <Row key={index} style={{ width: "100%" }}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                                <Space.Compact
                                                    size="large"
                                                    style={{
                                                        width: "100%",
                                                        marginBottom: 15,
                                                        height: "56px",
                                                    }}
                                                    className=""
                                                >
                                                    <Input
                                                        style={{
                                                            width: "25%",
                                                            borderRadius: "4px",
                                                        }}
                                                        readOnly
                                                        defaultValue={t("destination")}
                                                    />
                                                    <TextField
                                                        xs={{
                                                            width: "75%",
                                                            borderRadius: "4px",
                                                        }}
                                                        SelectProps={{
                                                            MenuProps: {
                                                                sx: { height: "300px" },
                                                            },
                                                        }}
                                                        label={t("location.country")}
                                                        select
                                                        fullWidth
                                                        name="quocGiaId"
                                                        onChange={(event) => handleChangeQG_item(event, index)}
                                                        value={item.quocGiaId}
                                                    >
                                                        {dataQuocGia.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Space.Compact>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                <Cascader
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "4px",
                                                    }}
                                                    size="large"
                                                    options={item.TinhTp}
                                                    onChange={(value, selectedOptions) =>
                                                        onChangeCascader_item(value, selectedOptions, index)
                                                    }
                                                    showSearch={{
                                                        filterCascader,
                                                    }}
                                                    placeholder={
                                                        t("location.city") +
                                                        " - " +
                                                        t("location.district") +
                                                        " - " +
                                                        t("location.wards")
                                                    }
                                                    onSearch={(value) => console.log(value)}
                                                    value={item.diaDiem.length > 0 ? item.diaDiem.split(",") : []}
                                                />
                                            </Col>
                                            {items.length > 1 ? (
                                                <RemoveCircleOutlineIcon
                                                    style={{ marginTop: "15px", marginLeft: "10px" }}
                                                    onClick={() => handleRemoveField()}
                                                />
                                            ) : null}
                                        </Row>
                                        <Row style={{marginTop:"22px"}}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                                <TextField
                                                    sx={{
                                                        marginBottom: 2,
                                                    }}
                                                    fullWidth
                                                    label={t("diemDenCuThe")}
                                                    onChange={(e) => handleChaneDiemDen(e.target.value, index)}
                                                    value={item.diaChiCuThe}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                ))}
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={1}>
                                <AddBoxIcon onClick={() => handleAddField()} style={{ marginTop: "15px" }} />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <TextField
                                    sx={{
                                        marginBottom: 2,
                                    }}
                                    fullWidth
                                    label={t("notes")}
                                    multiline
                                    rows={2}
                                    value={ghiChu}
                                    onChange={(e) => setGhiChu(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={23}>
                                <Dragger {...props} fileList={fileListPdf}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">{t("ClickorDag")}</p>
                                    <p className="ant-upload-hint">{t("validateUploadPdf")}</p>
                                </Dragger>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                        <Row>
                            <Upload
                                beforeUpload={beforeUploadImg}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChangeImg}
                            >
                                {fileList.length >= maxFile ? null : uploadButton}
                            </Upload>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img
                                    alt="image"
                                    style={{
                                        width: "100%",
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        </Row>

                        <Row style={{ marginTop: "20px" }}>
                            <FormControlLabel
                                style={{ marginLeft: "-5px" }}
                                label={t("choPhepBooking")}
                                control={
                                    <Switch
                                        style={{ marginLeft: "10px" }}
                                        value={trangThai}
                                        onChange={onChangeTrangThai}
                                    />
                                }
                                labelPlacement="start"
                            />
                        </Row>

                        <Row>
                            <Space direction="vertical">
                                <h3>{t("typeTour") + "*"}</h3>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={dmLoaiTourId}
                                    onChange={onChangeRadio}
                                >
                                    <FormControlLabel value={3} control={<Radio />} label={t("private")} />
                                    <FormControlLabel value={1} control={<Radio />} label={t("sicGroup")} />
                                </RadioGroup>
                            </Space>
                        </Row>
                    </Col>
                </Row>
            </Space>
        );
        return html;
    };
    return (
        <Box component="form" noValidate>
            {contextHolder}
            {dataRender()}
            <Affix offsetBottom={bottom}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                        <div
                            style={{
                                textAlign: "center",
                                color: "#fff",
                                height: 60,
                                paddingInline: 50,
                                lineHeight: "60px",
                                backgroundColor: "rgba(150, 150, 150, 0.2)",
                                borderRadius: "4px",
                            }}
                        >
                            {isView ? (
                                <Buttons key="back" onClick={handleExit}>
                                    {t("exit")}
                                </Buttons>
                            ) : (
                                <Space>
                                    <Buttons
                                        variant="contained"
                                        onClick={onFinish}
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                    >
                                        {t("action.ok")}
                                    </Buttons>
                                    <Buttons
                                        style={{
                                            backgroundColor: "#898989",
                                            color: "white",
                                        }}
                                        key="reset"
                                        startIcon={<RestartAltIcon />}
                                        onClick={(e) => handleClear(e)}
                                    >
                                        {t("action.reset")}
                                    </Buttons>
                                    <Buttons
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        onClick={handleCancels}
                                        key="clear"
                                    >
                                        {t("action.cancel")}
                                    </Buttons>
                                </Space>
                            )}
                        </div>
                    </Col>
                </Row>
            </Affix>
        </Box>
    );
};
export default GeneralInfo;
