import { MenuItem, TextField } from "@mui/material";
import { Col, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../api/auth_header";
import { API_BASE_URL } from "../../../../constants";

const style = {
    h4: { fontSize: "14px", fontWeight: "bold", marginTop: "10px" },
    rowTop: { marginTop: "10px" },
    InputLabelProps: { color: "#858383", fontSize: "22px", fontWeight: 600 },
};
const RenderDiemDen = ({ quocGiaId, tinhId, items, index, cards, setCards, idx, setIsChangeHanhTrinh }) => {
    const { t } = useTranslation();
    const [dataQuocGia, setDataQuocGia] = useState([]);
    const [dataTinhTp, setDataTinhTp] = useState([]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachQuocGia",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    // console.log("Response: ====" + response.data.data);
                    let dataQG = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataQG.push({
                                    value: data[i].id,
                                    label: data[i].quoc_gia,
                                });
                            }
                        }
                        setDataQuocGia(dataQG);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    useEffect(() => {
        if (quocGiaId) handleChangeQG(quocGiaId, tinhId);
    }, [quocGiaId, tinhId]);
    const handleChangeQG = async (value, tinh) => {
        console.log(`selected ${value}`);
        let data = {
            dm_quoc_gia_id: value,
        };
        await axios({
            method: "post",
            url: API_BASE_URL + "category/danhSachTinhThanhPho",
            data: data,
            headers: authHeader(),
        }).then(function (response) {
            // console.log("Response: ====" + response.status);
            if (response.status === 200) {
                let dataTp = [];
                let data = response.data.data;
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].hieu_luc === true) {
                            dataTp.push({
                                value: data[i].id,
                                label: data[i].tinh_thanhpho,
                            });
                        }
                    }
                    debugger;
                    setDataTinhTp(dataTp);
                    if (!items.diemDen[idx]) {
                        items.diemDen[idx] = {};
                    }
                    items.diemDen[idx].quocGiaId = value;
                    let data1 = [
                        ...cards.map((card, i) => {
                            if (index === i) return items;
                            return card;
                        }),
                    ];
                    setCards(data1);
                }
            }
        });
    };
    return (
        <>
            <Row>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={style.h4}>{t("destination")}</div>
                </Col>
                <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                    <TextField
                        value={items.diemDen[idx]?.quocGiaId}
                        select
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                            handleChangeQG(e.target.value, null);
                            setIsChangeHanhTrinh(true);
                        }}
                    >
                        {dataQuocGia.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                    <TextField
                        fullWidth
                        variant="standard"
                        value={items.diemDen[idx]?.tinhId}
                        select
                        onChange={(e) => {
                            debugger;
                            if (!items.diemDen[idx]) {
                                items.diemDen[idx] = {};
                            }
                            items.diemDen[idx].tinhId = e.target.value;
                            let data1 = [
                                ...cards.map((card, i) => {
                                    if (index === i) return items;
                                    return card;
                                }),
                            ];
                            setIsChangeHanhTrinh(true);
                            setCards(data1);
                        }}
                    >
                        {dataTinhTp.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Col>
            </Row>
        </>
    );
};
export default RenderDiemDen;
