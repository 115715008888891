import { CheckOutlined, EditOutlined, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Delete from "@mui/icons-material/Delete";
import Buttons from "@mui/material/Button";
// import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import { Link, Select, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, Card, Col, Collapse, Divider, Row, Space, Table, Tabs, notification } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import baoGiaService from "../../../api/bao_gia/baoGiaService";
import dm_do_tuoi from "../../../api/category/dm_do_tuoi";
import dm_ty_gia from "../../../api/category/dm_ty_gia";
import chiettinhService from "../../../api/tour/chiettinh.service";
import ModalTourSanPham from "../Modal/ModalTourSanPham";
import ChietTinhOP from "../TabChietTinhOP/ChietTinhOP";
import ChietTinhSale from "../TabChietTinhSale/ChietTinhSale";
import ThongTinTour from "../TabThongTinTour/ThongTinTour";
import HanhTrinh from "./TabHanhTrinh/HanhTrinh";
import { TIME_DURATION } from "../../../constants";
export default function FormThemMoi() {
    const fileInput = React.useRef();
    const focusRef = createRef();
    const location = useLocation();
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [dataDoTuoi, setDataDoTuoi] = useState([]);
    const [dmLoaiKhachHang, setDmLoaiKhachHang] = useState([]);
    const [dmLoaiTien, setDmLoaiTien] = useState([]);
    const [userAssigns, setUserAssigns] = useState([]);
    const [maBaoGia, setMaBaoGia] = useState("");
    const [tenBaoGia, setTenBaoGia] = useState("");
    const [loaiKhachHang, setLoaiKhachHang] = useState(0);
    const [tenKhachHang, setTenKhachHang] = useState("");
    const [ngayKhoiHanh, setNgayKhoiHanh] = useState("");
    const [ngayKhoiHanhDate, setNgayKhoiHanhDate] = useState();
    const [soNgay, setSoNgay] = useState();
    const [soDem, setSoDem] = useState();
    const [soNguoiLon, setSoNguoiLon] = useState();
    const [soTreEm, setSoTreEm] = useState();
    const [foc, setFoc] = useState();
    const [chietKhau, setChietKhau] = useState();
    const [loaiTienMacDinh, setLoaiTienMacDinh] = useState(0);
    // const [loaiTienQuyDoi, setLoaiTienQuyDoi] = useState(0);
    const [tyGia, setTyGia] = useState();
    const [userAssign, setUserAssign] = useState(0);
    const [ghiChu, setGhiChu] = useState("");
    const [fileDinhKem, setFileDinhKem] = useState();
    const [optionNhomTreEm, setOptionNhomTreEm] = useState([]);
    const [id, setId] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [tenTour, setTenTour] = useState("");
    const [tourThongTinChungId, setTourThongTinChungId] = useState(0);
    const [apDungThongTinTour, setApDungThongTinTour] = useState(false);
    const [loaiTienMau, setLoaiTienMau] = useState("");
    const [dmTyGiaQuyDoi, setDmTyGiaQuyDoi] = useState([]);
    // const [isNew, setIsNew] = useState(location.pathname.includes("new"));
    const [dataTableTreEm, setDataTableTreEm] = useState([{ id: "SUBTOTAL" }]);
    const [dataTableNguoiLon, setDataTableNguoiLon] = useState([{ id: "SUBTOTAL" }]);
    const [dataNhomNgLon, setDataNhomNgLon] = useState([]);
    const [dataNhomTreEm, setDataTreEm] = useState([]);
    const [errorSoNgay, setErrorSoNgay] = useState(false);
    const [action, setAction] = useState(location.pathname.includes("new") ? "" : "tiep_tuc");
    const [defaultActiveKey, setDefaultActiveKey] = useState("1");
    const [isDisableTyGia, setIsDisableTyGia] = useState(true);
    const [isDisable, setIsDisable] = useState(location.pathname.includes("new") ? false : true);
    const [isChangeHanhTrinh, setIsChangeHanhTrinh] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(false);
    useEffect(() => {
        let data = {};
        dm_do_tuoi
            .getDmDoTuoi(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDataDoTuoi(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
        getDmLoaiKhachHang();
        getDmLoaiTien();
        getUserAssign();
    }, []);

    useEffect(() => {
        let id = getDvId();
        if (id !== 0) {
            setId(id);
            setIsEdit(true);
            getBaoGiaTongQuanById(id);
        }
    }, []);

    const style = {
        h4: { fontSize: "14px", fontWeight: "bold", marginTop: "10px" },
        rowTop: { marginTop: "10px" },
        InputLabelProps: { color: "#858383", fontSize: "22px", fontWeight: 600 },
    };

    const getDvId = (value) => {
        let Id = 0;
        if (value === undefined) {
            if (location.search.length > 0) {
                let id = location.search.split("=");
                Id = id[1];
            }
        } else {
            Id = value;
        }
        return Id;
    };
    useEffect(() => {
        if (loaiTienMau === loaiTienMacDinh) {
            setTyGia(1);
        } else {
            for (let i = 0; i < dmTyGiaQuyDoi.length; i++) {
                const element = dmTyGiaQuyDoi[i];
                if (element.ma_ngoai_te_id === loaiTienMau && element.ma_quy_doi_id === loaiTienMacDinh) {
                    setTyGia(element.ty_gia_quy_doi);
                }
            }
        }
    }, [loaiTienMau, loaiTienMacDinh]);
    const getBaoGiaTongQuanById = (id) => {
        baoGiaService
            .getBaoGiaTongQuanById({ id: id })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    handleSetDataTongQuan(data);
                }
            })
            .catch((rs) => console.log("Error getBaoGiaTongQuanById: " + rs));
    };

    const getDmLoaiKhachHang = () => {
        baoGiaService
            .getDmLoaiKhachHang()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log(data);
                    setDmLoaiKhachHang(data);
                }
            })
            .catch((rs) => console.log("Error getDmKhachHang: " + rs));
    };
    useEffect(() => {
        dm_ty_gia
            .getDmTyGiaQuyDoi()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log(data);
                    setDmTyGiaQuyDoi(data);
                }
            })
            .catch((rs) => console.log("Error getDmLoaiTien: " + rs));
    }, []);
    const getDmLoaiTien = () => {
        dm_ty_gia
            .getDmTyGiaHeThong()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    console.log(data);
                    setDmLoaiTien(data);
                }
            })
            .catch((rs) => console.log("Error getDmLoaiTien: " + rs));
    };

    const getUserAssign = () => {
        baoGiaService
            .getUserAssign()
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    setUserAssigns(data);
                }
            })
            .catch((rs) => console.log("Error getUserAssign: " + rs));
    };

    const handleSetDataTongQuan = (data) => {
        console.log("data bao gia:  ", data);
        let dataTableNhomTreEms = [];
        let optionTreEms = [];
        let count = 0;
        data?.nhomTreEms?.forEach((item, idx) => {
            item.stt = idx + 1;
            optionTreEms.push({ maNhom: idx + 1 + "", tenNhom: item.tenNhom });
            let treEms = item.details?.map((dt, index) => {
                count++;
                return {
                    id: count,
                    stt: idx + 1,
                    nhomTreEm: item.tenNhom,
                    soLuong: dt.soLuong,
                    dmDoTuoiId: dt.dmDoTuoiId,
                    nhomNguoiLonId: item.nhomNguoiLonId,
                    rowSpan: index === 0 ? item.details.length : 0,
                };
            });
            dataTableNhomTreEms.push(...treEms);
        });
        dataTableNhomTreEms.push({ id: "SUBTOTAL" });
        let dataTableNhomNguoiLons = data?.nhomNguoiLons?.map((item, idx) => {
            return {
                id: item.id,
                stt: idx + 1,
                nhomNguoiLon: item.tenNhom,
                soLuong: item.soLuong,
                foc: item.foc,
                maNhomTreEm: data?.nhomTreEms
                    ?.filter((it) => it.nhomNguoiLonId === item.id)
                    .map((it) => {
                        return it.stt + "";
                    }),
                rowSpan: 1,
            };
        });
        dataTableNhomNguoiLons.push({ id: "SUBTOTAL" });
        setMaBaoGia(data.maBaoGia);
        setTenBaoGia(data.tenBaoGia);
        setLoaiKhachHang(data.dmLoaiKhachHang);
        setTenKhachHang(data.tenKhachHang);
        setNgayKhoiHanh(data.ngayKhoiHanh);
        setNgayKhoiHanhDate(new moment(data.ngayKhoiHanh, "DD/MM/YYYY"));
        setSoNgay(data.soNgay);
        setLoaiTienMau(data.dmLoaiTienMauId);
        setSoDem(data.soDem);
        setSoNguoiLon(data.soLuongNguoiLon);
        setSoTreEm(data.soLuongTreEm);
        setFoc(data.foc);
        setChietKhau(data.chietKhau);
        setTourThongTinChungId(data.tourThongTinChungId);
        setTenTour(data.tenTour);
        setApDungThongTinTour(data.apDungThongTinTour);
        setLoaiTienMacDinh(data.dmLoaiTienBaoGiaId);
        // setLoaiTienQuyDoi(data.dmTienQuyDoiId);
        setTyGia(data.tyGia);
        setUserAssign(data.userAssign);
        setGhiChu(data.ghiChu);
        setFileDinhKem(data.fileDinhKem);
        setOptionNhomTreEm(optionTreEms);
        setDataTableTreEm(dataTableNhomTreEms);
        setDataTableNguoiLon(dataTableNhomNguoiLons);
        setDataTreEm(data.nhomTreEms);
        setDataNhomNgLon(data.nhomNguoiLons);
    };

    const handleAddRow = (row) => {
        let data = [];
        let stt = 0;
        dataTableTreEm.forEach((dt) => {
            if ("SUBTOTAL" === dt.id) {
                data.push({
                    id: "NEW" + new Date().getTime(),
                    stt: stt + 1,
                    nhomTreEm: "",
                    soLuong: 1,
                    dmDoTuoiId: 1,
                    rowSpan: 1,
                });
                setOptionNhomTreEm([...optionNhomTreEm, { maNhom: stt + 1 + "", tenNhom: "" }]);
            } else stt = dt.stt;
            data.push(dt);
        });
        setDataTableTreEm(data);
    };
    const handleAddSub = (row) => {
        let data = [];
        let lstTreEmByGroup = dataTableTreEm.filter((item) => item.stt === row.stt);
        dataTableTreEm.forEach((dt) => {
            if (row.id === dt.id) {
                dt.rowSpan = lstTreEmByGroup.length + 1;
                data.push(dt);
                data.push({
                    id: "NEW" + new Date().getTime(),
                    stt: dt.stt,
                    nhomTreEm: "",
                    soLuong: 1,
                    dmDoTuoiId: 1,
                    rowSpan: 0,
                });
            } else data.push(dt);
        });
        setDataTableTreEm(data);
    };

    const handleDeleteTreEm = (row) => {
        let data = dataTableTreEm.filter((item) => item.id !== row.id);
        let lstTreEmByGroup = data.filter((item) => item.stt === row.stt);
        let dataFirst = data.find((item) => item.stt === row.stt);
        if (!dataFirst) {
            let indexRemove = optionNhomTreEm.findIndex((item) => +item.maNhom === row.stt);
            optionNhomTreEm.splice(indexRemove, 1);
            let optionTE = optionNhomTreEm.map((dt, index) => {
                return { ...dt, maNhom: index + 1 + "" };
            });
            setOptionNhomTreEm(optionTE);
            let dataNL = dataTableNguoiLon.map((dt) => {
                return { ...dt, maNhomTreEm: [] };
            });
            setDataTableNguoiLon(dataNL);
        }
        data?.forEach((dt) => {
            if (dataFirst) {
                if (dataFirst.id === dt.id) {
                    dt.rowSpan = lstTreEmByGroup.length;
                }
            } else {
                if (dt.stt > row.stt) {
                    dt.stt = dt.stt - 1;
                }
            }
        });
        setDataTableTreEm(data);
    };

    const handleChangeDataTreEm = (e, row) => {
        let name = e.target.name;
        let index = dataTableTreEm.findIndex((it) => it.id === row.id);
        if (name === "nhomTreEm") {
            let indexEdit = optionNhomTreEm.findIndex((item) => +item.maNhom === row.stt);
            if (indexEdit !== -1) {
                optionNhomTreEm[indexEdit].tenNhom = e.target.value;
                setOptionNhomTreEm([...optionNhomTreEm]);
            }
        }
        dataTableTreEm[index][name] = e.target.value;
        setDataTableTreEm([...dataTableTreEm]);
    };

    const handleChangeDataNL = (e, row) => {
        let name = e.target.name;
        let index = dataTableNguoiLon.findIndex((it) => it.id === row.id);
        dataTableNguoiLon[index][name] = e.target.value;
        setDataTableNguoiLon([...dataTableNguoiLon]);
    };

    const handleDeleteNL = (row) => {
        let data = dataTableNguoiLon.filter((item) => item.id !== row.id);
        data?.forEach((dt) => {
            if (dt.stt > row.stt) {
                dt.stt = dt.stt - 1;
            }
        });
        setDataTableNguoiLon(data);
    };

    const handleCancelTourSp = () => {
        setIsOpen(false);
    };

    const handleFinish = (record, apDungThongTinTour) => {
        console.log(record);
        if (record?.id) {
            setIsOpen(false);
            setIsDisableTyGia(false);
            setApDungThongTinTour(apDungThongTinTour);
            setTenTour(record.tenTour);
            setTourThongTinChungId(record.id);
            chiettinhService
                .getChietTinh({
                    typeData: 0,
                    tourThongTinChungId: record.id,
                    tyGiaGocId: 7,
                    tourTabChietTinhId: 1,
                    tourThoiGianApDungId: 4,
                    tourQuanLyKhoangKhachId: 0,
                    loaiTong: "total",
                })
                .then(function (response) {
                    if (response.status === 200) {
                        let data = response.data.data;
                        setLoaiTienMau(data.tyGiaGoc.id);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    };

    const columnsTreEm = [
        {
            title: t("stt"),
            dataIndex: "stt",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        children: (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }} onClick={() => handleAddRow(row)}>
                                <Link disabled={isDisable} underline="none">
                                    {t("addChildrenGroup")}
                                </Link>
                            </Box>
                        ),
                        props: {
                            colSpan: 2,
                        },
                    };
                }
                return {
                    children: text,
                    props: {
                        rowSpan: row.rowSpan,
                    },
                };
            },
        },
        {
            title: t("childrenGroup"),
            dataIndex: "nhomTreEm",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                if (row.rowSpan > 0) {
                    return {
                        children: (
                            <>
                                <Row>
                                    <Col xs={21} sm={21} md={21} lg={20}>
                                        {row.id.toString().indexOf("NEW") !== -1 ? (
                                            <TextField
                                                name="nhomTreEm"
                                                onChange={(e) => handleChangeDataTreEm(e, row)}
                                                value={row.nhomTreEm}
                                            ></TextField>
                                        ) : (
                                            text
                                        )}
                                    </Col>
                                    <Col xs={1} sm={1} md={1} lg={1}>
                                        <IconButton disabled={isDisable} onClick={() => handleAddSub(row)} size="small">
                                            <PlusCircleFilled />
                                        </IconButton>
                                    </Col>
                                </Row>
                            </>
                        ),
                        // props: {
                        //   rowSpan: row.rowSpan,
                        //   colSpan: 0,
                        // },
                    };
                }
            },
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            dataIndex: "soLuong",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        <TextField
                            name="soLuong"
                            type="number"
                            value={text}
                            onChange={(e) => handleChangeDataTreEm(e, row)}
                            disabled={isDisable}
                        ></TextField>
                    </>
                );
            },
        },
        {
            title: t("age"),
            dataIndex: "dmDoTuoiId",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        <Row>
                            <Col xs={21} sm={21} md={21} lg={20}>
                                <TextField
                                    name="dmDoTuoiId"
                                    fullWidth
                                    select
                                    value={text}
                                    onChange={(e) => handleChangeDataTreEm(e, row)}
                                    disabled={isDisable}
                                >
                                    {dataDoTuoi.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.do_tuoi}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1}>
                                <IconButton disabled={isDisable} onClick={() => handleDeleteTreEm(row)}>
                                    <Delete />
                                </IconButton>
                            </Col>
                        </Row>
                    </>
                );
            },
        },
    ];
    const handleAddRowNL = (row) => {
        let data = [];
        let stt = 0;
        dataTableNguoiLon.forEach((dt) => {
            if ("SUBTOTAL" === dt.id) {
                data.push({
                    id: "NEW" + new Date().getTime(),
                    stt: stt + 1,
                    nhomNguoiLon: "",
                    soLuong: 1,
                    dmDoTuoiId: 1,
                    maNhomTreEm: [],
                });
            } else stt = dt.stt;
            data.push(dt);
        });
        setDataTableNguoiLon(data);
    };

    const handleUpload = (e) => {
        e.preventDefault();
        fileInput.current.click();
    };

    const handleSelectFile = async (e) => {
        // console.log(e);
        const file = e.target.files[0];
        let tenFile = "";
        let base64 = "";
        if (file) {
            if (file.type === "application/pdf") {
                tenFile = file.name;
                base64 = await convertBase64(file);
            } else {
                handleNoti("error", t("restrictFile"));
            }
        }
        setFileDinhKem({
            id: 0,
            tenFile: tenFile,
            base64Value: base64 ? base64.substring(base64.indexOf("base64") + 7) : base64,
        });
        // console.log(base64);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleNgayKhoiHanh = (value) => {
        let day = value.format("DD/MM/YYYY");
        console.log(day);
        console.log(value);
        setNgayKhoiHanh(day);
        setNgayKhoiHanhDate(value);
    };

    const handleSave = (e, action) => {
        e.preventDefault();
        let nhomTreEms = [];
        let groupTreEm = Object.groupBy(dataTableTreEm, ({ stt }) => stt);
        let keyGroupTreEm = Object.keys(groupTreEm);
        for (let index = 0; index < keyGroupTreEm.length - 1; index++) {
            let element = keyGroupTreEm[index];
            nhomTreEms.push({
                maNhom: element,
                tenNhom: groupTreEm[element][0].nhomTreEm,
                details: groupTreEm[element]?.map((item) => {
                    return { soLuong: item.soLuong, dmDoTuoiId: item.dmDoTuoiId };
                }),
            });
        }
        let nhomNguoiLons = [];
        dataTableNguoiLon.forEach((item, index) => {
            if (index !== dataTableNguoiLon.length - 1)
                nhomNguoiLons.push({
                    maNhomTreEm: item.maNhomTreEm,
                    tenNhom: item.nhomNguoiLon,
                    soLuong: item.soLuong,
                    foc: item.foc,
                });
        });
        let maBG = gennerateMaBaoGia();
        if (!maBaoGia) {
            setMaBaoGia(maBG);
            setTenBaoGia("Bao gia " + maBG);
        }
        let dataSave = {
            hanhDong: action, //luu hoac tiep_tuc
            maBaoGia: maBaoGia ? maBaoGia : maBG,
            tenBaoGia: tenBaoGia ? tenBaoGia : maBaoGia ? "Bao gia " + maBaoGia : "Bao gia " + maBG,
            dmLoaiKhachHang: loaiKhachHang,
            tenKhachHang: tenKhachHang,
            ngayKhoiHanh: ngayKhoiHanh,
            soNgay: parseInt(soNgay),
            soDem: parseInt(soDem),
            soLuongNguoiLon: parseInt(soNguoiLon),
            foc: parseInt(foc),
            soLuongTreEm: parseInt(soTreEm),
            chietKhau: parseInt(chietKhau),
            tourThongTinChungId: tourThongTinChungId, //0: thông tin thêm mới, >0 chọn từ tour sản phẩm
            tenTour: tenTour,
            apDungThongTinTour: apDungThongTinTour,
            dmLoaiTienMauId: loaiTienMau ? loaiTienMau : 1, //dm_ty_gia_id
            dmLoaiTienBaoGiaId: loaiTienMacDinh, //dm_ty_gia_id
            tyGia: tyGia,
            userAssign: userAssign,
            ghiChu: ghiChu,
            fileDinhKem: fileDinhKem ? fileDinhKem : { id: 0, tenFile: "", base64Value: "" },
            nhomTreEms: nhomTreEms,
            nhomNguoiLons: nhomNguoiLons,
        };
        console.log("dataSave: " + JSON.stringify(dataSave));
        console.log(dataSave);
        let infoErr = validateDataTongQuan(dataSave);
        if (infoErr.check) {
            handleNoti("error", t(infoErr.message));
        } else {
            if (id === 0) {
                saveTongQuan(dataSave, action);
            } else {
                dataSave.id = id;
                updateTongQuan(dataSave, action);
            }
        }
    };

    const formatStringDateView = (strDate) => {
        let date = strDate.split("/");
        return date[1] + "/" + date[0] + "/" + date[2];
    };

    const validateDataTongQuan = (dataSave) => {
        let infoError = { check: false, message: "" };
        let tenNhomNgLons = dataSave.nhomNguoiLons?.map((it) => it.tenNhom) || [];
        let tenNhomTreEms = dataSave.nhomTreEms?.map((it) => it.tenNhom) || [];
        let maNhomTreEms = dataSave.nhomNguoiLons?.flatMap((it) => it.maNhomTreEm?.map((dt) => dt) || []);
        if (tenNhomNgLons.length > 1 && checkDuplicate(tenNhomNgLons)) {
            infoError.check = true;
            infoError.message = "noti.duplicateDataNgLon";
            return infoError;
        } else if (tenNhomTreEms.length > 1 && checkDuplicate(tenNhomTreEms)) {
            infoError.check = true;
            infoError.message = "noti.duplicateDataTreEm";
            return infoError;
        } else if (maNhomTreEms.length > 1 && checkDuplicate(maNhomTreEms)) {
            infoError.check = true;
            infoError.message = "noti.duplicateDataTreEmOfNgLon";
            return infoError;
        }
        return infoError;
    };

    const checkDuplicate = (data) => {
        const uniqueItems = new Set(data);
        return uniqueItems.size !== data.length;
    };

    const saveTongQuan = (dataSave, action) => {
        baoGiaService
            .saveTongQuan(dataSave)
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    // handleResetDataTQ();
                    setId(response.data.data);
                    setAction(action);
                    setIsDisable(true);
                    if (action === "tiep_tuc" && focusRef.current) {
                        focusRef.current.scrollIntoView({ behavior: "smooth" });
                    }
                    handleNoti("success", t("add") + " " + t("noti.success").toLowerCase());
                } else {
                    handleNoti("error", response.data.message);
                }
            })
            .catch(() => {
                console.log("Save baoGiaTongQuanError ...");
                handleNoti("error", t("add") + " " + t("noti.error").toLowerCase());
            });
    };

    const updateTongQuan = (dataUpdate, action) => {
        baoGiaService
            .updateTongQuan(dataUpdate)
            .then((response) => {
                console.log(response);
                if (response.status === 200 && response.data.code === "00") {
                    // handleResetDataTQ();
                    setAction(action);
                    setIsDisable(true);
                    if (action === "tiep_tuc") {
                        focusRef.current.scrollIntoView({ behavior: "smooth" });
                    }
                    handleNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
                } else {
                    handleNoti("error", response.data.message);
                }
            })
            .catch(() => {
                console.log("Update baoGiaTongQuanError ...");
                handleNoti("error", t("edit") + " " + t("noti.error").toLowerCase());
            });
    };

    const gennerateMaBaoGia = () => {
        let d = new Date();
        return "MBG" + d.getTime();
    };

    const handleNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: message,
            duration: TIME_DURATION,
        });
    };

    const handleResetDataTQ = () => {
        setLoaiKhachHang(0);
        setTenKhachHang("");
        setNgayKhoiHanh("");
        setSoNgay("");
        setSoDem("");
        setSoNguoiLon("");
        setSoTreEm("");
        setFoc("");
        setChietKhau("");
        setLoaiTienMacDinh(0);
        // setLoaiTienQuyDoi(0);
        setTyGia("");
        setUserAssign(0);
        setGhiChu("");
        setFileDinhKem({ tenFile: "" });
        setDataTableTreEm([{ id: "SUBTOTAL" }]);
        setDataTableNguoiLon([{ id: "SUBTOTAL" }]);
    };

    const columnsNguoiLon = [
        {
            title: t("stt"),
            dataIndex: "stt",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        children: (
                            <Box sx={{ display: "flex", alignItems: "flex-end" }} onClick={() => handleAddRowNL(row)}>
                                <Link disabled={isDisable} underline="none">
                                    {t("addAdultGroup")}
                                </Link>
                            </Box>
                        ),
                        props: {
                            colSpan: 2,
                        },
                    };
                }
                return {
                    children: text,
                    props: {
                        rowSpan: row.rowSpan,
                    },
                };
            },
        },
        {
            title: t("adultGroup"),
            dataIndex: "nhomNguoiLon",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        {row.id.toString().indexOf("NEW") !== -1 ? (
                            <TextField
                                name="nhomNguoiLon"
                                onChange={(e) => handleChangeDataNL(e, row)}
                                value={text}
                            ></TextField>
                        ) : (
                            text
                        )}
                    </>
                );
            },
        },
        {
            title: t("tour.vanChuyen.soLuong"),
            dataIndex: "soLuong",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        <TextField
                            name="soLuong"
                            fullWidth
                            type="number"
                            value={text}
                            onChange={(e) => handleChangeDataNL(e, row)}
                            disabled={isDisable}
                        ></TextField>
                    </>
                );
            },
        },
        {
            title: t("hotel.roomClass.foc"),
            dataIndex: "foc",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        <TextField
                            name="foc"
                            fullWidth
                            type="number"
                            value={text}
                            onChange={(e) => handleChangeDataNL(e, row)}
                            disabled={isDisable}
                        ></TextField>
                    </>
                );
            },
        },
        {
            title: t("quanLyNhomTreEm"),
            dataIndex: "dmDoTuoiId",
            render: (text, row, index) => {
                if (row.id === "SUBTOTAL") {
                    return {
                        props: {
                            colSpan: 0,
                        },
                    };
                }
                return (
                    <>
                        <Row>
                            <Col xs={21} sm={21} md={21} lg={20}>
                                <TextField
                                    name="maNhomTreEm"
                                    fullWidth
                                    select
                                    // value={text}
                                    SelectProps={{
                                        multiple: true,
                                        value: row.maNhomTreEm,
                                        onChange: (e) => handleChangeDataNL(e, row),
                                    }}
                                >
                                    {optionNhomTreEm.map((option) => (
                                        <MenuItem key={option.maNhom} value={option.maNhom}>
                                            {option.tenNhom}
                                        </MenuItem>
                                    ))}
                                    disabled={isDisable}
                                </TextField>
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1}>
                                <IconButton disabled={isDisable} onClick={() => handleDeleteNL(row)}>
                                    <Delete />
                                </IconButton>
                            </Col>
                        </Row>
                    </>
                );
            },
        },
    ];
    const children = (
        <>
            <Box>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={3}>
                        <div style={style.h4}>{t("childrenGroup")}</div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={9}></Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={3}>
                        <div style={style.h4}>{t("adultGroup")}</div>
                    </Col>
                </Row>
                <Row style={style.rowTop}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                        <Table bordered columns={columnsTreEm} dataSource={dataTableTreEm} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Table bordered columns={columnsNguoiLon} dataSource={dataTableNguoiLon} />
                    </Col>
                </Row>
            </Box>
        </>
    );
    const checkErrorSoNgay = (length) => {
        if (length > soNgay) {
            setErrorSoNgay(true);
        } else setErrorSoNgay(false);
    };
    const items = [
        {
            key: "1",
            label: t("trip"),
            children: (
                <HanhTrinh
                    dvId={tourThongTinChungId}
                    baoGiaTongQuanId={id}
                    ngayKhoiHanh={ngayKhoiHanh ? ngayKhoiHanh : null}
                    soNgay={soNgay > soDem ? soNgay : soDem}
                    errorSoNgay={errorSoNgay}
                    id={tourThongTinChungId}
                    action={action}
                    ngayKhoiHanhDate={ngayKhoiHanhDate}
                    checkErrorSoNgay={checkErrorSoNgay}
                    setDefaultActiveKey={setDefaultActiveKey}
                    setIsChangeHanhTrinh={setIsChangeHanhTrinh}
                />
            ),
        },
        {
            key: "2",
            label: t("thongTinTour"),
            disabled: errorSoNgay || isChangeHanhTrinh,
            children: (
                <ThongTinTour
                    dvId={tourThongTinChungId}
                    baoGiaTongQuanId={id}
                    isEdit={isEdit}
                    apDungThongTinTour={apDungThongTinTour}
                />
            ),
        },
        // {
        //   key: "3",
        //   label: t("chietTinhOP"),
        //   disabled: errorSoNgay,
        //   children: (
        //     <ChietTinhOP
        //       dvId={tourThongTinChungId}
        //       baoGiaTongQuanId={id}
        //       soNgay={soNgay}
        //       ngayKhoiHanh={ngayKhoiHanh}
        //     />
        //   ),
        // },
        {
            key: "3",
            label: t("chietTinhSale"),
            disabled: errorSoNgay || isChangeHanhTrinh,
            children: (
                <ChietTinhSale
                    dvId={tourThongTinChungId}
                    baoGiaTongQuanId={id}
                    soNgay={soNgay}
                    ngayKhoiHanh={ngayKhoiHanh}
                    dataNhomNgLon={dataNhomNgLon}
                    dataNhomTreEm={dataNhomTreEm}
                    soLuongNgLonBG={soNguoiLon}
                    soLuongTreEmBG={soTreEm}
                />
            ),
        },
        {
            key: "4",
            label: t("AdditionalQuote"),
            disabled: errorSoNgay || isChangeHanhTrinh,
            // children: (
            //     <ChietTinhSale
            //         dvId={tourThongTinChungId}
            //         baoGiaTongQuanId={id}
            //         soNgay={soNgay}
            //         ngayKhoiHanh={ngayKhoiHanh}
            //         dataNhomNgLon={dataNhomNgLon}
            //         dataNhomTreEm={dataNhomTreEm}
            //         soLuongNgLonBG={soNguoiLon}
            //         soLuongTreEmBG={soTreEm}
            //     />
            // ),
        },
    ];

    const childrenTQ = (
        <>
            <Row style={style.rowTop}>
                <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                    <TextField
                        value={maBaoGia}
                        fullWidth
                        label={t("nhapMa") + " " + t("baoGia").toLowerCase()}
                        id="nhapMa"
                        variant="standard"
                        onChange={(e) => setMaBaoGia(e.target.value)}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                    <TextField
                        value={tenBaoGia}
                        fullWidth
                        id="nhapTen"
                        label={t("nhapTen") + " " + t("baoGia").toLowerCase()}
                        variant="standard"
                        onChange={(e) => setTenBaoGia(e.target.value)}
                    />
                </Col>
            </Row>
            <Row style={style.rowTop}>
                <Col span={3}>
                    <div style={style.h4}>{t("loaiKH") + " *"}</div>
                </Col>
                <Col span={8}>
                    <Select
                        value={loaiKhachHang}
                        fullWidth
                        id="loaiKH"
                        variant="standard"
                        onChange={(e) => setLoaiKhachHang(e.target.value)}
                    >
                        {dmLoaiKhachHang?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.loai_khach_hang}
                            </MenuItem>
                        ))}
                    </Select>
                </Col>

                <Col span={1}></Col>
                <Col span={3}>
                    <div style={style.h4}>{t("tenKH")} *</div>
                </Col>
                <Col span={8}>
                    <TextField
                        value={tenKhachHang}
                        fullWidth
                        id="tenKH"
                        variant="standard"
                        onChange={(e) => setTenKhachHang(e.target.value)}
                    />
                </Col>
            </Row>
            <Row style={style.rowTop}>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>{t("ngayKhoiHanh") + " *"}</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    {/* <TextField fullWidth id="ngayKhoiHanh" variant="standard" /> */}
                    <LocalizationProvider
                        // style={{ width: "100%" }}
                        dateAdapter={AdapterDayjs}
                    >
                        <DatePicker
                            value={ngayKhoiHanh ? dayjs(formatStringDateView(ngayKhoiHanh)) : null}
                            format="DD/MM/YYYY"
                            // variant="standard"
                            sx={{ width: "100%" }}
                            onChange={handleNgayKhoiHanh}
                            slotProps={{ textField: { variant: "standard" } }}
                        />
                    </LocalizationProvider>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>{t("soNgay")} *</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                    <TextField
                        type="number"
                        value={soNgay}
                        fullWidth
                        id="soNgay"
                        variant="standard"
                        onChange={(e) => setSoNgay(e.target.value)}
                        error={errorSoNgay}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={1}>
                    <div style={style.h4}>{t("soDem")} *</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                    <TextField
                        type="number"
                        value={soDem}
                        fullWidth
                        id="soDem"
                        variant="standard"
                        onChange={(e) => setSoDem(e.target.value)}
                    />
                </Col>
            </Row>
            <Row style={style.rowTop}>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>{t("soLuongNgLon") + " *"}</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <TextField
                        type="number"
                        value={soNguoiLon}
                        fullWidth
                        id="soLuongNgLon"
                        variant="standard"
                        onChange={(e) => setSoNguoiLon(e.target.value)}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>{t("hotel.roomClass.foc")}</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <TextField
                        type="number"
                        value={foc}
                        fullWidth
                        id="foc"
                        variant="standard"
                        onChange={(e) => setFoc(e.target.value)}
                    />
                </Col>
            </Row>
            <Row style={style.rowTop}>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>{t("soLuongTreEm")}</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <TextField
                        type="number"
                        value={soTreEm}
                        fullWidth
                        id="soLuongTreEm"
                        variant="standard"
                        onChange={(e) => setSoTreEm(e.target.value)}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>{t("chietKhau")} (%)</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <TextField
                        type="number"
                        value={chietKhau}
                        fullWidth
                        id="chietKhau"
                        variant="standard"
                        onChange={(e) => setChietKhau(e.target.value)}
                    />
                </Col>
            </Row>
            <Row style={style.rowTop}>
                <Col span={3}>
                    <div style={style.h4}>Tên Tour</div>
                </Col>
                <Col span={18}>
                    <TextField
                        type="text"
                        value={tenTour}
                        fullWidth
                        id="tenTour"
                        variant="standard"
                        onChange={(e) => setTenTour(e.target.value)}
                    />
                </Col>
                {/* <Col span={1}></Col> */}
                <Col span={2}>
                    <Button
                        type="primary"
                        fullWidth
                        icon={<SearchOutlined />}
                        onClick={() => setIsOpen(true)}
                        size="middle"
                    >
                        {t("selectTourTemplate")}
                    </Button>
                </Col>
            </Row>
            <Row style={style.rowTop}>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>{t("loaiTienMacDinh")}</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <Select
                        value={loaiTienMacDinh}
                        fullWidth
                        id="loaiTienMacDinh"
                        variant="standard"
                        onChange={(e) => setLoaiTienMacDinh(e.target.value)}
                    >
                        {dmLoaiTien?.map((option) => (
                            <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                {option.maNgoaiTe}
                            </MenuItem>
                        ))}
                    </Select>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={2}>
          <div style={style.h4}>{t("loaiTienQuyDoi")}</div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
          <Select
            value={loaiTienQuyDoi}
            fullWidth
            id="loaiTienQuyDoi"
            variant="standard"
            onChange={(e) => setLoaiTienQuyDoi(e.target.value)}
          >
            {dmLoaiTien?.map((option) => (
              <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                {option.maNgoaiTe}
              </MenuItem>
            ))}
          </Select>
        </Col> */}
                <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>{t("exchangeRate")}</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <TextField
                        type="number"
                        value={tyGia}
                        fullWidth
                        id="exchangeRate"
                        variant="standard"
                        onChange={(e) => setTyGia(e.target.value)}
                    />
                </Col>
            </Row>
            <Row style={style.rowTop}>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>User Assign</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <Select
                        value={userAssign}
                        fullWidth
                        id="user"
                        variant="standard"
                        onChange={(e) => setUserAssign(e.target.value)}
                    >
                        {userAssigns?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.fullName}
                            </MenuItem>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>{t("notes")}</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    <TextField
                        fullWidth
                        value={ghiChu}
                        id="notes"
                        variant="standard"
                        onChange={(e) => setGhiChu(e.target.value)}
                    />
                </Col>
            </Row>
            <Row style={style.rowTop}>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div style={style.h4}>{t("fileAttach")}</div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                    {/* <Input
              type="file"
              fullWidth
              id="fileAttach"
              onChange={(e) => handleSelectFile(e)}
              // ref={fileDinhKem?.base64Value}
              // variant="standard"
            /> */}
                    <TextField
                        fullWidth
                        variant="standard"
                        type="text"
                        value={fileDinhKem?.tenFile}
                        InputProps={{
                            startAdornment: (
                                <>
                                    <button
                                        style={{ width: "100%", marginRight: "5px" }}
                                        onClick={(e) => handleUpload(e)}
                                    >
                                        Chọn file
                                    </button>
                                    <input
                                        styles={{ display: "none" }}
                                        ref={fileInput}
                                        type="file"
                                        accept=".pdf, application/pdf"
                                        hidden
                                        onChange={(e) => handleSelectFile(e)}
                                        // name="[licenseFile]"
                                    />
                                </>
                            ),
                            disableUnderline: true,
                        }}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse
                        style={style.rowTop}
                        defaultActiveKey={"1"}
                        items={[
                            {
                                key: "1",
                                label: (
                                    <div
                                        style={{
                                            textTransform: "uppercase",
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("quanLyNhomKhach")}
                                    </div>
                                ),
                                children: children,
                            },
                        ]}
                    />
                </Col>
            </Row>
        </>
    );

    return (
        <>
            {contextHolder}
            <ModalTourSanPham isOpen={isOpen} handleFinish={handleFinish} handleCancel={handleCancelTourSp} />
            <Box component="form" noValidate autoComplete="off">
                <Card
                    style={style.rowTop}
                    headStyle={{
                        backgroundColor: "#f5f5f5",
                    }}
                    title={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    textTransform: "uppercase",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    alignSelf: "center",
                                }}
                            >
                                {t("overview")}
                            </div>
                            <div
                                style={{
                                    marginRight: "10px",
                                    // width: "12%",
                                }}
                            >
                                {action === "tiep_tuc" ? (
                                    <>
                                        <Buttons
                                            style={{
                                                marginRight: "5px",
                                            }}
                                            variant="contained"
                                            // type="save"
                                            //onClick={(e) => handleSave(e, "luu")}
                                            onClick={() => setIsDisable(false)}
                                        >
                                            <EditOutlined />
                                        </Buttons>
                                        <Buttons
                                            variant="outlined"
                                            onClick={(e) => handleSave(e, "tiep_tuc")}
                                            key="continue"
                                        >
                                            <CheckOutlined />
                                        </Buttons>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    }
                >
                    <Row style={style.rowTop}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <TextField
                                value={maBaoGia}
                                fullWidth
                                label={t("nhapMa") + " " + t("baoGia").toLowerCase()}
                                id="nhapMa"
                                variant="standard"
                                onChange={(e) => setMaBaoGia(e.target.value)}
                                disabled={isDisable}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                            <TextField
                                value={tenBaoGia}
                                fullWidth
                                id="nhapTen"
                                label={t("nhapTen") + " " + t("baoGia").toLowerCase()}
                                variant="standard"
                                onChange={(e) => setTenBaoGia(e.target.value)}
                                disabled={isDisable}
                            />
                        </Col>
                    </Row>
                    <Row style={style.rowTop}>
                        <Col span={3}>
                            <div>{t("loaiKH") + " *"}</div>
                        </Col>
                        <Col span={8}>
                            <Select
                                value={loaiKhachHang}
                                fullWidth
                                id="loaiKH"
                                variant="standard"
                                onChange={(e) => setLoaiKhachHang(e.target.value)}
                                disabled={isDisable}
                            >
                                {dmLoaiKhachHang?.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.loai_khach_hang}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Col>

                        <Col span={1}></Col>
                        <Col span={3}>
                            <div>{t("tenKH")} *</div>
                        </Col>
                        <Col span={8}>
                            <TextField
                                value={tenKhachHang}
                                fullWidth
                                id="tenKH"
                                variant="standard"
                                onChange={(e) => setTenKhachHang(e.target.value)}
                                disabled={isDisable}
                            />
                        </Col>
                    </Row>
                    <Row style={style.rowTop}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <div>{t("ngayKhoiHanh") + " *"}</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            {/* <TextField fullWidth id="ngayKhoiHanh" variant="standard" /> */}
                            <LocalizationProvider
                                // style={{ width: "100%" }}
                                dateAdapter={AdapterDayjs}
                            >
                                <DatePicker
                                    value={ngayKhoiHanh ? dayjs(formatStringDateView(ngayKhoiHanh)) : null}
                                    format="DD/MM/YYYY"
                                    // variant="standard"
                                    sx={{ width: "100%" }}
                                    onChange={handleNgayKhoiHanh}
                                    slotProps={{ textField: { variant: "standard" } }}
                                    disabled={isDisable}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            {errorSoNgay ? (
                                <div style={{ color: "red" }}>{t("soNgay")} *</div>
                            ) : (
                                <div>{t("soNgay")} *</div>
                            )}
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                            <TextField
                                type="number"
                                value={soNgay}
                                fullWidth
                                id="soNgay"
                                variant="standard"
                                onChange={(e) => setSoNgay(e.target.value)}
                                error={errorSoNgay}
                                disabled={isDisable}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={1}>
                            <div>{t("soDem")} *</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                            <TextField
                                type="number"
                                value={soDem}
                                fullWidth
                                id="soDem"
                                variant="standard"
                                onChange={(e) => setSoDem(e.target.value)}
                                disabled={isDisable}
                            />
                        </Col>
                    </Row>
                    <Row style={style.rowTop}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <div>{t("soLuongNgLon") + " *"}</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            <TextField
                                type="number"
                                value={soNguoiLon}
                                fullWidth
                                id="soLuongNgLon"
                                variant="standard"
                                onChange={(e) => setSoNguoiLon(e.target.value)}
                                disabled={isDisable}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <div>{t("hotel.roomClass.foc")}</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            <TextField
                                type="number"
                                value={foc}
                                fullWidth
                                id="foc"
                                variant="standard"
                                onChange={(e) => setFoc(e.target.value)}
                                disabled={isDisable}
                            />
                        </Col>
                    </Row>
                    <Row style={style.rowTop}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <div>{t("soLuongTreEm")}</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            <TextField
                                type="number"
                                value={soTreEm}
                                fullWidth
                                id="soLuongTreEm"
                                variant="standard"
                                onChange={(e) => setSoTreEm(e.target.value)}
                                disabled={isDisable}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <div>{t("chietKhau")} (%)</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            <TextField
                                type="number"
                                value={chietKhau}
                                fullWidth
                                id="chietKhau"
                                variant="standard"
                                onChange={(e) => setChietKhau(e.target.value)}
                                disabled={isDisable}
                            />
                        </Col>
                    </Row>
                    <Row style={style.rowTop}>
                        <Col span={3}>
                            <div>Tên Tour</div>
                        </Col>
                        <Col span={17}>
                            <TextField
                                type="text"
                                value={tenTour}
                                fullWidth
                                id="tenTour"
                                variant="standard"
                                onChange={(e) => setTenTour(e.target.value)}
                                disabled={isDisable}
                            />
                        </Col>
                        <Col span={3}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    type="primary"
                                    // fullWidth
                                    style={{ width: "fit-content" }}
                                    icon={<SearchOutlined />}
                                    onClick={() => setIsOpen(true)}
                                    size="middle"
                                    disabled={isDisable}
                                >
                                    {t("selectTourTemplate")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={style.rowTop}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <div>{t("tour.loaiTienMau")}</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                            <TextField
                                select
                                value={loaiTienMau}
                                disabled
                                fullWidth
                                id="loaiTienMau"
                                variant="standard"
                                onChange={(e) => setLoaiTienMau(e.target.value)}
                            >
                                {dmLoaiTien?.map((option) => (
                                    <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                        {option.maNgoaiTe}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={2}>
                            <div>{t("tour.loaiTienBaoGia")} *</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Select
                                value={loaiTienMacDinh}
                                fullWidth
                                id="loaiTienMacDinh"
                                variant="standard"
                                onChange={(e) => setLoaiTienMacDinh(e.target.value)}
                                disabled={isDisable}
                            >
                                {dmLoaiTien?.map((option) => (
                                    <MenuItem key={option.maNgoaiTeId} value={option.maNgoaiTeId}>
                                        {option.maNgoaiTe}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <div>{t("exchangeRate") + " *"}</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            <TextField
                                type="number"
                                value={tyGia}
                                disabled={isDisable ? true : tyGia && tyGia !== 1 ? false : isDisableTyGia}
                                fullWidth
                                id="exchangeRate"
                                variant="standard"
                                onChange={(e) => setTyGia(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row style={style.rowTop}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <div>User Assign</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            <Select
                                value={userAssign}
                                fullWidth
                                id="user"
                                variant="standard"
                                onChange={(e) => setUserAssign(e.target.value)}
                                disabled={isDisable}
                            >
                                {userAssigns?.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.fullName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <div>{t("notes")}</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            <TextField
                                fullWidth
                                value={ghiChu}
                                id="notes"
                                variant="standard"
                                onChange={(e) => setGhiChu(e.target.value)}
                                disabled={isDisable}
                            />
                        </Col>
                    </Row>
                    <Row style={style.rowTop}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <div>{t("fileAttach")}</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                            {/* <Input
              type="file"
              fullWidth
              id="fileAttach"
              onChange={(e) => handleSelectFile(e)}
              // ref={fileDinhKem?.base64Value}
              // variant="standard"
            /> */}
                            <TextField
                                fullWidth
                                variant="standard"
                                type="text"
                                value={fileDinhKem?.tenFile}
                                InputProps={{
                                    startAdornment: (
                                        <>
                                            <button
                                                style={{ width: "20%", marginRight: "5px" }}
                                                onClick={(e) => handleUpload(e)}
                                                disabled={isDisable}
                                            >
                                                Chọn file
                                            </button>
                                            <input
                                                styles={{ display: "none" }}
                                                ref={fileInput}
                                                type="file"
                                                accept=".pdf, application/pdf"
                                                hidden
                                                onChange={(e) => handleSelectFile(e)}
                                                // name="[licenseFile]"
                                            />
                                        </>
                                    ),
                                    disableUnderline: true,
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={1}></Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Collapse
                                style={style.rowTop}
                                defaultActiveKey={"1"}
                                items={[
                                    {
                                        key: "1",
                                        label: (
                                            <div
                                                style={{
                                                    textTransform: "uppercase",
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {t("quanLyNhomKhach")}
                                            </div>
                                        ),
                                        children: children,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Card>
                <Divider />
                {action !== "tiep_tuc" ? (
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={2} xl={9}></Col>
                        <Col xs={24} sm={24} md={24} lg={18} xl={10}>
                            <div
                                style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    height: 60,
                                    paddingInline: 50,
                                    lineHeight: "60px",
                                    backgroundColor: "rgba(150, 150, 150, 0.2)",
                                    borderRadius: "4px",
                                }}
                            >
                                <Space>
                                    <Buttons
                                        style={{
                                            backgroundColor: "#898989",
                                            color: "white",
                                        }}
                                        key="reset"
                                        startIcon={<RestartAltIcon />}
                                        // onClick={(e) => handleClear(e)}
                                    >
                                        {t("action.reset")}
                                    </Buttons>
                                    <Buttons
                                        variant="contained"
                                        type="submit"
                                        startIcon={<SaveIcon />}
                                        onClick={(e) => handleSave(e, "luu")}
                                    >
                                        {t("action.ok")}
                                    </Buttons>
                                    <Buttons
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        onClick={(e) => handleSave(e, "tiep_tuc")}
                                        key="cancel"
                                    >
                                        {t("action.continue")}
                                    </Buttons>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} ref={focusRef}>
                                {/* <span style={{ display: "none" }} ref={focusRef} /> */}
                                <Tabs
                                    activeKey={defaultActiveKey}
                                    items={items}
                                    onChange={(key) => setDefaultActiveKey(key)}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Box>
        </>
    );
}
